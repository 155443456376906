import { ReactChild, ReactFragment, ReactPortal, useCallback, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {IonAvatar, IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonRow, IonSkeletonText, IonThumbnail, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { cloudDownload, cloudDownloadOutline, chevronDown, pauseCircle, play, add, heartCircleOutline, heartCircle } from 'ionicons/icons';
// import axios from 'axios';
import { AppContext, getCurrentAlbum, getCurrentTrack, getHotAlbums, getHotTracks, getPlaying, isFavTrack, isOfflineTrack, makeRequests, openPlayer, playTrackList, setCurrentAlbum, setFavTrack, setIsSubscribed, setTrackToPlay, togglePaymentPopup } from '../Services/State.jsx';
import { appAuthDomain, img } from '../Services/utils';

import './Tab1.css';
// interface Provider {
//   header: string;
//   subHeader: string;
//   message: string;
//   buttons: any;
//   showAlert: boolean;
// }

const Tab1: React.FC<any> = ({actionBtnsClick, addPlaylist, setShowAlert, selectPlaylist}) => {
  const { state, dispatch } = useContext(AppContext);
  const songPlaying = getPlaying(state);
  const currentAlbum = getCurrentAlbum(state);
  const currentTrack = getCurrentTrack(state);
  const [requestMade, setRequestMade] = useState(false);
  const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});
  // const [progress, setProgress] = useState(0);
  // const [showAlertState, setShowAlert] = useState<Provider>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});

  const history = useHistory();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('id');
  const location = useLocation();


  useIonViewWillEnter(() => {
      const bottomNav = document.getElementById("bottomNav");
      if (bottomNav) {
          bottomNav.style.display = "flex";
      };
  });
  const peach3DPayment = (code:any)=>{
    var requestObject = {
        method: "POST", 
        url: appAuthDomain("backend/includes/subscription/subscription.php?action=subscribe&paymentMethod=getStatus"),
        data: {
            payID: code,
            value: 5,
        },
        headers:{
            "Content-Type": "multipart/form-data"

        }
    };
    // console.log(requestObject);
    setShowLoading({showLoading: true, showLoadingMessage: "Verifying Payment... "});
    makeRequests(state, requestObject).then(response=>{
        setShowLoading({...showLoadingState, showLoading: false});
        var buttonActions:any =[]
        var alertStateVars = {header: response.msg, subHeader: "", message: response.msg2, buttons: buttonActions};
        // console.log(response);
        if (response.success) {
            localStorage.setItem("isSubscribed", 'true');
            dispatch(setIsSubscribed(true));
            // handleClose();
            buttonActions = [
              {
                  text: 'Okay',
                  handler: () => {
                    history.push("/home");
                  }
              }
            ];
            alertStateVars = {header: response.msg, subHeader: "", message: response.msg2, buttons: buttonActions};
            setShowAlert({...alertStateVars, showAlert: true});
            // doThePlay();
        }else{
              buttonActions = [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                },
                {
                    text: 'Retry',
                    handler: () => {
                      peach3DPayment(code);
                      // setRequestMade(false);
                    }
                }
            ];
            alertStateVars = {header: response.msg, subHeader: "", message: response.msg2, buttons: buttonActions};
            setShowAlert({...alertStateVars, showAlert: true});
        }
    })
    
}

if(code){
  if(!requestMade){
    if(location.pathname !== '/album'){
      setRequestMade(true);
      peach3DPayment(code);
    }
  }
}

// function doThePlay (){
//   console.log("here")
//   if(state.trackToPlay.whatType !== ""|| state.trackToPlay.whatType!== null){
//   console.log("in")
//       var myPlayList:any = [];
//       var tempTracks:any = [];
//       state.trackToPlay.listTracks.forEach((song:any)=>{
//           myPlayList.push(song.id);
//           tempTracks.push(song);
//       });
//       if(state.trackToPlay.whatType === "track"){
//           if (getCurrentTrack(state)) {
//               if (state.trackToPlay.track.id === (getCurrentTrack(state)).id) {
//                   dispatch(openPlayer());
//               } else {
//                   dispatch(playTrackList(tempTracks, myPlayList, state.trackToPlay.track));
//               }
//           } else {
//           dispatch(playTrackList(tempTracks, myPlayList, state.trackToPlay.track));
//           }
//       }else{
//           if (state.trackToPlay.listTracks.length > 0) {
//               dispatch(playTrackList(tempTracks, myPlayList, state.trackToPlay.track));
//           };
//       }
//   }

// }

  const doPlay = useCallback((track:any, listTracks: any) => {
    if (state.auth.user) {
      if (state.auth.user.isSubscribed) {
        // var getCurrentTracks = getTracks(state);
        var myPlayList:any = [];
        var tempTracks:any = [];
        listTracks.forEach((song:any)=>{
          myPlayList.push(song.id);
          tempTracks.push(song);
          // getCurrentTracks.push(song);
        });
        // var newMusicList = {...state.music, tracks: getCurrentTracks, tempTracks: tempTracks, playList: myPlayList};
        // // dispatch(setTracks(newMusicList));
        if (getCurrentTrack(state)) {
          if (track.id === (getCurrentTrack(state)).id) {
            dispatch(openPlayer());
            // history.push("/album?track="+track.id); //Current Track route so that tracks have shareable links
          } else {
            // dispatch(playTrack(track));
            // dispatch(playTrackList(newMusicList));
            dispatch(playTrackList(tempTracks, myPlayList, track));
            // history.push("/album?track="+track.id); //Current Track route so that tracks have shareable links
          }
        } else {
          // dispatch(playTrack(track));
          // dispatch(playTrackList(newMusicList));
          dispatch(playTrackList(tempTracks, myPlayList, track));
          // history.push("/album?track="+track.id); //Current Track route so that tracks have shareable links
        }
      } else {
        // history.push("/subscribe");
        dispatch(setTrackToPlay(track, listTracks, "track"));
        dispatch(togglePaymentPopup(true));
      }
    } else {
      history.push("/login");
    }
  }, [ state, dispatch, history ]);
  const gotoAlbum = useCallback((album, play) => {
    var playing = (play==="yes")?true:false;
    var prelimAlbumInfo = {
      id: album.id,
      img: album.img,
      title: album.title,
      artist: album.artist,
      type: album.type,
      tracks: [
        {id: 1, type: "loader", title: 'Hey Jude', artist: 'The Beatles', src: "", img: 'music/hey-jude.jpg', time: 359000,},
        {id: 2, type: "loader", title: 'Hey Jude', artist: 'The Beatles', src: "", img: 'music/hey-jude.jpg', time: 359000,},
        {id: 3, type: "loader", title: 'Hey Jude', artist: 'The Beatles', src: "", img: 'music/hey-jude.jpg', time: 359000,},
      ],
      playing
    };
    dispatch(setCurrentAlbum(prelimAlbumInfo));
    history.push("/album?id="+album.id+"&play="+play);
  }, [dispatch, history]);

  let hotTracks = getHotTracks(state);
  let hotAlbums = getHotAlbums(state);
  // console.log(hotAlbums)

  

  const addFav = (track: any) => {
    if (state.auth.user) {
      if (state.auth.user.isSubscribed) {
        dispatch(setFavTrack(track));
      }else {
        dispatch(togglePaymentPopup(true));
      }
      
    }else{
      history.push("/login");
    }
  };
  
  return (
    <IonPage>
      <IonHeader mode='ios'>
        <IonToolbar mode='ios'>
          <IonTitle>Rhythm</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen >
        <IonHeader collapse="condense" mode='ios'>
          <IonToolbar mode='ios'>
            <IonAvatar><img alt='logo' src='assets/icon/logo.png'/></IonAvatar>
            <IonTitle className="nextToLog" size="large">Rhythm</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* <div style={{visibility: "hidden"}}>
          <IonSearchbar></IonSearchbar>
        </div> */}
        {/* <div className="topHome"> */}
          <IonList mode='ios'>
            <IonListHeader mode='ios'>
              <IonLabel>Top Songs</IonLabel>
            </IonListHeader>
            {
              (hotTracks.length > 0) ? hotTracks.map((track: { id: number | null | undefined; img: any; thisIsAlbum: any; type: string ; title: string | undefined; artist: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }, key: number) => {
                var relevantIcon = play;
                var playingClass = "";
                if (currentTrack) {
                  relevantIcon = ((currentTrack.id === track.id)&&(!songPlaying.paused))?(pauseCircle): (play);
                  playingClass = (currentTrack.id === track.id)?("currentSongInList"): ("");
                };
                if(track.type === "loader"){
                  return <IonItem key={key} mode='ios' className="trackListed">
                      <IonThumbnail slot="start" className='thumbnail'>
                        {/* <h2><IonSkeletonText animated style={{ width: '100%', float: 'left'  }} /></h2> */}
                      </IonThumbnail>
                      <IonLabel>
                        <h2><IonSkeletonText animated style={{ width: '100%', float: 'left'  }} /></h2>
                        <p><IonSkeletonText animated style={{ width: '80%', float: 'left'  }} /></p>
                      </IonLabel>
                      <IonIcon className="offline-indicator" icon={cloudDownloadOutline} style={{color: "gray"}} slot="end"/>
                    </IonItem>;
                }else{
                  return <IonItemSliding key={key} className="trackListed">
                    <IonItem key={track.title} mode='ios'>
                    {/* <IonItem key={track.title} mode='ios' button> */}
                      <IonThumbnail slot="start" className='thumbnail' onClick={() => doPlay(track, hotTracks)}>
                        <img alt={track.title} src={img(track.img)}/>
                      </IonThumbnail>
                      <IonIcon onClick={() => doPlay(track, hotTracks)} icon={relevantIcon} className={playingClass} slot="start"/>
                      {/* {(songPlaying)?(<IonIcon onClick={() => doPlay(track, hotTracks)} icon={relevantIcon} className={playingClass} slot="start"/>):("")} */}
                      <IonLabel onClick={() => doPlay(track, hotTracks)}>
                        <h2>{track.title}</h2>
                        <p>{track.artist}</p>
                        {/* <IonIcon icon={downloadOutline} slot="end"/> */}
                      </IonLabel>
                      <IonIcon icon={(isFavTrack(state, track))?(heartCircle):(heartCircleOutline)} onClick={()=> addFav(track)} slot="end"/>
                      <IonIcon className="offline-indicator" onClick={(e)=>{actionBtnsClick(e, {action: 'save', song: track})}} icon={(isOfflineTrack(state, track))?(cloudDownload):(cloudDownloadOutline)} slot="end"/>
                    </IonItem>
                    <IonItemOptions key={track.id}>
                        <IonItemOption color="success" onClick={(e)=> selectPlaylist(track)}><IonIcon slot="start" icon={add}/>Playlist</IonItemOption>
                    </IonItemOptions>
                  </IonItemSliding>
                }
              }) : (<p style={{textAlign: "center"}}>No tracks available</p>)
            }
            <IonButton expand="full" fill="clear" color="rgb(238, 20, 19)" onClick={() => history.push("/songs")}><IonIcon icon={chevronDown}></IonIcon></IonButton>
          </IonList>
        {/* </div> */}
        <div className="topHome">
          <IonList mode='ios'>
            <IonListHeader mode='ios'>
              <IonLabel>Top Albums</IonLabel>
            </IonListHeader>
            <IonGrid >
              <IonRow>
                {
                  (hotAlbums.length>0) ? hotAlbums.map((album: any) => {
                    // console.log(currentAlbum.id, album.id);
                    var relevantIcon = play;
                    var playingClass:string = "";
                    if (currentAlbum) {
                      relevantIcon = ((currentAlbum.id === album.id)&&(currentAlbum.playing))?(pauseCircle): (play)
                      // relevantIcon = ((currentTrack.id === track.id)&&(!songPlaying.paused))?(pauseCircle): (play);
                      playingClass = (currentAlbum.id === album.id)?("playAlbumBtn currentAlbumInList"): ("playAlbumBtn");
                    };

                    if(album.type === "loader"){
                      return <IonCol
                        size="6" size-md="2.99"
                        className="new-track"
                        key={album.id}
                        >
                        <IonSkeletonText animated style={{ width: '80%', float: 'left'  }} />
                        {/* <img alt={album.title} style={{backgroundImage:"url("+img(album.img)+")" }} className="albumCover albumContainer" src={img(album.img)} onClick={() => gotoAlbum(album, "no")}/> */}
                        <IonItem lines="none" className='albumContainer theDesription' mode='ios'>
                          <IonLabel onClick={() => gotoAlbum(album, "no")} mode='ios'>
                            <h3><IonSkeletonText animated style={{ width: '80%', float: 'left'  }} /></h3>
                            <p><IonSkeletonText animated style={{ width: '80%', float: 'left'  }} /></p>
                          </IonLabel>
                          <IonIcon className='playAlbumBtn' icon={play} slot="start"/>
                        </IonItem>
                      </IonCol>;
                    }else{
                      return <IonCol
                        size="6" size-md="2.99"
                        className="new-track"
                        key={album.id}
                        >
                        <img alt={album.title} style={{backgroundImage:"url("+img(album.img)+")" }} className="albumCover albumContainer" src={img(album.img)} onClick={() => gotoAlbum(album, "no")}/>
                        <IonItem lines="none" className='albumContainer theDesription' mode='ios'>
                          <IonLabel onClick={() => gotoAlbum(album, "no")} mode='ios'>
                            <h3>{album.title} ({album.noOfSongs} tracks)</h3>
                            <p>{album.artist}</p>
                          </IonLabel>
                          <IonIcon className={playingClass+ " albumIconPlay"} icon={relevantIcon} onClick={() => gotoAlbum(album, "yes")} slot="start"/>
                        </IonItem>
                      </IonCol>;
                    }
                  }) : ("")
                }
              </IonRow>
            </IonGrid>
            <IonButton expand="full" fill="clear" color="rgb(238, 20, 19)" onClick={() => history.push("/albums")}><IonIcon icon={chevronDown}></IonIcon></IonButton>
          </IonList>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </IonContent>

      <IonLoading
          mode='ios'
          isOpen={showLoadingState.showLoading}
          onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
          message={showLoadingState.showLoadingMessage}
      />
      {/* {
        (progress !== 0)?(
          <IonProgressBar type="indeterminate" buffer={.2} value={progress}></IonProgressBar>
        ):("")
      } */}
      {/* <IonAlert
          mode='ios'
          isOpen={showAlertState.showAlert}
          onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
          header={showAlertState.header}
          subHeader={showAlertState.subHeader}
          message={showAlertState.message}
          buttons={showAlertState.buttons}
      /> */}
    </IonPage>
  );
};

export default Tab1;