import {
    Chart as ChartJS,
    
    registerables
  } from 'chart.js';
  import { Bar, Doughnut } from 'react-chartjs-2';
  
  ChartJS.register(...registerables);
  
  
  
  
  const Charts: React.FC<any> = ({data}) => {
    const myOptions = {
      maintainAspectRatio: true,
      responsive: true,
      plugins: {
        title: data.title,
        legend: {
          position: 'top' as const,
        },
      },
    };
    // console.log(data)
    var usefullData = data;
    // if (!usefullData) {
      if (Object.keys(usefullData).length === 0) {
        usefullData = {
          labels: [],
          datasets: [
          {
            
          }
        ]};
      }
    // }
  
    return (
      <>
        {
          (
            data.type === 'doughut'
          )?(
              <Doughnut options={myOptions} data={usefullData} />
          ):(
              // <Bar data={usefullData} />
              <Bar options={myOptions} data={usefullData} />
          )
        }
      </>
      // <Bar options={myOptions} data={usefullData} />
    );
  }
  export default Charts;