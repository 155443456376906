import React, { useCallback, useContext } from 'react';
import { IonIcon, IonThumbnail } from '@ionic/react';
import { play, pause, closeCircle } from 'ionicons/icons';

import {
AppContext, getPlaying, getCurrentTrack, openPlayer,
pauseTrack, playTrack, stopPlay
} from '../Services/State';

import { img } from '../Services/utils';

import './TrackPreview.css';

interface ProgressProps {
    playing: any;
    track: any;
}

const TrackProgress: React.FC<ProgressProps> = ({ playing, track }) => {
    const progress = playing.progress;
    // const left = track.time - progress;
    const percent = (progress / track.time) * 100;

    return (
        <div className="track-preview-progress">
            <div className="track-preview-progress-track">
                <div className="track-preview-progress-current" style={{ width: `${percent}%` }}></div>
            </div>
        </div>
    );
}
interface ContainerProps {
    tabBarTop?: number;
}

const TrackPreview: React.FC<ContainerProps> = ({ tabBarTop }) => {
    const { state, dispatch } = useContext(AppContext);

    const playing = getPlaying(state);
    const track = getCurrentTrack(state);

    const doPlayToggle = useCallback((e, track) => {
        // Stop the toggle from opening the modal
        e.stopPropagation();

        if (playing.paused) {
            dispatch(playTrack(track));
        } else {
            dispatch(pauseTrack());
        }
    }, [playing, dispatch]);
    const closePlay = useCallback((e, track)=>{
        // Stop the toggle from opening the modal
        e.stopPropagation();
        dispatch(stopPlay());
    }, [ dispatch]);

    if (!playing) return null;

    return (
        <div style={{ top: `${tabBarTop}px` }} className="track-preview" onClick={() => dispatch(openPlayer())}>
            <TrackProgress playing={playing} track={track} />

            <div className="track-preview-wrapper">
                <div className="track-thumbnail">
                    <IonThumbnail>
                        <img alt={track.title} src={img(track.img)} className="track-art" />
                    </IonThumbnail>
                </div>


                <div className="track-info">
                    <span className="track-name">{track.title}</span>
                        &middot;
                    <span className="track-artist">{track.artist}</span>
                </div>

                <div className="track-controls">
                    {playing.paused ? (
                        <IonIcon color="favorite" icon={play} onClick={(e)=>{doPlayToggle(e, track)}} />
                        ) : (
                        <IonIcon color="favorite" icon={pause} onClick={(e)=>{doPlayToggle(e, track)}} />
                    )}
                </div>
                <div className="track-controls track-controls-close">
                    <IonIcon color="primary" icon={closeCircle} onClick={(e)=>{closePlay(e, track)}} />
                </div>
            </div>
        </div>
    )
};

export default TrackPreview;