import { IonTitle, IonCard, IonCardContent, IonAlert } from "@ionic/react";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext} from "../../Services/State";
import Charts from "../../components/charts/Charts"
import { appAuthDomain } from "../../Services/utils";

const AdminStats: React.FC<any> = ({whichModal, showModal, userRole, setShowModal, presentingElement, onDismiss}) =>{
    interface AlertTypeInterface {
        header: string;
        subHeader: string;
        message: string;
        buttons: any;
        showAlert: boolean;
    }
    const { state } = useContext(AppContext);
    const [chartTypesData, setChartTypesData] = useState({});
    const [payData, setPayData] = useState({});
    const [showAlertState, setShowAlert] = useState<AlertTypeInterface>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});

    let showModal5 = false;
    // let setShowModal5:Function;

    if (whichModal === 5) {
        showModal5 = showModal;
        // setShowModal5 = setShowModal;
    }

    const moreInsgitsPlots = useCallback((plotsData:any)=>{
        // console.log("setupData", plotsData)
        if (Object.keys(plotsData).length > 0) {
            var plots = plotsData.dashPlots;
            var payPlots = plotsData.payPlots;
            if (plots !== "") {
                setChartTypesData(plots);
            }
            if(payPlots !==""){
                setPayData(payPlots); 
            }
        }
    }, []);

    useEffect(()=>{
        const fetchStats = ()=>{
            var buttonActions:any = [];
            var alertStateVars:any = {};
            var config = {
                headers: {
                    Authorization: "Bearer "+state.auth.user.accessToken
                }
            };
            var url = appAuthDomain("backend/includes/admin/music.php?page=admin&action=getStats");
            if(userRole === "Super admin"){
                url = appAuthDomain("backend/includes/admin/music.php?page=admin&action=getAdminStats");
            }
            return axios.get(url, config).then((result:any)=>{
                // console.log(result);
                return result.data;
            }).then ((response)=>{
                // console.log(response);
                if (response.success) {
                    moreInsgitsPlots(response.data);
                } else {
                    moreInsgitsPlots([]);
                };
            }).catch((error:any)=>{
                console.log(error.message);
                buttonActions = [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            // setShowModal5(false)
                        }
                    },
                    {
                        text: 'Retry',
                        handler: () => {
                            setTimeout(() => {
                                fetchStats();
                            }, 500);
                        }
                    }
                ];
                alertStateVars = {
                    header: error.message,
                    subHeader: "", 
                    message: "",
                    buttons: buttonActions
                };
                setShowAlert({...alertStateVars, showAlert: true});
            });
        }
        if(showModal5){
            fetchStats();
        }

    },[showModal5, userRole, state, moreInsgitsPlots])

    return (
        <>
        <IonCard mode='ios' className="statsCard">
            <IonCardContent >
                <IonTitle style={{textAlign:"center"}}>Music stats</IonTitle>
                <Charts data={chartTypesData} />
            </IonCardContent>
        </IonCard>
        {
            (userRole === "Super admin")?(
                <IonCard mode='ios' className="statsCard">
                    <IonCardContent >
                        <IonTitle style={{textAlign:"center"}}>Payment stats</IonTitle>
                        <Charts data={payData}/>
                    </IonCardContent>
                </IonCard>
            ):("")
        }
        <IonAlert
            mode='ios'
            isOpen={showAlertState.showAlert}
            onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
            header={showAlertState.header}
            subHeader={showAlertState.subHeader}
            message={showAlertState.message}
            buttons={showAlertState.buttons}
        />
        </>
    );
}
export default AdminStats;