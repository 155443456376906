import {IonCard, IonCardContent, IonAlert, IonLoading, IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonItem, IonLabel, IonIcon, IonSearchbar, IonContent } from "@ionic/react";
import axios from "axios";
import { person } from "ionicons/icons";
import {useCallback, useContext, useEffect, useState } from "react";
import { AppContext} from "../../Services/State";
import { appAuthDomain} from "../../Services/utils";

const GetUsers: React.FC<any> = ({whichModal, showModal, userRole, setShowModal, presentingElement, onDismiss}) =>{
    interface AlertTypeInterface {
        header: string;
        subHeader: string;
        message: string;
        buttons: any;
        showAlert: boolean;
    }
    const { state } = useContext(AppContext);
    const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});
    const [usersData, setUsersData] = useState({all: 0, today: 10, thisWeek: 0, thisMonth: 0, lastYear: 0});
    const [showAlertState, setShowAlert] = useState<AlertTypeInterface>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});
    const [canDismiss, setCanDismiss] = useState(false);
    const [usersInfo, setUsersInfo] = useState([]);
    const [searchedUser, setSearchedUser] = useState('');

    let showModal5 = false;
    // let setShowModal5:Function;

    if (whichModal === 6) {
        showModal5 = showModal;
        // setShowModal5 = setShowModal;
    }

    const handleClose = useCallback(() => {
        setCanDismiss(false);
    }, [setCanDismiss]);

    useEffect(()=>{
        var buttonActions:any = [];
        var alertStateVars:any = {};
        (function doSearch(){
            if(searchedUser !== ""){
            var config = {
                headers: {
                    Authorization: "Bearer "+state.auth.user.accessToken
                }
            };
            return axios.get(appAuthDomain("backend/includes/user/userProfile.php?type=full&keyWords="+searchedUser), config).then((result:any)=>{
                // console.log(result);
                return result.data;
            }).then ((response)=>{
                // console.log(response);
                if (response.success) {
                var respInfo = response.data;
                setUsersInfo(respInfo);
                // dispatch(loggedIn(respInfo));
                } else {
                setUsersInfo([]);
                };
            }).catch((error:any)=>{
                console.log(error.message);
                console.log(error.message);
            buttonActions = [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        // setShowModal5(false)
                    }
                },
                {
                    text: 'Retry',
                    handler: () => {
                        setTimeout(() => {
                            doSearch();
                        }, 500);
                    }
                }
            ];
            alertStateVars = {
                header: error.message,
                subHeader: "", 
                message: "",
                buttons: buttonActions
            };
            setShowAlert({...alertStateVars, showAlert: true});
            });
            }else{
            // setHotTracks(localHotTracks);
            }
        })();   
    
        },[searchedUser, state.auth.user.accessToken]);
    

    const fetchUSers =(range:number)=>{
        var buttonActions:any = [];
        var alertStateVars:any = {};
        var config = {
            headers: {
                Authorization: "Bearer "+state.auth.user.accessToken
            }
        };
        setUsersInfo([]);
        setCanDismiss(true);
        setShowLoading({showLoadingMessage: "Fetching users...", showLoading: true});
        var url = appAuthDomain("backend/includes/user/userProfile.php?action=getAllUsers&lastId=0&dateRange="+range);
            return axios.get(url, config).then((result:any)=>{
                // console.log(result);
                return result.data;
            }).then ((response)=>{
                setTimeout(() => {
                    setShowLoading({...showLoadingState, showLoading: false});
                }, 900);
                if (response.success) {
                    setUsersInfo(response.data);
                } else {
                    setUsersInfo([]);
                };
            }).catch((error:any)=>{
                console.log(error.message);
                buttonActions = [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            // setShowModal5(false)
                        }
                    },
                    {
                        text: 'Retry',
                        handler: () => {
                            setTimeout(() => {
                                fetchUSers(range);
                            }, 500);
                        }
                    }
                ];
                alertStateVars = {
                    header: error.message,
                    subHeader: "", 
                    message: "",
                    buttons: buttonActions
                };
                setShowAlert({...alertStateVars, showAlert: true});
            });

    }

    useEffect(()=>{
        const fetchStats = ()=>{
            var buttonActions:any = [];
            var alertStateVars:any = {};
            var config = {
                headers: {
                    Authorization: "Bearer "+state.auth.user.accessToken
                }
            };
            var url = appAuthDomain("backend/includes/user/userProfile.php?action=getUserCounts");
            return axios.get(url, config).then((result:any)=>{
                // console.log(result);
                return result.data;
            }).then ((response)=>{
                console.log(response);
                if (response.success) {
                    setUsersData(response.data.userCounts)
                } else {
                    
                };
            }).catch((error:any)=>{
                console.log(error.message);
                buttonActions = [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            // setShowModal5(false)
                        }
                    },
                    {
                        text: 'Retry',
                        handler: () => {
                            setTimeout(() => {
                                fetchStats();
                            }, 500);
                        }
                    }
                ];
                alertStateVars = {
                    header: error.message,
                    subHeader: "", 
                    message: "",
                    buttons: buttonActions
                };
                setShowAlert({...alertStateVars, showAlert: true});
            });
        }
        if(showModal5){
            fetchStats();
        }

    },[showModal5, userRole, state])

    return (
        <>
        <IonCard mode='ios' className="usersCard" onClick={()=>fetchUSers(0)}>
            <IonCardContent>
                <h1 style={{textAlign: 'center', fontWeight: "bold"}}>{usersData.all}</h1>
                <h3 style={{textAlign: 'center'}}>All users</h3>
            </IonCardContent>
        </IonCard>
        <IonCard mode='ios' className="usersCard" onClick={()=>fetchUSers(1)}>
            <IonCardContent >
                <h1 style={{textAlign: 'center', fontWeight: "bold"}}>{usersData.today}</h1>
                <h3 style={{textAlign: 'center'}}>Signed up Today</h3>
            </IonCardContent>
        </IonCard>
        <IonCard mode='ios' className="usersCard" onClick={()=>fetchUSers(7)}>
            <IonCardContent >
                <h1 style={{textAlign: 'center', fontWeight: "bold"}}>{usersData.thisWeek}</h1>
                <h3 style={{textAlign: 'center'}}>Signed up this week</h3>
            </IonCardContent>
        </IonCard>
        <IonCard mode='ios' className="usersCard" onClick={()=>fetchUSers(30)}>
            <IonCardContent >
                <h1 style={{textAlign: 'center', fontWeight: "bold"}}>{usersData.thisMonth}</h1>
                <h3 style={{textAlign: 'center'}}>Signed up this Month</h3>
            </IonCardContent>
        </IonCard>
        <IonCard mode='ios' className="usersCard" onClick={()=>fetchUSers(365)}>
            <IonCardContent >
                <h1 style={{textAlign: 'center', fontWeight: "bold"}}>{usersData.lastYear}</h1>
                <h3 style={{textAlign: 'center'}}>Last 365 days</h3>
            </IonCardContent>
        </IonCard>
        <IonModal
            mode='ios'
            isOpen={canDismiss}
            onDidDismiss={handleClose}
            swipeToClose={true}
            presentingElement={presentingElement}
            showBackdrop={true}
            // trigger="open-payment-modal"
            // breakpoints={[ 0.5, 1]}
            // initialBreakpoint={0.5}
        >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton onClick={handleClose}>Close</IonButton>
                    </IonButtons>
                    <IonTitle>Users</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSearchbar mode='ios' className='mySearchBTN' placeholder='Search users' 
                 value={searchedUser} onIonChange={e => setSearchedUser(e.detail.value!)}></IonSearchbar>
                {(usersInfo.length > 0)?(
                    usersInfo.map((user: any, key:number) => {
                        return <IonItem key={key}>
                            {/* <IonThumbnail slot="start" className='thumbnail'> */}
                            <IonIcon icon={person} slot="start" className='thumbnail'/>
                            {/* <img alt={track.title} src={img(track.img)}/> */}
                            {/* </IonThumbnail> */}
                            <IonLabel>
                            <h2>{user.firstname} {user.lastname}</h2>
                            <p>{user.email}</p>
                            </IonLabel>
                        </IonItem>
                    })
                    
                ):(
                    <p style={{textAlign: 'center'}}>No Users</p>
                )}
            </IonContent>
            
            {/* <IonCard>
                
            </IonCard> */}
        </IonModal>
        <IonLoading
            mode='ios'
            isOpen={showLoadingState.showLoading}
            onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
            message={showLoadingState.showLoadingMessage}
        />
        <IonAlert
            mode='ios'
            isOpen={showAlertState.showAlert}
            onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
            header={showAlertState.header}
            subHeader={showAlertState.subHeader}
            message={showAlertState.message}
            buttons={showAlertState.buttons}
        />
        </>
    );
}
export default GetUsers;