import { useState, ReactChild, ReactFragment, ReactPortal, useEffect, useContext, useCallback } from 'react';
import { IonAlert, IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonSearchbar, IonSelect, IonSelectOption, IonThumbnail } from '@ionic/react';
import { add, chevronDown} from 'ionicons/icons';
import axios from 'axios';

import { AppContext,} from '../../Services/State';
import { appAuthDomain, img } from '../../Services/utils';


const AddEditAlbums: React.FC<any> = ({whichModal, showModal, userRole, setShowModal, presentingElement, onDismiss}) =>{
    // const { state, dispatch } = useContext(AppContext);
    const { state } = useContext(AppContext);

    interface AlertTypeInterface {
        header: string;
        subHeader: string;
        message: string;
        buttons: any;
        showAlert: boolean;
    }
    var albums:any | null  = [];
    let defaultSelectedAlbum: {albumID?: string; albumName?: string; albumBy?: string; albumYear?:string; albumType?: any; albumCategory?: any; albumImg?: any} = {};

    const [albumsInfo, setAlbums] = useState(albums);
    const [myGenreInfo, setMyGenre] = useState([]);
    const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});
    const [showAlertState, setShowAlert] = useState<AlertTypeInterface>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});
    const [searchText, setSearchText] = useState('');
    const [albumType, setAlbumType] = useState('');
    const [genre, setGenre] = useState('');
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [selectedAlbumInfo, setSelectedAlbumInfo] = useState(defaultSelectedAlbum);

    
    let showModal1 = false;
    let showAlbum = false;
    let setShowModal1:Function;
    let setShowAlbum:Function;
    if (whichModal === 1) {
        showModal1 = showModal;
        setShowModal1 = setShowModal;
    } else if (whichModal ===4) {
        showAlbum = showModal;
        setShowAlbum = setShowModal;
    }

    const myFunction = useCallback(()=>{
        var config = {
            headers: {
                Authorization: "Bearer "+state.auth.user.accessToken
            }
        };
        var url = "backend/includes/admin/music.php?page=admin&action=getAlbums";
        if(userRole === "Super admin"){
            url = "backend/includes/admin/music.php?page=admin&action=getAlbums&myRole=super";
        }
        return axios.get(appAuthDomain(url), config).then((result:any)=>{
            // console.log(result);
            return result.data;
        }).then ((response)=>{
            // console.log(response);
            if (response.success) {
                setAlbums(response.data);
                // dispatch(loggedIn(respInfo));
            } else {
                setAlbums([]);
            };
        }).catch((error:any)=>{
            console.log("out");
            console.log(error.message);
            // dispatch(loggedIn(null));
        });

    }, [state.auth.user.accessToken, userRole]);

    var submitFunction = (e: any)=>{
        e.preventDefault();
        if(albumType || genre){
            var myText = "ALBUM type.";
            if((albumType === "")&&(genre==="")){myText = "ALBUM type and GENRE."}else if(genre===""){myText = "GENRE."}
            var buttonActions = [
                {
                    text: 'Okay',
                    handler: () => {
                    }
                }
            ];
            var alertStateVars = {
                header: "Please select "+myText,
                subHeader: "", 
                message: "",
                buttons: buttonActions
            };
            setShowAlert({...alertStateVars, showAlert: true});
        }else{
            var url = "backend/includes/admin/music.php?page=admin&action=editAlbum";
            const config = {
                headers: {
                    Authorization: "Bearer "+ state.auth.user.accessToken,
                    "Content-Type": "multipart/form-data"
                }
            };
            var orderForm = new FormData(e.target);
            orderForm.append("appType", "store");
            // console.log("url: ", url)
            // console.log("orderForm: ", e.target.addAlbum.value)
            if(e.target.addAlbum){
                url = "backend/includes/admin/music.php?page=admin&action=addAlbums";
            }
            setShowLoading({showLoadingMessage: "Submitting album...", showLoading: true});
            axios.post(appAuthDomain(url), orderForm, config).then(res=>{
                return res.data;
            }).then(response=>{
                // console.log(response);
                setTimeout(() => {
                    setShowLoading({...showLoadingState, showLoading: false});
                }, 900);
                setAlbumType('');
                setGenre('');
                var buttonActions:any = [];
                var alertStateVars:any = {};
                if (response.success) {
                    buttonActions = [
                        {
                            text: 'Done',
                            handler: () => {
                                setShowModal1(false);
                            }
                        }
                    ];
                    alertStateVars = {
                        header: response.msg,
                        subHeader: "", 
                        message: response.msg2,
                        buttons: buttonActions
                    };
                    setShowAlert({...alertStateVars, showAlert: true});
                    // onDismiss('manager');
                    // setShowModal1(false);
                } else {
                    buttonActions = [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                setShowModal1(false);
                            }
                        },
                        {
                            text: 'Retry',
                            handler: () => {
                                setTimeout(() => {
                                    submitFunction(e);
                                }, 500);
                            }
                        }
                    ];
                    alertStateVars = {
                        header: response.msg,
                        subHeader: "", 
                        message: response.msg2,
                        buttons: buttonActions
                    };
                    setShowAlert({...alertStateVars, showAlert: true});
                };
            }).catch(err=>{
                console.log(err.message);
                setTimeout(() => {
                    setShowLoading({...showLoadingState, showLoading: false});
                }, 900);
                var buttonActions = [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            console.log('Confirm Cancel');
                        }
                    },
                    {
                        text: 'Retry',
                        handler: () => {
                            submitFunction(e);
                        }
                    }
                ];
                var alertStateVars = {header: "Error encountered", subHeader: "", message: err.message, buttons: buttonActions};
                setTimeout(() => {
                    setShowAlert({...alertStateVars, showAlert: true});
                }, 1001);
            })
        }
    }

    const myEditFunction = (elem: any)=>{
        var albumID = elem.id;
        var released = elem.released;
        var listed = elem.listed;
        var isReleased = "Release";
        var isListed = "Unlist";
        if(userRole === "Super admin"){
            if(parseInt(elem.listed) ===1){isListed = "List";}
            if(parseInt(elem.released) ===1){isReleased = "Unrelease";}
        }else{
            if(parseInt(elem.released) ===1){isReleased = "Unrelease";}
        }

        setSelectedAlbumInfo({
            albumID : elem.id, albumBy: elem.artist, albumName: elem.title, albumCategory: elem.genre, albumType: elem.type,
             albumImg: elem.img, albumYear: elem.year
        })

        var buttonActions: any = [];
        if(userRole === "Super admin"){
            buttonActions = [
                (parseInt(elem.addedBy) === parseInt(state.auth.user.id))?({
                    text: isReleased,
                    cssClass: 'secondary',
                    handler: () => {
                        // doTheRequest(albumID, released);
                        doTheRequest(albumID, released, "release");
                    }
                }):({}),
                {
                    text: isListed,
                    cssClass: 'secondary',
                    handler: () => {
                        doTheRequest(albumID, listed, "List");
                    }
                },(parseInt(elem.addedBy) === parseInt(state.auth.user.id))?({
                    text: "Edit",
                    cssClass: 'secondary',
                    handler: () => {
                        setShowAlbum(false)
                        setShowModalEdit(true);
                    }
                }):({}),
                {   
                    text: "Cancel",
                    role: 'cancel',
                    handler: () => {
                    }
                }
            ];
        }else{
            buttonActions = [
                {
                    text: isReleased,
                    // role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                      doTheRequest(albumID, released, "release");
                    }
                },
                {   
                    text: "Edit",
                    handler: () => {
                        if(userRole !== "Super admin"){
                            setShowAlbum(false)
                            setShowModalEdit(true);
                        }
                    }
                }, 
                {   
                    text: "Cancel",
                    handler: () => {}
                }
            ];
        }
        var alertStateVars = {header: "Choose action", subHeader: "", message: "", buttons: buttonActions};
        setTimeout(() => {
            setShowAlert({...alertStateVars, showAlert: true});
        }, 1001);

        const doTheRequest = (id: any, released: any, action:any)=>{
            var url = "backend/includes/admin/music.php?page=admin&action=release&type=album&id="+id+"&state="+released;
            if(action === "List"){
                url = "backend/includes/admin/music.php?page=admin&action=unlist&type=album&id="+id+"&state="+released;
            }
            const config = {
                headers: {
                    Authorization: "Bearer "+state.auth.user.accessToken,
                    "Content-Type": "multipart/form-data"
                }
            };
            setShowLoading({showLoadingMessage: "Submitting...", showLoading: true});
            axios.post(appAuthDomain(url), {}, config).then(res=>{
                return res.data;
            }).then(response=>{
                // console.log(response);
                setTimeout(() => {
                    setShowLoading({...showLoadingState, showLoading: false});
                }, 900);
                var buttonActions:any = [];
                var alertStateVars:any = {};
                if (response.success) {
                    buttonActions = [
                        {
                            text: 'Done',
                            handler: () => {
                            }
                        }
                    ];
                    alertStateVars = {
                        header: response.msg,
                        subHeader: "", 
                        message: response.msg2,
                        buttons: buttonActions
                    };
                    setShowAlert({...alertStateVars, showAlert: true});
                } else {
                    buttonActions = [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                            }
                        },
                        {
                            text: 'Retry',
                            handler: () => {
                                setTimeout(() => {
                                    doTheRequest(id, released, action);
                                }, 500);
                            }
                        }
                    ];
                    alertStateVars = {
                        header: response.msg,
                        subHeader: "", 
                        message: response.msg2,
                        buttons: buttonActions
                    };
                    setShowAlert({...alertStateVars, showAlert: true});
                };
            }).catch(err=>{
                console.log(err.message);
                setTimeout(() => {
                    setShowLoading({...showLoadingState, showLoading: false});
                }, 900);
                var buttonActions = [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                        }
                    },
                    {
                        text: 'Retry',
                        handler: () => {
                            doTheRequest(id, released, action);
                        }
                    }
                ];
                var alertStateVars = {header: "Error encountered", subHeader: "", message: err.message, buttons: buttonActions};
                setTimeout(() => {
                    setShowAlert({...alertStateVars, showAlert: true});
                }, 1001);
            })
        }

    }

    useEffect(()=>{

        const getMyGenres = ()=>{
            var config = {
                headers: {
                    Authorization: "Bearer "+state.auth.user.accessToken
                }
            };
            return axios.get(appAuthDomain("backend/includes/admin/music.php?page=admin&action=getGenre"), config).then((result:any)=>{
            //   console.log(result);
              return result.data;
            }).then ((response)=>{
            //   console.log(response);
              if (response.success) {
                var respInfo = response.data;
                setMyGenre(respInfo);
                // dispatch(loggedIn(respInfo));
              } else {
                setMyGenre([]);
              };
            }).catch((error:any)=>{
              console.log(error.message);
              setMyGenre([]);
              // dispatch(loggedIn(null));
            });
        }

        if(showModalEdit || showModal1){
            getMyGenres();
        }
    },[showModalEdit, showModal1, state.auth.user.accessToken]);

    useEffect(()=>{
        if(searchText !== ""){
            (function doSearch(){
                // return axios.get(appAuthDomain(""), {headers}).then((result:any)=>{
                var config = {
                    headers: {
                        Authorization: "Bearer "+state.auth.user.accessToken
                    }
                };
                return axios.get(appAuthDomain("backend/includes/admin/music.php?page=admin&action=searchAlbums&keyWords="+searchText), config).then((result:any)=>{
                //   console.log(result);
                return result.data;
                }).then ((response)=>{
                //   console.log(response);
                if (response.success) {
                    var respInfo = response.data;
                    setAlbums(respInfo);
                    // dispatch(loggedIn(respInfo));
                } else {
                    setAlbums([]);
                };
                }).catch((error:any)=>{
                console.log(error.message);
                console.log(searchText)
                // dispatch(loggedIn(null));
                });
            })();
        }

    },[searchText, state]);

    useEffect(()=>{
        if (showAlbum) {
            myFunction();
        }
    }, [showAlbum, myFunction]);

    
    // const fileInput = useRef(null);
    // const [inputElement, setInputElement({} as HTMLInputElement);

    return (
        <>
            {(showModal1)?(
                <IonContent>
                <h3 style={{textAlign:"center"}}>Add album</h3> 
                <form  action="backend/includes/admin/music.php?page=admin&action=addAlbums" onSubmit={submitFunction}>
                
                    <>
                    <input name='addAlbum' value="add" type="hidden"/>
                    <IonItem className="myFormInputs">
                        <IonLabel position="floating" >Album type</IonLabel>
                        <IonSelect name="type">
                            <IonSelectOption value="Album">Album</IonSelectOption>
                            <IonSelectOption value="EP">EP</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem className="myFormInputs">
                        <IonLabel position="floating" >Album Genre</IonLabel>
                        <IonSelect name="genre" >
                            {
                                (myGenreInfo.length> 0)?(
                                    myGenreInfo.map((genre:any)=>{
                                        return <IonSelectOption key={genre.id} value={genre.id} >{genre.title}</IonSelectOption>

                                    })
                                ):(
                                    <IonSelectOption value="">No albums available</IonSelectOption>
                                )
                            }
                        </IonSelect>
                    </IonItem>
                    <IonItem className="myFormInputs">
                        <IonLabel position="floating" >Album name</IonLabel>
                        <IonInput name="name" placeholder="Product name" className="theIput" required />
                    </IonItem>

                    <IonItem className="myFormInputs">
                        <IonLabel position="floating" >Artist</IonLabel>
                        <IonInput name="artist" placeholder="Product description" className="theIput" required />
                    </IonItem>
                    <IonItem className="myFormInputs">
                        <IonLabel position="floating" >Album year</IonLabel>
                        <IonInput name="year" placeholder="Product price" className="theIput" required />
                    </IonItem>

                    <IonItem className="myFormInputs">
                        <IonLabel position="stacked">Album cover</IonLabel>
                        <input type="file" accept="image/*" name="cover" placeholder="Product image" className="theIput" required />
                    </IonItem>
                    </>
                    <br/>
                    <br/>
                    <IonButton expand="block" fill="outline" shape="round"  className="btnSubmit" type='submit'><IonIcon icon={add}/>Add album</IonButton>
                </form>
                </IonContent>
            ):(
                (showAlbum)?(
                    <IonContent>
                        <h3 style={{textAlign:"center"}}>Albums</h3> 
                        <IonSearchbar className='dashSearch' value={searchText} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
                        <>
                        {(albumsInfo.length > 0) ? albumsInfo.map((album: { id: number | null | undefined; img: any; title: string | undefined; artist: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }) => {
                            return <IonItem key={album.id} onClick={() => myEditFunction(album)} button>
                                <IonThumbnail slot="start">
                                <img alt={album.img} src={img(album.img)}/>
                                </IonThumbnail>
                                <IonLabel>
                                <h2>{album.title}</h2>
                                <p>{album.artist}</p>
                                </IonLabel>
                            </IonItem>
                        }) : (<p style={{textAlign: "center"}}>No tracks available</p>)
                        }
                        </>
                        <br/>
                        <br/>
                        <IonButton expand="full" fill="clear" className="btnSubmit" type='submit'><IonIcon icon={chevronDown}/>more</IonButton>
                    </IonContent>
                ):(
                    (showModalEdit)?(
                        <IonContent>
                            <h3 style={{textAlign:"center"}}>Edit {selectedAlbumInfo.albumName} by {selectedAlbumInfo.albumBy} </h3> 
                            <form  action="backend/includes/admin/music.php?page=admin&action=editAlbum" onSubmit={submitFunction}>
                                <input type="hidden" name="id" defaultValue={selectedAlbumInfo.albumID}/>
                                <>
                                <IonItem className="myFormInputs">
                                    <IonLabel position="floating" >Album type</IonLabel>
                                    <IonSelect name="type" onIonChange={(ev) => setAlbumType(ev.detail.value)}>
                                        { 
                                            (selectedAlbumInfo.albumType === "EP")?(
                                                <><IonSelectOption value="EP">EP</IonSelectOption>
                                                <IonSelectOption value="Album">Album</IonSelectOption></>
                                            ):(
                                            <>
                                                <IonSelectOption value="Album">Album</IonSelectOption>
                                                <IonSelectOption value="EP">EP</IonSelectOption>
                                            </> 
                                            )
                                        }
                                        
                                    </IonSelect>
                                </IonItem>
                                <IonItem className="myFormInputs">
                                    <IonLabel position="floating" >Album Genre</IonLabel>
                                    <IonSelect name="genre" onIonChange={(ev) => setGenre(ev.detail.value)}>
                                        {
                                            (myGenreInfo.length> 0)?(
                                                myGenreInfo.map((genre:any)=>{
                                                    return <IonSelectOption key={genre.id} value={genre.id} >{genre.title}</IonSelectOption>

                                                })
                                            ):(
                                                <IonSelectOption value="">No albums available</IonSelectOption>
                                            )
                                        }
                                    </IonSelect>
                                </IonItem>
                                <IonItem className="myFormInputs">
                                    <IonLabel position="floating" >Album name</IonLabel>
                                    <IonInput name="name" value={selectedAlbumInfo.albumName} placeholder="Product name" className="theIput" required />
                                </IonItem>

                                <IonItem className="myFormInputs">
                                    <IonLabel position="floating" >Artist</IonLabel>
                                    <IonInput name="artist" value={selectedAlbumInfo.albumBy} placeholder="Product description" className="theIput" required />
                                </IonItem>
                                <IonItem className="myFormInputs">
                                    <IonLabel position="floating" >Album year</IonLabel>
                                    <IonInput name="year" value={selectedAlbumInfo.albumYear} placeholder="Product price" className="theIput" required />
                                </IonItem>

                                <IonItem className="myFormInputs">
                                    <IonLabel position="stacked">Album cover</IonLabel>
                                    <input type="file" accept="image/*" name="cover" placeholder="Product image" className="theIput" required />
                                </IonItem>
                                </>
                                <br/>
                                <br/>
                                <IonButton expand="block" fill="outline" shape="round"  className="btnSubmit" type='submit'><IonIcon icon={add}/>Edit album</IonButton>
                            </form>
                        </IonContent>
                    ):("")
                )
            )}

            <IonLoading
                mode='ios'
                isOpen={showLoadingState.showLoading}
                onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
                message={showLoadingState.showLoadingMessage}
            />
            <IonAlert
                mode='ios'
                isOpen={showAlertState.showAlert}
                onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
                header={showAlertState.header}
                subHeader={showAlertState.subHeader}
                message={showAlertState.message}
                buttons={showAlertState.buttons}
            />
        </>
    );

}
export default AddEditAlbums;
