import { useCallback, useContext, useRef, useState} from 'react';
// import { useHistory } from 'react-router';
import React from 'react';

import { IonCard, useIonToast, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToggle, IonToolbar, IonAlert, IonLoading} from '@ionic/react';
import { logOut, moon, person, document,folder} from 'ionicons/icons';
// import { logOut, moon, chatbubbles, person, helpCircle, document, cash, notifications, folder} from 'ionicons/icons';

import './Settings.css';
import { AppContext, logout, setColorMode } from '../Services/State';
import { appAuthDomain } from '../Services/utils';
// import { useHistory } from 'react-router';
// import urls from '../Services/urls';

interface AlertStateInterface {
    header: string;
    subHeader: string;
    message: string;
    buttons: any;
    showAlert: boolean;
}

const Settings: React.FC<any> = ({history, appMode, setAppMode})=>{
    // const { state, dispatch } = useContext(AppContext);
    const { dispatch } = useContext(AppContext);
    // const myHistory = useHistory();
    
    const refDarkMode = useRef<HTMLIonToggleElement>(null);
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});
    const [showAlertState, setShowAlertState] = useState<AlertStateInterface>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});
    const [present, dismiss] = useIonToast();
    // const myHistory = useHistory();
    const switchMode = (_checked: any)=>{
        localStorage.setItem("appMode", _checked);
        setAppMode(_checked)
        var chosenMode = _checked?"dark":"light";
        dispatch(setColorMode(chosenMode));
    }

    const checkToggle = useCallback((shouldCheck: boolean)=>{
        console.log('toggle', shouldCheck);
        if (refDarkMode) {
            if (refDarkMode.current) {
                refDarkMode.current.checked = shouldCheck;
                var chosenMode = shouldCheck?"light":"dark";
                dispatch(setColorMode(chosenMode));
                // console.log(chosenMode)
            }
        }
    }, [dispatch]);

    const doLogout = useCallback(()=>{
        var buttonActions = [
          {
              text: 'Not now',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                  // window.history.back();
              }
          },
          {
              text: 'Continue',
              handler: () => {
                present({
                  position: "top",
                  buttons: [{ text: 'hide', handler: () => dismiss() }],
                  message: 'Logged out ...',
                });
                dispatch(logout());
                // history.push("/login");
                setTimeout(() => {
                  localStorage.clear();
                  window.location.reload();
                }, 500);
              }
          }
        ];
        var alertStateVars = {
          header: "About to log out",
          subHeader: "", 
          message: "Are you sure?",
          buttons: buttonActions};
        setShowAlertState({...alertStateVars, showAlert: true});
    }, [dispatch, dismiss, present]);

    prefersDark.onchange = (e) => {
        checkToggle(prefersDark.matches);
    };

    const navigateToTerms = () => {
        window.open(appAuthDomain('/terms.html'), '_blank');
        // myHistory.push(appAuthDomain('/terms.html'));
    };

    return (
        <IonPage>
            <IonHeader mode='ios'>
                <IonToolbar>
                    <IonTitle>Settings</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense" mode='ios'>
                    <IonToolbar>
                        <IonTitle size="large">Settings</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div>
                    <br/>
                    <br/>
                    <IonList mode='ios' className="menuSections">
                        <IonItem mode='ios'>
                            <IonIcon slot="start" name={moon} className="component-icon component-icon-dark" mode='ios'/>
                            <IonLabel> Dark Mode</IonLabel>
                            <IonToggle checked={appMode} ref={refDarkMode} onIonChange={e => switchMode(e.detail.checked)} />
                        </IonItem>
                    </IonList>
                    <br/>
                    <IonCard mode='ios' className="menuSections">
                        <IonCardHeader mode='ios'>
                            <IonCardTitle mode='ios'>
                                Profile
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent className="cardContent" mode='ios'>
                            <IonList className="cardContentList">
                                <IonItem routerLink='profile' mode='ios'>
                                    <IonIcon icon={person} />
                                    <IonLabel> My Profile</IonLabel>
                                </IonItem>
                                <IonItem routerLink='dashboard' >
                                    <IonIcon icon={folder} />
                                    <IonLabel> Dashboard</IonLabel>
                                </IonItem>
                                {/* <IonItem>
                                    <IonIcon icon={notifications} />
                                    <IonLabel> Push Notifications</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonIcon icon={cash} />
                                    <IonLabel> Payments</IonLabel>
                                </IonItem> */}
                            </IonList>
                        </IonCardContent>
                    </IonCard>
                    <IonCard mode='ios' className="menuSections">
                        <IonCardHeader>
                            <IonCardTitle>
                                More
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent className="cardContent">
                            <IonList className="cardContentList">
                                {/* <IonItem>
                                    <IonIcon icon={chatbubbles} />
                                    <IonLabel> About us</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonIcon icon={helpCircle} />
                                    <IonLabel> Help</IonLabel>
                                </IonItem> */}
                                <IonItem onClick={navigateToTerms}>
                                    <IonIcon icon={document} />
                                    <IonLabel> Terms</IonLabel>
                                </IonItem>
                                {/* <IonItem className="dummyDivs">
                                </IonItem> */}
                            </IonList>
                        </IonCardContent>
                    </IonCard>
                    <IonCard mode='ios' className="menuSections">
                        <IonItem onClick={doLogout}>
                            <IonIcon slot="start" name={logOut} className="component-icon component-icon-dark" />
                            <IonLabel> Log out</IonLabel>
                        </IonItem>
                    </IonCard>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </IonContent>
            <IonLoading
                mode='ios'
                isOpen={showLoadingState.showLoading}
                onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
                message={showLoadingState.showLoadingMessage}
            />
            <IonAlert
                mode='ios'
                isOpen={showAlertState.showAlert}
                onDidDismiss={() => setShowAlertState({...showAlertState, showAlert: false})}
                header={showAlertState.header}
                subHeader={showAlertState.subHeader}
                message={showAlertState.message}
                buttons={showAlertState.buttons}
            />
        </IonPage>
      );
}

export default Settings;