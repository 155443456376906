import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonPage, IonSearchbar, IonSkeletonText, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import { add, chevronBack, cloudDownload, cloudDownloadOutline, heartCircle, heartCircleOutline, pauseCircle, play  } from 'ionicons/icons';

import { AppContext, getCurrentTrack, getHotTracks, getPlaying, isFavTrack, isOfflineTrack, openPlayer, playTrackList, setFavTrack, setTrackToPlay, togglePaymentPopup } from '../Services/State.jsx';

import { appAuthDomain, img } from '../Services/utils';

import './Tab1.css';
import axios from 'axios';

const Songs: React.FC<any> = ({actionBtnsClick, addPlaylist, setShowAlert, selectPlaylist}) => {
  const { state, dispatch } = useContext(AppContext);
  var localHotTracks = getHotTracks(state);
  // var localHotTracks:any = [];
  const songPlaying = getPlaying(state);
  const currentTrack = getCurrentTrack(state);
  const [hotTracks, setHotTracks] = useState(localHotTracks);
  const [songsFetched, setSongsFetched] = useState(false);
  const [searchedTrack, setSearchedTrack] = useState('');
  
  const history = useHistory();

  const doPlay = useCallback((track:any, listTracks: any) => {
    // console.log(history);
    if (state.auth.user) { 
      if (state.auth.user.isSubscribed) {
        var myPlayList:any = [];
        var tempTracks:any = [];
        listTracks.forEach((song:any)=>{
          myPlayList.push(song.id);
          tempTracks.push(song);
          // getCurrentTracks.push(song);
        });

        if (getCurrentTrack(state)) {
          if (track.id === (getCurrentTrack(state)).id) {
            dispatch(openPlayer());
            // history.push("/album?track="+track.id); //Current Track route so that tracks have shareable links
          } else {
            // dispatch(playTrack(track));
            // dispatch(playTrackList(newMusicList));
            dispatch(playTrackList(tempTracks, myPlayList, track));
            // history.push("/album?track="+track.id); //Current Track route so that tracks have shareable links
          }
        } else {
          // dispatch(playTrack(track));
          // dispatch(playTrackList(newMusicList));
          dispatch(playTrackList(tempTracks, myPlayList, track));
          // history.push("/album?track="+track.id); //Current Track route so that tracks have shareable links
        }
      } else {
        // history.push("/subscribe");
        dispatch(setTrackToPlay(track, listTracks, "track"));
        dispatch(togglePaymentPopup(true));

      }
    } else {
      history.push("/login");
    }
  }, [state, dispatch, history]);

  var hasSome = 0;
  if (hotTracks.length >0) {    
    hasSome = (hotTracks[(hotTracks.length - 1)]).id;
  }
  var loaderTracks = [
    {id: hasSome+1, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
    {id: hasSome+2, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
    {id: hasSome+3, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
  ];
  var appendedHotTracks = hotTracks;
  if (!songsFetched) {
    // appendedHotTracks.unshift(loaderTracks[0]);
    // appendedHotTracks.unshift(loaderTracks[1]);
    // appendedHotTracks.unshift(loaderTracks[2]);
    appendedHotTracks.push(loaderTracks[0]);
    appendedHotTracks.push(loaderTracks[1]);
    appendedHotTracks.push(loaderTracks[2]);
  };


  useEffect(()=>{
    (function cloudUserState(){
      if (!songsFetched) {
        var accessToken = "";
        if (state.auth.user) {
          if (state.auth.user.accessToken) {
            accessToken = state.auth.user.accessToken;
          }
        };
        var headers = {
          Authorization: "Bearer "+accessToken
        }
        // return axios.get(appAuthDomain("api/music?content=album&id="+albumId), {headers}).then((result:any)=>{
        // return axios.get("api/music?content=album&id="+albumId, {headers}).then((result:any)=>{
        axios.get(appAuthDomain("backend/includes/music/music.php?content=moreSongs"), {headers}).then((result:any)=>{
          // console.log(result);
          return result.data;
        }).then ((response)=>{
          setSongsFetched(true);
          // console.log(response);
          if (response.success) {
            setHotTracks(response.data);
          } else {
            setHotTracks([]);
          };
          // dispatch(setCurrentAlbum(album));
        }).catch((error:any)=>{
          console.log(error.message);
          setSongsFetched(true);
          // setHotTracks(loaderTracks);
          setHotTracks(appendedHotTracks);
          setTimeout(() => {
            var cleanLoaders:any = [];
            appendedHotTracks.forEach((item: any)=>{
              if (item.type !== "loader") {
                cleanLoaders.push(item)
              }
            });
            setHotTracks(cleanLoaders);
          }, 1000);
        });
      };
    })();
  }, [state.auth.user, dispatch, songsFetched, appendedHotTracks]);

  var accessToken = "";
  if (state.auth.user) {
    if (state.auth.user.accessToken) {
      accessToken = state.auth.user.accessToken;
    }
  };

  useEffect(()=>{
    (function doSearch(){
      if(searchedTrack !== ""){
        var config = {
          headers: {
            Authorization: "Bearer "+accessToken
          }
        };
        return axios.get(appAuthDomain("backend/includes/music/music.php?page=user&action=searchTracks&keyWords="+searchedTrack), config).then((result:any)=>{
          // console.log(result);
          return result.data;
        }).then ((response)=>{
          // console.log(response);
          if (response.success) {
            var respInfo = response.data;
            setHotTracks(respInfo);
            // dispatch(loggedIn(respInfo));
          } else {
            setHotTracks([]);
          };
        }).catch((error:any)=>{
          console.log(error.message);
        });
      }else{
        // setHotTracks(localHotTracks);
      }
    })();   

  },[searchedTrack, accessToken]);

  // const addFav = useCallback(track => {
  //   dispatch(setFavTrack(track));
  // }, [dispatch]);

  const addFav = (track: any) => {
    if (state.auth.user) {
      if (state.auth.user.isSubscribed) {
        dispatch(setFavTrack(track));
      }else {
        dispatch(togglePaymentPopup(true));
      }
      
    }else{
      history.push("/login");
    }
  };
  
  return (
    <IonPage>
      <IonHeader mode='ios'>
        <IonToolbar mode='ios'>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" text="Back" icon={chevronBack} />
          </IonButtons>
          <IonTitle>Tracks</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" mode='ios'>
          <IonToolbar mode='ios'>
            <IonTitle size="large">Tracks</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>
          <IonSearchbar mode='ios' className='mySearchBTN' placeholder='Search songs' value={searchedTrack} 
          onIonChange={e => setSearchedTrack(e.detail.value!)}></IonSearchbar>
        </div>
        <div className="topHome">
          <IonList mode='ios'>
            <IonListHeader mode='ios'>
              <IonLabel>All Songs</IonLabel>
            </IonListHeader>
            {
              (hotTracks.length > 0) ? (
                hotTracks.map((track: any , key:number) => {
                  var relevantIcon = play;
                  var playingClass = "";
                  if (currentTrack) {
                    relevantIcon = ((currentTrack.id === track.id)&&(!songPlaying.paused))?(pauseCircle): (play);
                    playingClass = (currentTrack.id === track.id)?("currentSongInList"): ("");
                  };
                  if (track.type !== "loader") {
                    return <IonItemSliding key={key} className="trackListed">
                      <IonItem key={track.title}  mode='ios'>
                      {/* <IonItem key={track.title}  mode='ios' button> */}
                        <IonThumbnail slot="start" className='thumbnail' onClick={() => doPlay(track, hotTracks)}>
                          <img alt={track.title} src={img(track.img)}/>
                        </IonThumbnail>
                        <IonIcon onClick={() => doPlay(track, hotTracks)} icon={relevantIcon} className={playingClass} slot="start"/>
                        <IonLabel>
                          <h2>{track.title}</h2>
                          <p>{track.artist}</p>
                        </IonLabel>
                        <IonIcon icon={(isFavTrack(state, track))?(heartCircle):(heartCircleOutline)} onClick={()=> addFav(track)} slot="end"/>
                        <IonIcon className="offline-indicator" onClick={(e)=>{actionBtnsClick(e, {action: 'save', song: track})}} icon={(isOfflineTrack(state, track))?(cloudDownload):(cloudDownloadOutline)} slot="end"/>
                      </IonItem>
                      <IonItemOptions key={track.id}>
                        <IonItemOption color="success" onClick={(e)=> selectPlaylist(track)}><IonIcon slot="start" icon={add}/>Playlist</IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>;
                  } else {
                    return <IonItem key={track.id} className="trackListed" button>
                      <IonThumbnail slot="start">
                        {/* <h2><IonSkeletonText animated style={{ width: '100%', float: 'left'  }} /></h2> */}
                      </IonThumbnail>
                      <IonLabel>
                        <h2><IonSkeletonText animated style={{ width: '100%', float: 'left'  }} /></h2>
                        <p><IonSkeletonText animated style={{ width: '80%', float: 'left'  }} /></p>
                      </IonLabel>
                      <IonIcon className="offline-indicator" icon={cloudDownloadOutline} style={{color: "gray"}} slot="end"/>
                    </IonItem>;
                  }
                })
              ) : (<p>No tracks available</p>)
            }
          </IonList>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </IonContent>
    </IonPage>
  );
};

export default Songs;