import axios from 'axios';
import React from 'react';

import { playAudio, appAuthDomain } from './utils';

/**
 * This is a simple redux-like state management pattern for React using hooks
 * that might be useful in your simpler Ionic React apps that don't
 * require something as complex as Redux.
 * 
 * See each page for an example of how to read from state and
 * dispatch actions.
 * 
 * Learn more:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 */

export const AppContext = React.createContext();

const reducer = (state, action) => {
  const playing = getPlaying(state);
  const ct = getCurrentTrack(state);
  const user = getUser(state);
  var playerRef;

  switch (action.type) {
    case 'SET_IS_SUBSCRIBED': {
      return {
        ...state,
        auth: {
          user: {
            ...state.auth.user,
            isSubscribed: action.isSubscribed
          },
        }
      }
    }
    case 'SET_TRACKS': {
      return {
        ...state,
        music: {
          ...state.music,
          tracks: action.music.tracks,
          hotTracks: action.music.hotTracks,
          hotAlbums: action.music.hotAlbums
        }
      }
    }
    case 'SET_TRACK_TO_PLAY': {
      return {
        ...state,
        trackToPlay: {
          track: action.track,
          listTracks: action.listTracks,
          whatType: action.whatType
        }
      }
    }
    case 'SET_ALBUM': {
      return {
        ...state,
        album: {
          ...action.album
          // img: action.album.img,
          // title: action.album.title,
          // artist: action.album.artist,
          // type: action.album.type,
          // tracks: action.album.tracks
        }
      }
    }
    // case 'SET_OFFLINE': {
    //   console.log(action)
    //   const isOff = isOfflineTrack(state, action.track);
    //   const newOfflines = getOfflineTracks(state).filter(t => t.id !== action.track.id);
    //   return downloadTrack(action.offline).then(res=>{
    //     if (res.success) {
    //       syncMyActions(state, "offline", action.track.id, isOff);
    //       return {
    //         ...state,
    //         user: {
    //           ...user,
    //           offlineTracks: !isOff ? [ct, ...newOfflines] : newOfflines
    //         }
    //       };
    //     } else {
    //       console.log(res);
    //       return {
    //         ...state,
    //         user: {
    //           ...user,
    //         }
    //       };
    //     }
    //   });
    // }
    case 'PLAY': {
      var audioSRC, audioBlob;
      if (action.track && action.track !== ct) {
        const newRecentTracks = getRecentTracks(state).filter(t => t.id !== action.track.id);
        const index = getTrackIndex(state, action.track.id);
        audioSRC = action.track.src; 
        audioBlob = action.track.songBlob;
        playerRef = playAudio(audioSRC, audioBlob);
        playerRef.ref.play();
        
        syncMyActions(state, "play", action.track.id, 1);
        return {
          ...state,
          ui: {
            ...state.ui,
            playerOpen: true
          },
          user: {
            ...user,
            recentTracks: [action.track, ...newRecentTracks]
          },
          playing: {
            ...playing,
            index,
            progress: 0,
            paused: false,
            counted: false,
            id: action.track.id,
            playerRef: playerRef,
          }
        }
      } else {
        audioSRC = action.track.src; 
        audioBlob = action.track.songBlob;

        if (playing) {
          if (playing.playerRef) {
            playerRef = playing.playerRef;
            // console.log(playerRef.src, action.track.src);
            if (playerRef.src === action.track.src) {
              // state.playing.playerRef.ref.play();
            } else {
              playerRef = playAudio(audioSRC, audioBlob);
            }
          } else {
            playerRef = playAudio(audioSRC, audioBlob);
          }
        } else {
          playerRef = playAudio(audioSRC, audioBlob);
        }
        playerRef.ref.play();
      }
      syncMyActions(state, "play", state.playing.id, 1);
      return {
        ...state,
        playing: {
          ...playing,
          paused: false
        }
      }
    }
    case 'PAUSE': {
      if (state.playing) {
        state.playing.playerRef.ref.pause();
        syncMyActions(state, "play", state.playing.id, 0);
      }
      // console.log(state.playing.playerRef.ref)
      return {
        ...state,
        playing: {
          ...playing,
          paused: true
        }
      }
    }
    case 'NEXT': {
      var nextIndex = (playing.index + 1) % getTempTracks(state).length;
      var nextTrackTo = state.music.tempTracks[nextIndex];
      // console.log('nextTrackTo: ', nextTrackTo);
      const newRecentTracks = getRecentTracks(state).filter(t => t.id !== nextTrackTo.id);
      if (nextTrackTo !== ct) {
        const index = nextIndex;

        audioSRC = nextTrackTo.src; 
        audioBlob = nextTrackTo.songBlob;

        if (state.playing) {
          if (state.playing.playerRef) {
            playerRef = state.playing.playerRef;
            playerRef.src = audioSRC;
            audioSRC = audioBlob ? URL.createObjectURL(audioBlob) : appAuthDomain(audioSRC);
            playerRef.ref.src = audioSRC; //Replaces the Old Audio src with the new (prev track)
          } else {
            playerRef = playAudio(audioSRC, audioBlob);
          }
        } else {
          playerRef = playAudio(audioSRC, audioBlob);
        };

        syncMyActions(state, "play", nextTrackTo.id, 1);
        return {
          ...state,
          user: {
            ...user,
            recentTracks: [nextTrackTo, ...newRecentTracks],
          },
          playing: {
            ...state.playing,
            index,
            progress: 0,
            paused: false,
            id: nextTrackTo.id,
            playerRef: playerRef,
          }
        };
      }
      // syncMyActions(state, "play", state.playing.id, 1);
      return {
        ...state,
        // playing: {
        //   ...playing,
        // }
      }
    }
    case 'PREV': {
      // // syncMyActions(state, "play", action.track.id, -1);
      // return {
      //   ...state,
      //   playing: {
      //     ...playing,
      //     index: Math.max(0, playing.index - 1),
      //     progress: 0,
      //   }
      // }
      var prevIndex = Math.max(0, playing.index - 1);
      var prevTrackTo = state.music.tempTracks[prevIndex];
      const newRecentTracks = getRecentTracks(state).filter(t => t.id !== prevTrackTo.id);
      if (prevTrackTo !== ct) {
        const index = prevIndex;

        audioSRC = prevTrackTo.src; 
        audioBlob = prevTrackTo.songBlob;

        if (state.playing) {
          if (state.playing.playerRef) {
            playerRef = state.playing.playerRef;
            playerRef.src = audioSRC;
            audioSRC = audioBlob ? URL.createObjectURL(audioBlob) : appAuthDomain(audioSRC);
            playerRef.ref.src = audioSRC; //Replaces the Old Audio src with the new (prev track)
          } else {
            playerRef = playAudio(audioSRC, audioBlob);
          }
        } else {
          playerRef = playAudio(audioSRC, audioBlob);
        };

        syncMyActions(state, "play", prevTrackTo.id, 1);
        return {
          ...state,
          user: {
            ...user,
            recentTracks: [prevTrackTo, ...newRecentTracks],
          },
          playing: {
            ...state.playing,
            index,
            progress: 0,
            paused: false,
            id: prevTrackTo.id,
            playerRef: playerRef,
          }
        };
      }
      // syncMyActions(state, "play", state.playing.id, 1);
      return {
        ...state,
        // playing: {
        //   ...playing,
        // }
      }
    }
    case 'STOP_PLAY': {
      // syncMyActions(state, "play", action.track.id, -2);
      return {
        ...state,
        playing: null,
        // playing: {
        //   ...playing,
        //   // stopped: true,
        // }
      }
    }
    case 'SEEK': {
      var seekTo = action.time <= ct.time ? Math.floor(action.time) : ct.time;
      seekTo = parseInt(seekTo);

      state.playing.playerRef.ref.currentTime = seekTo;
      return {
        ...state,
        playing: {
          ...playing,
          // progress: seekTo
        }
      }
    }
    case 'PLAY_TRACK_LIST': {
      if (action.tempTracks && action.start) {
        const firstTrackIndex = action.tempTracks.findIndex(t => t.id === action.start.id);
        var firtTrack = action.tempTracks[firstTrackIndex];
        const newRecentTracks = getRecentTracks(state).filter(t => t.id !== firtTrack.id);
        if (firtTrack !== ct) {
          // const index = getTrackIndex(state, firtTrack.id);
          const index = firstTrackIndex;
          audioSRC = firtTrack.src; 
          audioBlob = firtTrack.songBlob;
          
          if (state.playing) {
            if (state.playing.playerRef) {
              playerRef = state.playing.playerRef;
              playerRef.ref.src = audioSRC;
            } else {
              playerRef = playAudio(audioSRC, audioBlob);
            }
          } else {
            playerRef = playAudio(audioSRC, audioBlob);
          };
          
          syncMyActions(state, "play", firtTrack.id, 1);
          return {
            ...state,
            ui: {
              ...state.ui,
              playerOpen: true,
            },
            user: {
              ...user,
              recentTracks: [firtTrack, ...newRecentTracks],
            },
            music: {
              ...state.music,
              tempTracks: action.tempTracks, 
              playList: action.playList,
            },
            playerRef,
            playing: {
              ...state.playing,
              index,
              progress: 0,
              paused: false,
              counted: false,
              id: firtTrack.id,
              playerRef: playerRef,
              time: firtTrack.time
            }
          };
        }
        if (state.playing) {
          syncMyActions(state, "play", state.playing.id, 1);
          return {
            ...state,
            // playing: {
            //   ...playing,
            // }
          }
        };
        syncMyActions(state, "play", firtTrack.id, 1);
        return {
          ...state,
          // playing: {
          //   ...playing,
          //   paused: false
          // }
        }
      }
      return {
        ...state
      }
    }
    case 'SET_PLAY_STATE': {
      return {
        ...state,
        playing: {
          ...playing,
          index: action.index,
          progress: action.progress,
          paused: action.paused
        }
      }
    }
    case 'SET_PLAYER_OPEN': {
      return {
        ...state,
        ui: {
          ...state.ui,
          playerOpen: action.open
        }
      }
    }
    case 'PROGRESS_TRACK': {
      if (action.time && ct) {
        var progressTo = action.time <= ct.time ? Math.floor(action.time) : ct.time;
        progressTo = parseInt(progressTo);
        
        return {
          ...state,
          playing: {
            ...playing,
            counted: action.counted,
            progress: progressTo
          }
        }
      } else {
        return {
          ...state
        }
      }
    }
    case 'SET_OFFLINE': {
      const isOff = isOfflineTrack(state, action.track);
      // const newOfflines = getOfflineTracks(state).filter(t => t.song !== action.track.song);
      const newOfflines = getOfflineTracks(state).filter(t => t.id !== action.track.id);
      downloadTrack(action.track);
      syncMyActions(state, "offline", action.track.id, isOff);
      return {
        ...state,
        user: {
          ...user,
          // offlineTracks: !isOff ? [action.track] : state.user.offlineTracks
          offlineTracks: !isOff ? [action.track, ...newOfflines] : newOfflines
        }
      };
    }
    case 'FAV': {
      // console.log('track', action.track)
      const isFav = isFavTrack(state, action.track);
      const newFavs = getFavTracks(state).filter(t => { return (t.id !== action.track.id)});
      syncMyActions(state, "fav", action.track.id, !isFav);
      if (ct) {
        return {
          ...state,
          user: {
            ...user,
            favTracks: !isFav ? [ct, ...newFavs] : newFavs
          }
        }
      } else {
        return {
          ...state,
          user: {
            ...user,
            favTracks: !isFav ? [action.track, ...newFavs] : newFavs
          }
        }
      }
    }
    case 'MYFAV': {
      return {
        ...state,
        user: {
          ...user,
          favTracks: action.tracks
        }
      }
    }
    case 'TOGGLE_PAYMENT_POPUP': {
      return {
        ...state,
        user: {
          ...state.user,
          tryPay: action.value
        }
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        playing: null,
        auth: {
          ...state.auth,
          user: null
        } 
      }
    }
    case 'LOGGED_IN': {
      // console.log()
      return {
        ...state,
        auth: {
          ...state.auth,
          user: action.user
        }
      }
    }
    case 'UI': {
      return {
        ...state,
        ui: {
          ...state.ui,
          colorMode: action.colorMode
        }
      }
    }
    
    default: {
      return state;
    }

    // return state;
  }
};

// const logger = (reducer) => {
//   const reducerWithLogger = (state, action) => {
//     console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
//     console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
//     console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state,action));
//     return reducer(state,action);
//   };

//   return reducerWithLogger;
// }

// const loggerReducer = logger(reducer);

var isOnline = true;
if (navigator.onLine === false) {
  isOnline = false; 
}; 
var colorMode = "light";
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  colorMode = "dark";
};

// console.log(localStorage.appMode, colorMode)
if(localStorage.appMode !==undefined){
  if(localStorage.getItem("appMode")=== 'true'){
    colorMode = "dark";
  }else{
    colorMode = "light";
  }
}
// console.log(localStorage.appMode, colorMode)


const initialState = {
  auth: {
    user: null
  },
  user: {
    recentTracks: [],
    favTracks: [],
    offlineTracks: [],
    autoPlay: true,
    tryPay: false,
  },
  // playing: {
  //   index: 0,
  //   progress: 27000,
  //   paused: true
  // },
  playCounterTimer: 0.1,
  music: {
    tracks: [
    ],
    tempTracks: [
    ],
    hotTracks: [
    ],
    hotAlbums: [
    ],
    playList: [
    ]
  },
  album: {
    // tracks: [
    //   {id: 0, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
    //   {id: 1, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
    //   {id: 2, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
    // ],
  },
  ui: {
    playerOpen: false,
    colorMode: colorMode,
  },
  isOnline: isOnline
};

// self.addEventListener('online', e=>{
//   // isOnline = true;
// });
// self.addEventListener('offline', e=>{
//   // isOnline = false;
// });

export function AppContextProvider(props) {
  const fullInitialState = {
    ...initialState,
  }

  let [state, dispatch] = React.useReducer(reducer, fullInitialState);
  let value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  );
}

export const AppContextConsumer = AppContext.Consumer;

// Some state action creators
export const setIsSubscribed = (isSubscribed)=>({
  type: 'SET_IS_SUBSCRIBED',
  isSubscribed
})
export const setTrackToPlay = (track, listTracks, whatType) => ({
  type: 'SET_TRACK_TO_PLAY',
  track,
  listTracks,
  whatType,
});
export const setTracks = (music) => ({
  type: 'SET_TRACKS',
  music: {
    tracks: music.tracks,
    hotTracks: music.hotTracks,
    hotAlbums: music.hotAlbums,
  }
});
export const setCurrentAlbum = (album) => ({
  type: 'SET_ALBUM',
  album
})
export const setUserPlayState = (music) => ({
  type: 'SET_PLAY_STATE',
  music: {
    tracks: music.tracks,
    hotTracks: music.hotTracks,
    hotAlbums: music.hotTracks
  }
});

// export const setOffline = (track) => ({
//   type: 'SET_OFFLINE',
//   track
// });

export const setOffline = (track) => ({
  type: 'SET_OFFLINE',
  track
});

export const setFavTrack = (track) => ({
  type: 'FAV',
  track
});

export const setToFavs = (tracks) => ({
  type: 'MYFAV',
  tracks
});

export const openPlayer = () => ({
  type: 'SET_PLAYER_OPEN',
  open: true
})

export const closePlayer = () => ({
  type: 'SET_PLAYER_OPEN',
  open: false
})
export const togglePaymentPopup = (value) =>({
  type: 'TOGGLE_PAYMENT_POPUP',
  value
})

export const pauseTrack = () => ({
  type: 'PAUSE'
});

export const playTrack = (track) => ({
  type: 'PLAY',
  track
});

export const playTrackList = (tempTracks, playList, start) => ({
  type: 'PLAY_TRACK_LIST',
  tempTracks,
  playList,
  start
});

export const progressTrack = ({time, counted}) => ({
  type: 'PROGRESS_TRACK',
  time,
  counted,
});


export const seekTrack = (time) => ({
  type: 'SEEK',
  time
});

export const nextTrack = () => ({
  type: 'NEXT',
});

export const prevTrack = () => ({
  type: 'PREV',
});

export const stopPlay = () => ({
  type: 'STOP_PLAY',
});


export const logout = () => ({
  type: 'LOGOUT'
});

export const loggedIn = (user) => ({
  type: 'LOGGED_IN',
  user
});
export const setColorMode = (color) => ({
  type: 'UI',
  colorMode: color
});


/* Some state selectors */

export const isPlayerOpen = (state) => state.ui.playerOpen;

// Color 
export const getColorMode = (state) => state.ui.colorMode;
// Get all tracks in database
export const getTracks = (state) => state.music.tracks;
export const getTempTracks = (state) => state.music.tempTracks;
export const getHotTracks = (state) => state.music.tracks.filter(t => state.music.hotTracks.find(nt => ((nt === t.id)&&(!t.thisIsAlbum))));
export const getHotAlbums = (state) => state.music.tracks.filter(t => state.music.hotAlbums.find(nt => ((nt === t.id)&&(t.thisIsAlbum))));


export const getOfflineTracks = (state) => state.user.offlineTracks;
export const getFavTracks = (state) => state.user.favTracks;
export const getRecentTracks = (state) => state.user.recentTracks;
export const isOfflineTrack = (state, track) => {
  // console.log(track.id)
  if (track) {
    // console.log(track, 'lll')
    if (!('id' in track)) {
      return false; 
    }
    // return !!state.user.offlineTracks.find(t => t.song === track.id);
    if ('song' in track) {
      return !!state.user.offlineTracks.find(t => t.id === track.id);
    }else{
      return !!state.user.offlineTracks.find(t => t.song === track.id);
    }
  }
  return false;
}
export const isFavTrack = (state, track) => {
  // console.log('TrackId', track.id)
  // console.log("These", state.user.favTracks)
  if (track) {
    if (!('id' in track)) {
      return false; 
    }
    return !!state.user.favTracks.find(t => t.id === track.id);
  }
  return false;
}

export const getPlaying = (state) => state.playing;
export const getIsSubscribed = (state) => state.auth.user.isSubscribed;
export const getCurrentTrack = (state, index) => state.music.tempTracks[state.playing ? state.playing.index : -1];
export const getCurrentAlbum = (state) => state.album;

export const getTrack = (state, id) => state.music.tracks.find(t => t.id === id);
// export const getTrackIndex = (state, id) => state.music.tracks.findIndex(t => t.id === id);
export const getTrackIndex = (state, id) => state.music.tempTracks.findIndex(t => t.id === id);
// export const getTrackIndexOnList = (state, id) => state.music.tempTracks.findIndex(t => t.id === id);
export const getUser = (state) => state.user;


export const checkIsOnline = (state) => {
  return state.isOnline;
};

const downloadTrack = (track) => {
  let response = {success: false};

  return response;
}

export const syncMyActions = (state, action, track, actionVal) => {
  // console.log(actionVal)
  var accessToken = "";
  if (state.auth.user) {
    if (state.auth.user.accessToken) {
      accessToken = state.auth.user.accessToken;
    }
  };
  var headers = {
    Authorization: "Bearer "+accessToken
  }
  var url = "api/user?action="+action+"&actionVal="+actionVal+"&track="+track; 
  if(action === "fav" || action === "playCount"){
    url = "api/music?action="+action+"&actionVal="+actionVal+"&track="+track;
  }
  axios.get(appAuthDomain(url), {headers}).then(res=>{
    return res.data;
  }).catch(err=>{
    console.log(err.message);
  });
}

export const makeRequests = async (state, options) => {
  var accessToken = "";
  var url = "";
  var method = "get";
  var data;
  var customHeaders = {};
  if (state) {
    if (state.auth.user) {
      if (state.auth.user.accessToken) {
        accessToken = state.auth.user.accessToken;
      };
    };
  } else {
    
  };
  if (options) {
    method = options.method? options.method : "get";
    url = options.url? options.url : "";
    data = options.data? options.data : "";
    customHeaders = options.headers? options.headers : {};
  };
  var headers = {
      Authorization: "Bearer "+accessToken, ...customHeaders
  }
  const axiosOptions = {
      url,
      method,
      headers,
      data,
  };
  // console.log(axiosOptions)
  return axios(axiosOptions).then(res=>{
      return res.data;
  }).then(resp=>{
      return resp;
  }).catch(err=>{
      // console.log(err);
      console.log("At State.jsx; Request Error Catch (by Lethu): ", err.message);
      return {success: false, msg: "Request error", msg2: err.message, action: {retry: true}};
  });
}