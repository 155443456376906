// import { useEffect } from 'react';
import { IonBackButton, IonButton, IonButtons, IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import { chevronBack, mail } from 'ionicons/icons';



const forgotpass: React.FC<any> = ({routerFunction, onsubmit, formEntries})=> {
  
  return (
    <>
    <div className="topDiv">
      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>
      <IonButtons slot="start" className="myHead">
          <IonBackButton className="mtBackBtn" defaultHref="home" text="Back" icon={chevronBack} />
      </IonButtons>
      <div className='logContainer'>
        <div className='logo'><img alt='logo' src='assets/icon/logo.png'/></div>
        <h1>forgot password</h1>

        <form method="POST" onSubmit={onsubmit}>
          <input type="hidden" name="type" defaultValue="forgotPassword" />
          <IonItem className="ionMyItem">
            <IonLabel position="floating" className="theLabel" >Email / Phone</IonLabel>
            <IonIcon className="iconPart" icon={mail} />
            <IonInput name="username" value={(formEntries&&formEntries['username'])?(formEntries['username']):("")} type="text" className="theInput" required />
          </IonItem>
          <br/>
          <br/>
          <IonButton expand="block" type="submit" className="ion-activatable ripple-parent">Submit</IonButton>
        </form>
        <br/>
        <br/>
        <div className="myButtons">                                            
          <p className="myForgot" onClick={()=>routerFunction('register')}>Remember your password?  <span>Sign in</span></p>          
        </div>
      </div>
    </div>
  </>
  );
};

export default forgotpass;
