import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { IonBackButton, IonButtons, IonCard, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonPage, IonSkeletonText, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBack, cloudDownload, cloudDownloadOutline, pauseCircle, play, playBack, trash } from 'ionicons/icons';

import { AppContext, checkIsOnline, getCurrentAlbum, getCurrentTrack, getHotTracks, getPlaying, isOfflineTrack, openPlayer, playTrackList, setTrackToPlay, togglePaymentPopup } from '../Services/State.jsx';
import { getItemOnDB, img} from '../Services/utils';

import './Album.css';

const Playlist: React.FC<any> = ({ playlists,actionBtnsClick, removePlaylist}) => {
    const { state, dispatch } = useContext(AppContext);
    const { search } = useLocation();
    const history = useHistory();
    var localHotTracks = getHotTracks(state);
    const songPlaying = getPlaying(state);
    const [hotTracks, setHotTracks] = useState(localHotTracks);
    const currentTrack = getCurrentTrack(state);
  // console.log(currentTrack);

    const getSelectedPlaylist = useCallback((playlistName:any) => {
    return playlists[playlistName] || [];
    },[playlists]);

    const query = new URLSearchParams(search);
    var playlistName = query.get("name");
    var isOnline = checkIsOnline(state);
    var selectedPlaylist = getSelectedPlaylist(playlistName);

    useEffect(()=>{
        if(state.user.offlineTracks.length> 0){
          const newArray = selectedPlaylist.map((track: any, key: number) => {
            if (isOfflineTrack(state, track)) {
              var itemBy = { database: "songs", table: "offlineSongs", byCol: 'song', byColValue: track.id };
              return getItemOnDB(itemBy).then((res: any) => {
                if (res.success) {
                  res.data.imageBlob = URL.createObjectURL(res.data.imageBlob);
                  return res.data;
                }
              });
            } else {
              return track;
            }
          });

          Promise.all(newArray).then((results) => {
            let newResults = results;
            if(!isOnline){
              newResults = results.filter(item => 'song' in item);
            }
            setHotTracks(newResults);
          });
        }else{
          if(isOnline){
            setHotTracks(selectedPlaylist);
          }else{
            setHotTracks([]);
          }
        }
    },[selectedPlaylist, playlists, isOnline, playlistName, state, state.user.offlineTracks])
  
    const doPlay = useCallback((track:any, listTracks: any) => {
        // console.log(history);
        if (state.auth.user) { 
        if (state.auth.user.isSubscribed) {
            var myPlayList:any = [];
            var tempTracks:any = [];
            listTracks.forEach((song:any)=>{
            myPlayList.push(song.id);
            tempTracks.push(song);
            });
            if (getCurrentTrack(state)) {
            if (track.id === (getCurrentTrack(state)).id) {
                dispatch(openPlayer());
            } else {
                dispatch(playTrackList(tempTracks, myPlayList, track));
            }
            } else {
            dispatch(playTrackList(tempTracks, myPlayList, track));
            }
        } else {
            dispatch(setTrackToPlay(track, listTracks, "track"));
            dispatch(togglePaymentPopup(true));

        }
        } else {
        history.push("/login");
        }
    }, [state, dispatch, history]);

  

  var album = getCurrentAlbum(state);

  return (
    <IonPage>
      <IonHeader mode='ios'>
        <IonToolbar mode='ios'> 
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" text="Back" icon={chevronBack} />
          </IonButtons>
          <IonTitle>Playlist</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" mode='ios'>
          <IonToolbar mode='ios'>
            <IonTitle size="large">Playlist</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid className="album-info">
          <IonItem lines="none" className="album-cover" mode='ios'>
            <IonCard className="album-cover-card" mode='ios'>
              <img alt={""+playlistName} src={ (hotTracks.length>0)?(img(hotTracks[0].img)):"assets/icon/logo.png"} />
            </IonCard>
            <IonLabel mode='ios'>
              <h2>{playlistName}</h2>
              <p>{(album)?(album.artist):"loading Artist..."}</p>
            </IonLabel>
            {/* <IonIcon icon={playCircle} onClick={() => doPlay(hotTracks[0], hotTracks)} slot="start"/> */}
          </IonItem>
        </IonGrid>
        <IonList mode='ios'>
          <IonListHeader mode='ios'>
            <IonLabel>Songs</IonLabel>
          </IonListHeader>
          {
            (hotTracks.length > 0) ? (
                hotTracks.map((track: any, key:number) => {
                  // console.log("hotTracks :", hotTracks)
                  // console.log(track)
                if (track.type !== "loader") {
                  var relevantIcon = play;
                  var playingClass = "";
                  if (currentTrack) {
                    relevantIcon = ((currentTrack.id === track.id)&&(!songPlaying.paused))?(pauseCircle): (play);
                    playingClass = (currentTrack.id === track.id)?("currentSongInList"): ("");
                  };
                  return <IonItemSliding key={key} className="trackListed">
                      <IonItem key={track.title} mode='ios'>
                      {/* <IonItem key={track.title} mode='ios' button> */}
                        <IonThumbnail slot="start" className='thumbnail' onClick={() => doPlay(track, hotTracks)}>
                          {("imageBlob" in track)?(
                            <img alt={track.title} src={track.imageBlob}/>
                          ):(
                            <img alt={track.title} src={img(track.img)}/>
                          )}
                        </IonThumbnail>
                        <IonIcon onClick={() => doPlay(track, hotTracks)} icon={relevantIcon} className={playingClass} slot="start"/>
                        <IonLabel>
                          <h2>{track.title}</h2>
                          <p>{track.artist}</p>
                        </IonLabel>
                        {/* <IonIcon icon={heartCircleOutline} onClick={()=> addFav(track)} slot="end"/> */}
                        <IonIcon className="offline-indicator" onClick={(e)=>{actionBtnsClick(e, {action: 'save', song: track})}} icon={(isOfflineTrack(state, track))?(cloudDownload):(cloudDownloadOutline)} slot="end"/>
                      </IonItem>
                      <IonItemOptions key={parseInt(track.id)}>
                        <IonItemOption color="danger" onClick={(e)=> removePlaylist(playlistName, 'song', track)}><IonIcon slot="start" icon={trash}/>Remove</IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>;
                } else {
                  return <IonItem key={track.id} mode='ios' className="trackListed"  button>
                    <IonIcon style={{color: "silver"}} name={playBack}  slot="start"/>
                    <IonLabel>
                      <h2><IonSkeletonText animated style={{ width: '100%', float: 'left'  }} /></h2>
                      <p><IonSkeletonText animated style={{ width: '80%', float: 'left'  }} /></p>
                    </IonLabel>
                    <IonIcon slot="end"/>
                  </IonItem>;
                }
              })
            ) : (
              (!isOnline && selectedPlaylist.length>0)?(
              <IonItem mode='ios'>You need to be online to play songs on this playlist.</IonItem>
              ):(<IonItem mode='ios'>No tracks in this playlist.</IonItem>)
            )
          }
        </IonList>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </IonContent>
    </IonPage>
  );
};

export default Playlist;
