import React, { useCallback, useContext, useEffect } from 'react';

import {
  IonModal, IonHeader, IonToolbar, IonTitle, IonRange,
  IonButtons, IonButton, IonIcon, IonCard, useIonToast 
} from '@ionic/react';

import {
  arrowDown, heart, heartOutline, playSkipBack,
  play, pause, playSkipForward, cloudDownloadOutline, cloudDownload, shareOutline
} from 'ionicons/icons';

import {
  AppContext, isPlayerOpen, closePlayer, getPlaying, getCurrentTrack, isFavTrack, 
  setFavTrack, pauseTrack, playTrack, seekTrack, nextTrack, prevTrack, setOffline, isOfflineTrack, getColorMode 
} from '../Services/State';

import { appAuthDomain, appDatabase, genWebShare, img, msToTime } from '../Services/utils';

import './TrackPlayer.css';
import axios from 'axios';



interface TrackProgressProps {
  colorMode?: any;
  playing?: any;
  track?: any;
  onSeek?: any;
  onHandlePause?: any;
  onHandlePlay?: any;
}
const TrackProgress: React.FC<TrackProgressProps> = ({ colorMode, playing, track, onSeek, onHandlePause, onHandlePlay }) => {
  const progress = playing.progress;
  const left = track.time - progress;
  const percent = (progress / track.time) * 100;

  const handle = (action: string) => {
    if (action === "down") {
      onHandlePause();
      return;
    }
    onHandlePlay();
    return;
  }
  const s = (p: any) => {
    const newTime = (p / 100) * track.time;
    onSeek(newTime);
  }
  return (
  <div className={"track-progress track-progress-"+colorMode}>
    <IonRange
      pin
      value={percent}
      onPointerDown={ ()=>{handle("down")} }
      onPointerUp={ ()=>{handle("up")} }
      onIonChange={(e) => { 
        if (playing.paused) {
          s(e.detail.value);
        }
        }
      }
    />
    <div className={"track-progress-time track-progress-time-"+colorMode}>
      <div className={"track-progress-time-current track-progress-time-current-"+colorMode}>
        {msToTime(progress)}
      </div>
      <div className={"track-progress-time-left track-progress-time-left-"+colorMode}>
        -{msToTime(left)}
      </div>
    </div>
  </div>
  )
};


interface TrackControlsProps {
  colorMode?: any;
  track?: any;
  playing?: any;
  isFav?: any;
  isOffTrack?: any;
  onPause?: any;
  onPlay?: any;
  onPrev?: any;
  onNext?: any;
  onFav?: any;
  onDownload?: any;
}
const TrackControls: React.FC<TrackControlsProps> = ({colorMode, track, playing, isFav, isOffTrack, onPause, onPlay, onPrev, onNext, onFav, onDownload }) => {
  // colorMode
  // console.log(colorMode)
  const { state, dispatch } = useContext(AppContext);
  const currentTrack = getCurrentTrack(state);
  const [present, dismiss] = useIonToast();
  const shareBtnsClick = useCallback((event, options) => {
    var playingSong = getCurrentTrack(state);
    console.log(playingSong)
    var songLink = "https://rhythmmusic.co.za/search?songID="+playingSong.id;
    if(playingSong.song){
      songLink = "https://rhythmmusic.co.za/search?songID="+playingSong.song;
    }
    genWebShare(playingSong.artist, playingSong.title, songLink, null).then(shareRes=>{
      console.log(shareRes);
      if (shareRes) {
          window.history.back();
      } else {
        navigator.clipboard.writeText(songLink);
        present({
          position: "bottom",
          buttons: [{ text: 'hide', handler: () => dismiss() }],
          message: playingSong.title+" copied to clipboard",
          duration: 10000
        });
      };
    });
  },[state, present, dismiss]);

  const actionBtnsClick = useCallback((event, options) => {
    var song = options.song;
    // setShowLoading({showLoadingMessage: "Saving for offline, progress: "+progress*100+"%", showLoading: true});
    // const logProgress = (progressEvent: any)=>{
    //   var numberToShow:any = Math.floor((progressEvent.loaded/progressEvent.total)*100);
    //   console.log(numberToShow);
    //   setProgress(numberToShow/100);
    // }
    // if(isAuthorized){
      present({
        position: "bottom",
        buttons: [{ text: 'hide', handler: () => dismiss() }],
        message: "Saving for offline: "+song.title,
        duration: 5000
      });
      Promise.all([
        
        axios.get(appAuthDomain(song.img), {responseType: "blob"}).then((response: any)=>{
          return response.data;
        }),
        // axios.get(appAuthDomain(song.src), {responseType: "blob", onDownloadProgress: logProgress}).then((response: any)=>{
        axios.get(appAuthDomain(song.src), {responseType: "blob"}).then((response: any)=>{
          return response.data;
        })
      ]).then(([imageBlob, songBlob])=>{
        var songStoreData:any = {
          album:song.album, artist:song.artist,genre: song.genre, song: song.id, img: song.img, src: song.src, imageBlob,
          thisIsAlbum: song.thisIsAlbum, time: song.time,title: song.title, type: song.type, year: song.year, songBlob
        }; 
        return appDatabase("songs", 'offlineSongs').then((databaseObject:any)=>{
          return databaseObject.appDbMethod('readwrite').then((vidStore: any)=>{
              return vidStore.put(songStoreData).then((mySong:any)=>{
                songStoreData['id'] = mySong;
                songStoreData['imageBlob'] = URL.createObjectURL(songStoreData.imageBlob);
              });
          }).then((res: any)=>{
              databaseObject.db.close();
              event.target.disabled = false;
              // hapticsImpactLight();
              // setShowLoading({showLoadingMessage: " ", showLoading: false});
              // setProgress(0);
              dispatch(setOffline(songStoreData));
              present({
                  position: "bottom",
                  buttons: [{ text: 'hide', handler: () => dismiss() }],
                  message: "Saved offline: "+song.title,
                  duration: 5000
              });
              // handleActionsClose();
              return res;
          });
        });
      })
    // }else{
    //   window.location.href = '/login';
    // }
  },[dismiss, dispatch, present])

  
  return (
  <> 
    <div className={"track-controls track-controls-"+colorMode}>
      <IonIcon onClick={onFav} icon={isFav ? heart : heartOutline} />
      <IonIcon onClick={onPrev} icon={playSkipBack} />
      {playing.paused ? (
        <IonIcon onClick={onPlay} className="play-pause" icon={play} />
      ): (
        <IonIcon onClick={onPause} className="play-pause" icon={pause} />
      )}
      <IonIcon onClick={onNext} icon={playSkipForward} />
      {/* <IonIcon icon={addCircleOutline} /> */}
      <IonIcon icon={isOffTrack ? cloudDownload : cloudDownloadOutline} onClick={(e)=>{actionBtnsClick(e, {action: 'save', song: currentTrack})}}  />
      
    </div>
    {/* <br/> */}
    <br/>
    <div className={"extra-controls track-controls-"+colorMode} onClick={(e)=>{shareBtnsClick(e, {action: 'share'})}}><IonIcon icon={shareOutline} /></div>
  </>
  );
}


interface ContainerProps {
  firstModalRef?: any;
  track?: any;
  closed?: any;
}
const TrackPlayer: React.FC<ContainerProps> = ({ firstModalRef, track, closed }) => {
  const { state, dispatch } = useContext(AppContext);

  const playing = getPlaying(state);

  const colorMode = getColorMode(state);

  const handleClose = useCallback(() => {
    dispatch(closePlayer());
  }, [dispatch]);

  
  useEffect(()=>{
    if (playing) {
      if (!playing.paused) {
        // console.log(playing.progress, playing.time);
        if (playing.progress === playing.time) {
          dispatch(nextTrack());
        }else{
          playing.playerRef.ref.play();
        }
      } else {
        if (false) {
          // nextTrack();
        }
      }
    }
  }, [playing, dispatch]);

  if (!playing) {
    return null;
  }

  const open = isPlayerOpen(state);
  const currentTrack = getCurrentTrack(state);
  // track = getTrack(state, currentTrack.id); // get track
  const isFav = isFavTrack(state, currentTrack);
  const isOffTrack = isOfflineTrack(state, currentTrack);
  // const isDownloading = isOfflineTrack(state, currentTrack);

  // // console.log(currentTrack, 'currentTrack');
  // console.log("playing: ", playing)
  return (
    <IonModal
      mode='ios'
      isOpen={open}
      onDidDismiss={handleClose}
      swipeToClose={true}
      cssClass="track-player"
      presentingElement={firstModalRef}
      >
      <IonHeader>
        <IonToolbar mode='ios'>
          <IonButtons slot="start">
            <IonButton fill="clear" onClick={() => dispatch(closePlayer())}>
              <IonIcon icon={arrowDown} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            {currentTrack.title}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className="track-content">
        <IonCard className="coverCard" mode='ios'>
          {('imageBlob' in currentTrack)?(
          <img className="coverArt" alt={currentTrack.title} src={currentTrack.imageBlob} />
          ):(
            <img className="coverArt" alt={currentTrack.title} src={img(currentTrack.img)} />
          )}
        </IonCard>
        <h2 style={{textAlign: "center", color: "gainsboro"}}>{currentTrack.title}</h2>
        <h4 style={{textAlign: "center"}}>{currentTrack.artist}</h4>
        <TrackProgress
          colorMode={colorMode}
          playing={playing}
          track={currentTrack}
          onHandlePause={() => dispatch(pauseTrack())} 
          onHandlePlay={() => dispatch(playTrack(currentTrack))} 
          onSeek={(n: any) => dispatch(seekTrack(n))} 
          />
        <TrackControls
          colorMode={colorMode}
          playing={playing}
          track={currentTrack.song}
          isFav={isFav}
          isOffTrack={isOffTrack}
          onPause={() => dispatch(pauseTrack())}
          onPlay={() => dispatch(playTrack(currentTrack))}
          onPrev={() => dispatch(prevTrack())}
          onNext={() => dispatch(nextTrack())}
          onFav={() => dispatch(setFavTrack(currentTrack))}
          onDownload={() => dispatch(setOffline(currentTrack))}
          />
      </div>
      <div></div>
    </IonModal>
  )
};

export default TrackPlayer;