import {useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import {IonAvatar, IonButton, IonButtons, IonCard, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonMenuToggle, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import {albums, disc, musicalNotes, musicalNote, analytics, person, chevronBack, menu, people} from 'ionicons/icons';

// import { AppContext } from '../Services/State';
// import { img } from '../../Services/utils';

import './Dashboard.css';
import AddEditTracks from './dashboard/AddEditTracks'; 
import AddEditAlbums from './dashboard/AddEditAlbums';
import AdminStats from './dashboard/AdminStats';
import GetUsers from './dashboard/GetUsers';

const Dashboard: React.FC<any> = () => {
  // const { state } = useContext(AppContext);
//   const history = useHistory();
    const history = useHistory();
    const firstModalRef = useRef();
    const [showModal2, setShowModal2] = useState(false);
    const [userRole, setUSerRole] = useState('user');
    const [userName, setUSerNsme] = useState('User name');
    // const [segment, setSegment] = useState("manager");
    const [whichModal, setWhichModal] = useState(0);

    const showMyModal = (whichModal: number)=>{
        setShowModal2(true);
        setWhichModal(whichModal)
    }
    const onSegment = (seg: any)=>{
        // setSegment(seg);
    }
    // if(localStorage.getItem("roleName") === "Super admin"){
    //   if(userRole !== "Super admin"){
    //       setUSerRole("Super admin");
    //   }
    // }  

    useIonViewWillEnter(() => {
        const bottomNav = document.getElementById("bottomNav");
        if (bottomNav) {
            bottomNav.style.display = "none";
        }; 
        // console.log(userRole)
        showMyModal(5);
    });
    useEffect(()=>{
      if(localStorage.getItem("roleName") === "Super admin"){
        if(userRole !== "Super admin"){
            setUSerRole("Super admin");
        }
      } 
      if(localStorage.getItem("firstname")){
          setUSerNsme(localStorage.getItem("firstname")+"")
      }
    },[userRole])
  
  
  return (
    <IonPage>
      <IonHeader mode='ios'>
        <IonToolbar mode='ios'>
        <IonButtons slot="start">
          <IonButton routerLink="/home">
            <IonIcon icon={chevronBack} />
              <IonLabel>Home</IonLabel>
            </IonButton>
          </IonButtons>
          <IonTitle>Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen id="main">
        <IonHeader collapse="condense" mode='ios'>
          <IonToolbar>
            <IonTitle size="large">Dashboard</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="topHome">
            <IonCard className='fistCard' mode='ios'>
                <IonAvatar className='myLogoDash' onClick={()=>history.push("/profile")}><IonIcon className="PersonIcon" icon={person} mode='ios'/></IonAvatar>
                <IonLabel mode='ios' className='userNames'>
                    <h2>{userName}</h2>
                </IonLabel>
                <IonCard className='btnTab' mode='ios' onClick={()=>(showMyModal(5))}>
                  <IonContent className='inContent'>
                    <IonIcon className="dashboardTabICons" icon={analytics} mode='ios'/>
                    <IonLabel mode='ios' className='lblName'>
                        <h2>Insights</h2>
                    </IonLabel>
                  </IonContent>
                </IonCard>
                {
                  (userRole === "Super admin")?(
                    <IonCard className='btnTab' mode='ios' onClick={()=>(showMyModal(6))}>
                      <IonContent className='inContent'>
                        <IonIcon className="dashboardTabICons" icon={people} mode='ios'/>
                        <IonLabel mode='ios' className='lblName'>
                            <h2>Users</h2>
                        </IonLabel>
                      </IonContent>
                    </IonCard>
                  ):("")
                }
                
                <IonCard className='btnTab' mode='ios' onClick={()=>(showMyModal(2))}>
                  <IonContent className='inContent'>
                    <IonIcon className="dashboardTabICons" icon={musicalNote} mode='ios'/>
                    <IonLabel mode='ios' className='lblName'>
                        <h2>Add music</h2>
                    </IonLabel>
                  </IonContent>
                </IonCard>
                <IonCard className='btnTab' mode='ios' onClick={()=>(showMyModal(3))}>
                  <IonContent className='inContent'>
                    <IonIcon className="dashboardTabICons" icon={musicalNotes} mode='ios'/>
                    <IonLabel mode='ios' className='lblName'>
                        <h2>View music</h2>
                    </IonLabel>
                  </IonContent>
                </IonCard>
                <IonCard className='btnTab' mode='ios' onClick={()=>(showMyModal(1))}>
                  <IonContent className='inContent'>
                    <IonIcon className="dashboardTabICons" icon={disc} mode='ios'/>
                    <IonLabel mode='ios' className='lblName'>
                        <h2>Add album</h2>
                    </IonLabel>
                  </IonContent>
                </IonCard>
                <IonCard className='btnTab' mode='ios' onClick={()=>(showMyModal(4))}>
                  <IonContent className='inContent'>
                    <IonIcon className="dashboardTabICons" icon={albums} mode='ios'/>
                    <IonLabel mode='ios' className='lblName'>
                        <h2>View albums</h2>
                    </IonLabel>
                  </IonContent>
                </IonCard>
                <IonMenuButton auto-hide="false" className='btnTab2'>
                <IonIcon className="dashboardTabICons2" icon={menu} mode='ios'/>
                {/* <IonLabel mode='ios' className='lblName2'>
                    <h2>Menu</h2>
                </IonLabel> */}
                </IonMenuButton>
            </IonCard>

            <IonCard className='secondCard'>

              {(whichModal === 5 || whichModal === 6)?(
                (whichModal === 5)?(
                  <>
                  <h3 style={{textAlign:"center"}}>Insight</h3>
                  <AdminStats
                    whichModal={whichModal} showModal={showModal2} userRole={userRole} setShowModal={setShowModal2} 
                    presentingElement={firstModalRef.current} onDismiss={onSegment}
                  />
                  </>
                ):(
                  <>
                  <h3 style={{textAlign:"center"}}>User Signups</h3>
                  <GetUsers
                    whichModal={whichModal} showModal={showModal2} userRole={userRole} setShowModal={setShowModal2} 
                    presentingElement={firstModalRef.current} onDismiss={onSegment}
                  />
                  </>
                )
                
              ):( 
                (whichModal === 3|| whichModal === 2)?(
                  <AddEditTracks
                    whichModal={whichModal} showModal={showModal2} userRole={userRole} setShowModal={setShowModal2} 
                    presentingElement={firstModalRef.current} onDismiss={onSegment}
                  />
                ):(
                  (whichModal === 1|| whichModal === 4)?(<AddEditAlbums
                      whichModal={whichModal} showModal={showModal2} userRole={userRole} setShowModal={setShowModal2} 
                      presentingElement={firstModalRef.current} onDismiss={onSegment}
                  />):("")
                )
              )}
            </IonCard>
        </div>
      </IonContent>
      <IonMenu side="start" contentId="main">
        <IonContent>
          <IonList>
            <br></br>
            <br></br>
            <IonAvatar className='myLogoDash' style={{marginLeft:"10%"}} onClick={()=>history.push("/profile")}><IonIcon className="PersonIcon" icon={person} mode='ios'/></IonAvatar>
            <br></br>
            <IonMenuToggle>
              <IonItem onClick={()=>(showMyModal(5))}>
                <IonIcon className="dashboardTabICons" icon={analytics} mode='ios'/>
                <IonLabel mode='ios' className='lblName'>
                    <h2>Insights</h2>
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
            {
              (userRole === "Super admin")?(
                <IonMenuToggle>
                  <IonItem onClick={()=>(showMyModal(6))}>
                    <IonIcon className="dashboardTabICons" icon={people} mode='ios'/>
                    <IonLabel mode='ios' className='lblName'>
                        <h2>Users</h2>
                    </IonLabel>
                  </IonItem>
                </IonMenuToggle>
              ):("")
            }
            <IonMenuToggle>
              <IonItem onClick={()=>(showMyModal(2))}>
                  <IonIcon className="dashboardTabICons" icon={musicalNote} mode='ios'/>
                  <IonLabel mode='ios' className='lblName'>
                      <h2>Add music</h2>
                  </IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle>
              <IonItem onClick={()=>(showMyModal(3))}>
                <IonIcon className="dashboardTabICons" icon={musicalNotes} mode='ios'/>
                <IonLabel mode='ios' className='lblName'>
                    <h2>View music</h2>
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle>
              <IonItem onClick={()=>(showMyModal(1))}>
                <IonIcon className="dashboardTabICons" icon={disc} mode='ios'/>
                <IonLabel mode='ios' className='lblName'>
                    <h2>Add album</h2>
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle>
              <IonItem onClick={()=>(showMyModal(4))}>
                <IonIcon className="dashboardTabICons" icon={albums} mode='ios'/>
                <IonLabel mode='ios' className='lblName'>
                    <h2>View albums</h2>
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        </IonContent>
      </IonMenu>
    </IonPage>
  );
};

export default Dashboard;
