import { useState, ReactChild, ReactFragment, ReactPortal, useEffect, useContext } from 'react';
import {IonAlert, IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonSearchbar, IonSelect, IonSelectOption, IonThumbnail} from '@ionic/react';
import { add, chevronDown} from 'ionicons/icons';
import axios from 'axios';

import { AppContext} from '../../Services/State';
import { appAuthDomain, img } from '../../Services/utils';


const AddEditTracks: React.FC<any> = ({whichModal, showModal, userRole, setShowModal, presentingElement, onDismiss}) =>{
    // const { state, dispatch } = useContext(AppContext);
    const { state } = useContext(AppContext);
    interface AlertTypeInterface {
        header: string;
        subHeader: string;
        message: string;
        buttons: any;
        showAlert: boolean;
    }
    var respTracks:any | null  = [];
    let defaultSelectedTrack: {myTrackID?: string; trackName?: string; trackBy?: string; trackYear?:string; trackAlbum?: any; trackCategory?: any; trackImg?: any} = {};

    const [tracksInfo, setTracks] = useState(respTracks);
    const [albumInfo, setMyAlbums] = useState([]);
    const [genreInfo, setGenre] = useState([]);
    const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});
    const [showAlertState, setShowAlert] = useState<AlertTypeInterface>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});
    const [searchText, setSearchText] = useState('');
    const [album, setAlbum] = useState('');
    const [genre, setMyGenre] = useState('');
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [selectedTrackInfo, setSelectedTrackInfo] = useState(defaultSelectedTrack);


    let showModal2 = false;
    let showTracks = false;
    let setShowModal2:Function;
    let setShowTracks:Function;
    if (whichModal === 2) {
        showModal2 = showModal;
        setShowModal2 = setShowModal;
    } else if (whichModal ===3) {
        showTracks = showModal;
        setShowTracks = setShowModal;
    };

    var submitFunction = (e: any)=>{
        e.preventDefault();
        if((album === "") || (genre==="")){
            var myText = "ALBUM.";
            if((album === "")&&(genre==="")){myText = "ALBUM and GENRE."}else if(genre===""){myText = "GENRE."}
            var buttonActions = [
                {
                    text: 'Okay',
                    handler: () => {
                    }
                }
            ];
            var alertStateVars = {
                header: "Please select "+myText,
                subHeader: "", 
                message: "",
                buttons: buttonActions
            };
            setShowAlert({...alertStateVars, showAlert: true});
        }else{
            var url = "backend/includes/admin/music.php?page=admin&action=editTracks";
            const config = {
                headers: {
                    Authorization: "Bearer "+state.auth.user.accessToken,
                    "Content-Type": "multipart/form-data"
                }
            };
            var orderForm = new FormData(e.target);
            orderForm.append("appType", "store");
            if (e.target.action.includes("addTracks")) {
                url = "backend/includes/admin/music.php?page=admin&action=addTracks";            
                var trackDuration:any = document.getElementById("in_AudioShow")
                if (trackDuration) {
                    if ('duration' in trackDuration) {
                        var formatedDur:any = Math.round(trackDuration.duration)*1000;
                        // console.log(formatedDur);
                        orderForm.append("duration", formatedDur);
                    }
                }
            }
            setShowLoading({showLoadingMessage: "Submitting track...", showLoading: true});
            axios.post(appAuthDomain(url), orderForm, config).then(res=>{
                // console.log(res)
                return res.data;
            }).then(response=>{
                // console.log(response);
                setTimeout(() => {
                    setShowLoading({...showLoadingState, showLoading: false});
                }, 900);
                var buttonActions:any = [];
                var alertStateVars:any = {};
                setAlbum('');
                setMyGenre("");
                if (response.success) {
                    buttonActions = [
                        {
                            text: 'Done',
                            handler: () => {
                                setShowModal2(false);
                            }
                        }
                    ];
                    alertStateVars = {
                        header: response.msg,
                        subHeader: "", 
                        message: response.msg2,
                        buttons: buttonActions
                    };
                    setShowAlert({...alertStateVars, showAlert: true});
                    // onDismiss('manager');
                    // setShowModal2(false);
                } else {
                    buttonActions = [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                setShowModal2(false);
                            }
                        },
                        {
                            text: 'Retry',
                            handler: () => {
                                setTimeout(() => {
                                    submitFunction(e);
                                }, 500);
                            }
                        }
                    ];
                    alertStateVars = {
                        header: response.msg,
                        subHeader: "", 
                        message: response.msg2,
                        buttons: buttonActions
                    };
                    setShowAlert({...alertStateVars, showAlert: true});
                };
            }).catch(err=>{
                console.log(err.message);
                setTimeout(() => {
                    setShowLoading({...showLoadingState, showLoading: false});
                }, 900);
                var buttonActions = [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            console.log('Confirm Cancel');
                        }
                    },
                    {
                        text: 'Retry',
                        handler: () => {
                            submitFunction(e);
                        }
                    }
                ];
                var alertStateVars = {header: "Error encountered", subHeader: "", message: err.message, buttons: buttonActions};
                setTimeout(() => {
                    setShowAlert({...alertStateVars, showAlert: true});
                }, 1001);
            })
        }
    }

    const myEditFunction = (elem: any)=>{
        var trackID = elem.id;
        var released = elem.released;
        var listed = elem.listed;
        var isReleased = "Release";
        var isListed = "Unlist";
        if(userRole === "Super admin"){
            if(parseInt(elem.listed) ===1){isListed = "List";}
            if(parseInt(elem.released) ===1){isReleased = "Unrelease";}
        }else{
            if(parseInt(elem.released) ===1){isReleased = "Unrelease";}
        }
        

        setSelectedTrackInfo({
            myTrackID: elem.id, trackName: elem.title, trackBy: elem.artist, trackYear: elem.year, trackImg: elem.img, trackAlbum: elem.album,
            trackCategory: elem.genre
        })
        var buttonActions: any = [];
        // console.log(state.auth.user.id)
        if(userRole === "Super admin"){
            buttonActions = [
                (parseInt(elem.addedBy) === parseInt(state.auth.user.id))?({
                    text: isReleased,
                    cssClass: 'secondary',
                    handler: () => {
                        doTheRequest(trackID, released, "release");
                    }
                }):({}),
                {
                    text: isListed,
                    cssClass: 'secondary',
                    handler: () => {
                        doTheRequest(trackID, listed, "List");
                    }
                },(parseInt(elem.addedBy) === parseInt(state.auth.user.id))?({
                    text: "Edit",
                    cssClass: 'secondary',
                    handler: () => {
                        setShowTracks(false)
                        setShowModalEdit(true);
                    }
                }):({}),
                {   
                    text: "Cancel",
                    role: 'cancel',
                    handler: () => {
                    }
                }
            ];
        }else{
            buttonActions = [
                {
                    text: isReleased,
                    // role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                      doTheRequest(trackID, released, "release");
                    }
                },
                {   
                    text: "Edit",
                    handler: () => {
                        if(userRole !== "Super admin"){
                            setShowTracks(false)
                            setShowModalEdit(true);
                        }
                    }
                }, 
                {   
                    text: "Cancel",
                    handler: () => {}
                }
            ];
        }
        var alertStateVars = {header: "Choose action", subHeader: "", message: "", buttons: buttonActions};
        setTimeout(() => {
            setShowAlert({...alertStateVars, showAlert: true});
        }, 1001);

        const doTheRequest = (id: any, released: any, action:any)=>{
            var url = appAuthDomain("backend/includes/admin/music.php?page=admin&action=release&type=song&id="+id+"&state="+released);
            if(action === "List"){
                url = appAuthDomain("backend/includes/admin/music.php?page=admin&action=unlist&type=song&id="+id+"&state="+released);
            }
            const config = {
                headers: {
                    Authorization: "Bearer "+state.auth.user.accessToken,
                    "Content-Type": "multipart/form-data"
                }
            };
            setShowLoading({showLoadingMessage: "Submitting...", showLoading: true});
            axios.post(url,{}, config).then(res=>{
                return res.data;
            }).then(response=>{
                // console.log(response);
                setTimeout(() => {
                    setShowLoading({...showLoadingState, showLoading: false});
                }, 900);
                var buttonActions:any = [];
                var alertStateVars:any = {};
                if (response.success) {
                    buttonActions = [
                        {
                            text: 'Done',
                            handler: () => {
                            }
                        }
                    ];
                    alertStateVars = {
                        header: response.msg,
                        subHeader: "", 
                        message: response.msg2,
                        buttons: buttonActions
                    };
                    setShowAlert({...alertStateVars, showAlert: true});
                } else {
                    buttonActions = [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                            }
                        },
                        {
                            text: 'Retry',
                            handler: () => {
                                setTimeout(() => {
                                    doTheRequest(id, released, action);
                                }, 500);
                            }
                        }
                    ];
                    alertStateVars = {
                        header: response.msg,
                        subHeader: "", 
                        message: response.msg2,
                        buttons: buttonActions
                    };
                    setShowAlert({...alertStateVars, showAlert: true});
                };
            }).catch(err=>{
                console.log(err.message);
                setTimeout(() => {
                    setShowLoading({...showLoadingState, showLoading: false});
                }, 900);
                var buttonActions = [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                        }
                    },
                    {
                        text: 'Retry',
                        handler: () => {
                            doTheRequest(id, released, action);
                        }
                    }
                ];
                var alertStateVars = {header: "Error encountered", subHeader: "", message: err.message, buttons: buttonActions};
                setTimeout(() => {
                    setShowAlert({...alertStateVars, showAlert: true});
                }, 1001);
            })
        }

    };

    const getAttachFile = (event: any, elemID:string)=>{
        var file = event.target.files[0];
        if ((file !== null)||(file !== undefined)) {
            var reader  = new FileReader(); 
            reader.onload = function(evt){
                var evtTarget = evt.target;
                if (evtTarget) {
                    if( evtTarget.readyState === FileReader.DONE) {
                        var revokeTimeDelay = 20000;

                        var theElem:any = document.getElementById(elemID);
                        var fileObject = URL.createObjectURL(file);
                        if (theElem) {
                            theElem.style.display = "block"; theElem.style.width = "100%";
                            // if ('src' in theElem) {
                                theElem.src = fileObject;
                                setTimeout(function() {
                                    URL.revokeObjectURL(fileObject);
                                }, revokeTimeDelay);
                            // }
                        }
                    };
                }
            }; 
            reader.readAsDataURL(file);
        } else {
            if ((elemID!==null)&&(elemID!==undefined)&&(elemID!=="")&&(elemID!==" ")) {
                var theElem = document.getElementById(elemID);
                if (theElem) {
                    theElem.style.display = "none";
                    theElem.style.width = "100%";
                }
            };
            event.target.value = "";
        };
    }

    useEffect(()=>{
        const getMyAlbums = ()=>{
            var config = {
                headers: {
                    Authorization: "Bearer "+state.auth.user.accessToken
                }
            };
            return axios.get(appAuthDomain("backend/includes/admin/music.php?page=admin&action=getAlbums"), config).then((result:any)=>{
            //   console.log(result);
              return result.data;
            }).then ((response)=>{
            //   console.log(response);
              if (response.success) {
                var respInfo = response.data;
                setMyAlbums(respInfo);
                // dispatch(loggedIn(respInfo));
              } else {
                setMyAlbums([]);
              };
            }).catch((error:any)=>{
              console.log(error.message);
              setMyAlbums([]);
              // dispatch(loggedIn(null));
            });
        }

        const getMyGenres = ()=>{
            var config = {
                headers: {
                    Authorization: "Bearer "+state.auth.user.accessToken
                }
            };
            return axios.get(appAuthDomain("backend/includes/admin/music.php?page=admin&action=getGenre"), config).then((result:any)=>{
            //   console.log(result);
              return result.data;
            }).then ((response)=>{
            //   console.log(response);
              if (response.success) {
                var respInfo = response.data;
                setGenre(respInfo);
                // dispatch(loggedIn(respInfo));
              } else {
                setGenre([]);
              };
            }).catch((error:any)=>{
              console.log(error.message);
              setGenre([]);
              // dispatch(loggedIn(null));
            });
        }
        if(showModalEdit || showModal2){
            getMyAlbums();
            getMyGenres();
        }
    },[showModalEdit, showModal2, state.auth.user.accessToken]);

    useEffect(()=>{
        if(searchText !== ""){
        
            (function doSearch(){
                // return axios.get(appAuthDomain(""), {headers}).then((result:any)=>{
                var config = {
                    headers: {
                        Authorization: "Bearer "+state.auth.user.accessToken
                    }
                };
                return axios.get(appAuthDomain("backend/includes/admin/music.php?page=admin&action=searchTracks&keyWords="+searchText), config).then((result:any)=>{
                //   console.log(result);
                return result.data;
                }).then ((response)=>{
                //   console.log(response);
                if (response.success) {
                    var respInfo = response.data;
                    setTracks(respInfo);
                    // dispatch(loggedIn(respInfo));
                } else {
                    setTracks([]);
                };
                }).catch((error:any)=>{
                console.log(error.message);
                console.log(searchText)
                // dispatch(loggedIn(null));
                });
            })();
        }

    },[searchText, state]);

    useEffect(()=>{
        const myFunction = ()=>{
            var config = {
                headers: {
                    Authorization: "Bearer "+state.auth.user.accessToken
                }
            };
            var url = "backend/includes/admin/music.php?page=admin&action=getTracks";
            if(userRole === "Super admin"){
                url = "backend/includes/admin/music.php?page=admin&action=getTracks&myRole=super";
            }
            return axios.get(appAuthDomain(url), config).then((result:any)=>{
                return result.data;
            }).then ((response)=>{
                // console.log(response, 'here');
                if (response.success) {
                    setTracks(response.data);
                    // dispatch(loggedIn(respInfo));
                } else {
                    setTracks([]);
                    // dispatch(loggedIn(null));
                };
            }).catch((error:any)=>{
                console.log(error.message);
                setTracks([
                    {
                        id: '1',
                        title: 'Hey Jude',
                        artist: 'The Beatles',
                        img: 'music/hey-jude.jpg',
                        year: 2021,
                        type: "Album",
                        released: "1",
                        album: "single",
                        genre: 1,
                    },
                    {
                        id: '2',
                        title: 'Hey Jude',
                        artist: 'The Beatles',
                        img: 'music/hey-jude.jpg',
                        year: 2020,
                        type: "Album",
                        released: 0,
                        album: 1,
                        genre: 1,
                    }
                ]);
                // dispatch(loggedIn(null));
            });
    
        }
        if (showTracks) {
            myFunction();
        }
    }, [showTracks, userRole, state.auth.user.accessToken]);
    
    return (
        <>
        { (showModal2)?( 
            <IonContent> 
                <h3 style={{textAlign:"center"}}>Add Music</h3> 
                <form action="backend/includes/admin/music.php?page=admin&action=addTracks" onSubmit={submitFunction}>
                    <>
                    <input type={"hidden"} name="duration" />
                    <IonItem className="myFormInputs">
                        <IonLabel position="floating" >Track album</IonLabel>
                        <IonSelect name='album' onIonChange={(ev) => setAlbum(ev.detail.value)}>
                            <IonSelectOption value="single"  style={{color: "red"}}><span style={{color: "red"}}>SINGLE</span></IonSelectOption>
                        {
                            (albumInfo.length > 0) ? (
                                albumInfo.map((album:any)=>{
                                    return <IonSelectOption key={album.id} value={album.id} >{album.title}</IonSelectOption>
                                })
                            ) : (
                                <IonSelectOption value="">No albums available</IonSelectOption>
                            )
                        }
                        </IonSelect>
                    </IonItem>
                    <IonItem className="myFormInputs">
                        <IonLabel position="floating" >Track genre</IonLabel>
                        <IonSelect name="genre" onIonChange={(ev) => setMyGenre(ev.detail.value)}>
                        {
                            (genreInfo.length > 0) ? (
                                genreInfo.map((genre:any)=>{
                                    return <IonSelectOption key={genre.id} value={genre.id} >{genre.title}</IonSelectOption>
                                })
                            ) : (
                                <IonSelectOption value="">No genre available</IonSelectOption>
                            )
                        }
                        </IonSelect>
                    </IonItem>
                    <IonItem className="myFormInputs">
                        <IonLabel position="floating" >Track name</IonLabel>
                        <IonInput name="name" placeholder="Product name" className="theIput" required />
                    </IonItem>

                    <IonItem className="myFormInputs">
                        <IonLabel position="floating" >Artist</IonLabel>
                        <IonInput name="artist" placeholder="Artist Name" className="theIput" required />
                    </IonItem>
                    <IonItem className="myFormInputs">
                        <IonLabel position="floating" >Track year</IonLabel>
                        <IonInput name="year" placeholder="Year Published" className="theIput" required />
                    </IonItem>
                    <IonItem className="myFormInputs">
                        <IonLabel position="stacked">Track cover</IonLabel>
                        <input type="file" accept="image/*" name="cover" placeholder="Product image" className="theIput" required />
                    </IonItem>
                    <IonItem className="myFormInputs">
                        <IonLabel position="stacked">Track audio</IonLabel>
                        <input type="file" name="audio" placeholder="Product image" className="theIput" onChange={(event)=>getAttachFile(event,'in_AudioShow')} required />
                        <audio id="in_AudioShow" style={{display: "none"}} controls />
                    </IonItem>
                    </>
                    <br/>
                    <br/>
                    <IonButton expand="block" fill="outline" shape="round" className="btnSubmit" type='submit'><IonIcon icon={add}/>Add track</IonButton>
                    <br/>
                    <br/>
                    <br/>

                    <br/>

                </form>
            </IonContent>
            ):(
                (showModal)?(
                    <IonContent>
                        <h3 style={{textAlign:"center"}}>Music</h3> 
                        <IonSearchbar className='dashSearch' value={searchText} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
                        <>
                        {
                            (tracksInfo.length > 0) ? tracksInfo.map((track: { id: number | null | undefined; streams: number| undefined; img: any; title: string | undefined; artist: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }) => {
                                return <IonItem key={track.id} className="trackListed" onClick={() => myEditFunction(track)} button>
                                    <IonThumbnail slot="start">
                                    <img alt={track.img} src={img(track.img)}/>
                                    </IonThumbnail>
                                    <IonLabel>
                                    <h2>{track.title}</h2>
                                    <p>{track.artist} <button style={{color: 'red', background: "transparent"}} >{track.streams} Streams</button></p>
                                    </IonLabel>
                                </IonItem>
                            }) : (<p style={{textAlign: "center"}}>No tracks available</p>)
                            
                        }
                        </>
                        <br/>
                        <br/>
                        <IonButton expand="full" fill="clear" className="btnSubmit" type='submit'><IonIcon icon={chevronDown}/>more</IonButton>
                    </IonContent>
                ):(
                    (showModalEdit)?(
                    <IonContent>
                        <h3 style={{textAlign:"center"}}>Edit {selectedTrackInfo.trackName} by {selectedTrackInfo.trackBy} </h3> 
                        <form action="backend/includes/admin/music.php?page=admin&action=editTracks" onSubmit={submitFunction}>
                            <input type="hidden" name="id" defaultValue={selectedTrackInfo.myTrackID}/>
                            <>
                            <IonItem className="myFormInputs">
                                <IonLabel position="floating" >Track album</IonLabel>
                                <IonSelect name='album'>
                                {
                                    (albumInfo.length > 0) ? (
                                        albumInfo.map((album:any)=>{
                                            return <IonSelectOption key={album.id} value={album.id} >{album.title}</IonSelectOption>
                                        })
                                    ) : (
                                        <IonSelectOption value="">No albums available</IonSelectOption>
                                    )
                                }
                                </IonSelect>
                            </IonItem>
                            <IonItem className="myFormInputs">
                                <IonLabel position="floating" >Track genre</IonLabel>
                                <IonSelect name="genre" >
                                {
                                    (genreInfo.length > 0) ? (
                                        genreInfo.map((genre:any)=>{
                                            return <IonSelectOption key={genre.id} value={genre.id} >{genre.title}</IonSelectOption>
                                        })
                                    ) : (
                                        <IonSelectOption value="">No genre available</IonSelectOption>
                                    )
                                }
                                </IonSelect>
                            </IonItem>
                            
                            <IonItem className="myFormInputs">
                                <IonLabel position="floating" >Track name</IonLabel>
                                <IonInput name="name" value={selectedTrackInfo.trackName} placeholder="Product name" className="theIput" required />
                            </IonItem>

                            <IonItem className="myFormInputs">
                                <IonLabel position="floating" >Artist</IonLabel>
                                <IonInput name="artist" value={selectedTrackInfo.trackBy} placeholder="Product description" className="theIput" required />
                            </IonItem>
                            <IonItem className="myFormInputs">
                                <IonLabel position="floating" >Track year</IonLabel>
                                <IonInput name="year" value={selectedTrackInfo.trackYear} placeholder="Product price" className="theIput" required />
                            </IonItem>

                            {/* <input ref={fileInput} hidden type="file" accept="image/*" onClick={() => {console.log('onClick');}}
                                // onChange={onSelectFile}
                            />
                            <IonButton expand="full" fill="clear" className='myFilesBtn'
                                onClick={() => {
                                // @ts-ignore 
                                fileInput?.current?.click();
                                // setBackgroundOption(BackgroundOptionType.Gradient);
                                }}>Track cover
                            </IonButton> */}

                            <IonItem className="myFormInputs">
                                <IonLabel position="stacked">Track cover</IonLabel>
                                <input type="file" accept="image/*" name="cover" placeholder="Product image" className="theIput" required />
                            </IonItem>
                            <IonItem className="myFormInputs">
                                <IonLabel position="stacked">Track audio</IonLabel>
                                <input type="file" name="audio" placeholder="Product image" className="theIput" required />
                            </IonItem>

                            {/* <IonItem className="myFormInputs">
                                <IonLabel position="floating" >Proctuct image 2</IonLabel>
                                <input type="file" accept="image/*" name="in_Pic2" placeholder="Store image" className="theIput" />
                            </IonItem> */}
                            </>
                            <br/>
                            <br/>
                            <IonButton expand="block" fill="outline" shape="round"  className="btnSubmit" type='submit'><IonIcon icon={add}/>Edit track</IonButton>
                        </form>
                    </IonContent>
                    ):("")
                )
            )
        }
            <IonLoading
                mode='ios'
                isOpen={showLoadingState.showLoading}
                onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
                message={showLoadingState.showLoadingMessage}
            />
            <IonAlert
                mode='ios'
                isOpen={showAlertState.showAlert}
                onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
                header={showAlertState.header}
                subHeader={showAlertState.subHeader}
                message={showAlertState.message}
                buttons={showAlertState.buttons}
            />
        </>
    );

}
export default AddEditTracks;
