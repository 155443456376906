import { Preferences } from '@capacitor/preferences';
import { openDB} from 'idb';
export const sleep = (n) => new Promise(r => setTimeout(r, n));


// const SOURCE_HOST = `https://localhost:80/Rhythm`;
const SOURCE_HOST = `https://rhythmmusic.co.za`;
const MONTYWOOD_HOST = `https://rhythmmusic.co.za`;

// export const img = (path) => `${path}`;
export const img = (path) => `${SOURCE_HOST}/${path}`;
// export const img = (path) => `https://ionic-react-demos.s3.amazonaws.com/${path}`;

// export const appAuthDomain = (path) => `https://ionic-react-demos.s3.amazonaws.com/${path}`;
export const appAuthDomain = (path) => `${SOURCE_HOST}/${path}`;
export const montyDomain = (path) => `${MONTYWOOD_HOST}/${path}`;


export const msToTime = (d) => {
    var seconds = Math.floor((d / 1000) % 60), minutes = Math.floor((d / (1000 * 60)) % 60);

    return minutes + ":" + (seconds < 10 ? `0${seconds}` : seconds);
}

export const playAudio = (link, blob) => {
    var audioSRC = blob ? URL.createObjectURL(blob) : appAuthDomain("api/stream?src="+encodeURIComponent(link));
    const vendor = navigator.vendor;
    if(vendor === "Apple Computer, Inc."){
        audioSRC = blob ? URL.createObjectURL(blob) : appAuthDomain(link);
    }
    var myAudioReference = new Audio(audioSRC);
    return {ref: myAudioReference, src: link};
}

export const promisedCreateObjectURL = (blob) => {
    return new Promise((resolve, reject) => {
        try {
            const url = URL.createObjectURL(blob);
            resolve(url);
        } catch (error) {
            reject(error);
        }
    });
}

export const onDeviceStorage = async (action, keyValuePair) => {
    if (action === 'set') {
        const key= Object.keys(keyValuePair)[0];
        const value = keyValuePair[key];
        await Preferences.set({key: key, value: value});
    } else if (action === 'get') {
        var { value } = await Preferences.get({ key: keyValuePair });
        return value;
    } else if (action === 'remove') {
        await Preferences.remove({ key: keyValuePair });
    } else if (action === 'clear') {
        await Preferences.clear();
    } else if (action === 'keys') {
        const { keys } = await Preferences.keys();
        return keys;
    };
}

export const appDatabase = async (theDB, dbTable, version= 1, options={}) => {
    var databaseObject = {
        db: null,
        theDB: theDB,
        dbTable: dbTable,

        init: ()=>{
            if (databaseObject.db) { 
                return Promise.resolve(databaseObject.db); 
            }
            return openDB(databaseObject.theDB, version, {
                upgrade(upgradeDb){
                    var myObjectStore;
                    if (!upgradeDb.objectStoreNames.contains(databaseObject,dbTable)) {
                        myObjectStore = upgradeDb.createObjectStore(databaseObject.dbTable, { autoIncrement : true, keyPath: 'id' });
                        // console.log(options);
                        if (options.indices) {
                            var theIndices = options.indices;
                            if (typeof theIndices === 'object') {
                                for (var key in theIndices) {
                                    //{indexName: keyPath}
                                    myObjectStore.createIndex(key, theIndices[key][0], theIndices[key][1]);
                                };
                            };
                        }
                    } else {
                        myObjectStore = upgradeDb._db;
                    };
                }
            }).then((db)=>{
                return databaseObject.db = db;
            });
        },

        appDbMethod: (mode)=>{				
            return databaseObject.init().then((db)=>{				
                return db.transaction(databaseObject.dbTable, mode).objectStore(databaseObject.dbTable);
            })
        }
    };
    return databaseObject;
};

export const getAllOnDB = async (theDatabase, theTable)=>{
    var response = {success: false, mgs: "", msg2: ""};
    try {
        return appDatabase(theDatabase, theTable).then((databaseObject)=>{
            return databaseObject.appDbMethod('readonly').then((tableObject)=>{
                return tableObject.getAll();
            }).then((res)=>{
                databaseObject.db.close();
                response.success = true;
                response.data = res;
                return response;
            }).catch((error)=>{
                response.msg = "IDB error";
                response.msg2 = error.message;
                return response;
            });
        });
    } catch (error) {
        response.msg = "Query error";
        response.msg2 = error.message;
    };
    return response;
}

export const getItemOnDB = async (itemBy) => {
    var response = {success: false, mgs: "", msg2: ""};
    
    try {
        if (itemBy.database && itemBy.table) {
            if (itemBy.byKey) {
                return appDatabase(itemBy.database, itemBy.table).then((databaseObject)=>{
                    return databaseObject.appDbMethod('readonly').then((tableObject)=>{
                        return tableObject.get(itemBy.byKey);
                    }).then((res)=>{
                        databaseObject.db.close();
                        response.success = true;
                        response.data = res;
                        return response;
                    }).catch((error)=>{
                        response.msg = "IDB error";
                        response.msg2 = error.message;
                        return response;
                    });;
                });
            } else if (itemBy.byCol) {
                var theCol = itemBy.byCol;
                var colVal = itemBy.byColValue;
                return appDatabase(itemBy.database, itemBy.table).then((databaseObject)=>{
                    return databaseObject.appDbMethod('readonly').then((tableObject)=>{
                        return tableObject.openCursor(null, 'next');
                    }).then(function myData(cursor) {
                        if ((cursor!==undefined)&&(cursor!==null)) { 
                            var dataPiece = cursor.value;
                            if (dataPiece[theCol] === colVal) {
                                return dataPiece;
                            } else {
                                return cursor.continue().then(myData); 
                            };
                        } else {
                            return;
                        };
                    }).then((res)=>{
                        databaseObject.db.close();
                        if (res) {
                            response.success = true;
                            response.data = res;
                            return response;
                        } else {
                            response.msg = "No result";
                            response.msg2 = "No value ("+colVal+") in the "+theCol+" column.";
                            return response;
                        };
                    }).catch((error)=>{
                        response.msg = "IDB error.";
                        response.msg2 = error.message;
                        return response;
                    });
                });
            } else {
                response.msg = "Unsepecified type";
                response.msg2 = "Unsepecified fetch type";
            }
        } else {
            response.msg = "Query error";
            response.msg2 = "Missing database or table.";
        };
    } catch (error) {
        response.msg = "Query error";
        response.msg2 = error.message;
    };
    return response;
}

export const genWebShare = async (shTitle, shText, shUrl, filesArray) => { 
    console.log(navigator)
    if (!("share" in navigator)) {
        console.log('Web Share API not supported.'); 
        // await  navigator.clipboard.writeText('Shared content text');
        return Promise.resolve(null);
    } else {
        var doFiles = false;
        if (filesArray) {
            if (navigator.canShare && navigator.canShare({files: filesArray})) { 
                doFiles = true;
                if ((filesArray[0] instanceof File)||(filesArray[0] instanceof Blob)) {
                    doFiles = true;
                };
            };
        };
        if (doFiles) {
            try {
                const result = await navigator.share({ title: shTitle, text: shText, url: shUrl, files: filesArray });
                return result;
            } catch (error) {
                console.log('Error sharing: ', error);
                return null;
            }
        } else {
            try {
                const result_1 = await navigator.share({ title: shTitle, text: shText, url: shUrl });
                return result_1;
            } catch (error_1) {
                console.log('Error sharing: ', error_1);
                return null;
            }
        }
    };
}