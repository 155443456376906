import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { IonBackButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRow, IonSearchbar, IonSkeletonText, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBack, pauseCircle, play } from 'ionicons/icons';

import axios from 'axios';

import { AppContext, getCurrentAlbum, getHotAlbums, setCurrentAlbum } from '../Services/State.jsx';
import { appAuthDomain, img, onDeviceStorage } from '../Services/utils';

import './Albums.css';

const Albums: React.FC<any> = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  var localHotAlbum = getHotAlbums(state);
  // var localHotAlbum:any = [];

  const currentAlbum = getCurrentAlbum(state);
  const [newAlbums, setNewTracks] = useState(localHotAlbum);
  const [albumsFetched, setAlbumsFetched] = useState(false);
  const [searchedAlbum, setSearchedAlbum] = useState('');


  var hasSome = 0;
  if (newAlbums.length >0) {    
    hasSome = (newAlbums[(newAlbums.length - 1)]).id;
  }
  var loaderAlbums = [
    {id: hasSome+1, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
    {id: hasSome+2, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
    {id: hasSome+3, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
  ];
  var appendedNewAlbums = newAlbums;
  if (!albumsFetched) {
    // appendedNewAlbums.unshift(loaderAlbums[0]);
    // appendedNewAlbums.unshift(loaderAlbums[1]);
    // appendedNewAlbums.unshift(loaderAlbums[2]);
    appendedNewAlbums.push(loaderAlbums[0]);
    appendedNewAlbums.push(loaderAlbums[1]);
    appendedNewAlbums.push(loaderAlbums[2]);
  };

  const gotoAlbum = useCallback((album: any, play: string) => {
    var playing = (play==="yes")?true:false;
    var prelimAlbumInfo = {
      id: album.id,
      img: album.img,
      title: album.title,
      artist: album.artist,
      type: album.type,
      noOfSongs: album.noOfSongs,
      tracks: [
        {id: 1, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
        {id: 2, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
        {id: 3, type: "loader", title: 'Hey Jude', artist: 'The Beatles', img: 'music/hey-jude.jpg', time: 359000,},
      ],
      playing: playing,
    };
    // console.log(prelimAlbumInfo)
    dispatch(setCurrentAlbum(prelimAlbumInfo));
    onDeviceStorage('set', {currentAlbum: JSON.stringify(prelimAlbumInfo)});
    history.push("/album?id="+album.id+"&play="+play);
  }, [history, dispatch]);

  useEffect(()=>{
    (function cloudUserState(){
      if (!albumsFetched) {
        var accessToken = "";
        if (state.auth.user) {
          if (state.auth.user.accessToken) {
            accessToken = state.auth.user.accessToken;
          }
        };
        var headers = {
          Authorization: "Bearer "+accessToken
        }
        // return axios.get(appAuthDomain("api/music?content=album&id="+albumId), {headers}).then((result:any)=>{
        // return axios.get("api/music?content=album&id="+albumId, {headers}).then((result:any)=>{
          return axios.get(appAuthDomain("backend/includes/music/music.php?content=albums"), {headers}).then((result:any)=>{
          // console.log(result);
          return result.data;
        }).then ((response)=>{
          setAlbumsFetched(true);
          // console.log(response);
          if (response.success) {
            setNewTracks(response.data);
          } else {
            setNewTracks([]);
          };
          // dispatch(setCurrentAlbum(album));
        }).catch((error:any)=>{
          console.log(error.message);
          setAlbumsFetched(true);
          setNewTracks(appendedNewAlbums);
          setTimeout(() => {
            var cleanLoaders:any = [];
            appendedNewAlbums.forEach((item: any)=>{
              if (item.type !== "loader") {
                cleanLoaders.push(item)
              }
            });
            setNewTracks(cleanLoaders);
          }, 1000);
        });
      }
    })();
  }, [state.auth.user, dispatch, albumsFetched, appendedNewAlbums]);
  
  var accessToken = "";
  if (state.auth.user) {
    if (state.auth.user.accessToken) {
      accessToken = state.auth.user.accessToken;
    }
  };

  useEffect(()=>{
    (function doSearch(){
      if(searchedAlbum !== ""){

        var config = {
          headers: {
            Authorization: "Bearer "+accessToken
          }
        };
        return axios.get(appAuthDomain("backend/includes/music/music.php?page=user&action=searchAlbums&keyWords="+searchedAlbum), config).then((result:any)=>{
          // console.log(result);
          return result.data;
        }).then ((response)=>{
          // console.log(response);
          if (response.success) {
            var respInfo = response.data;
            setNewTracks(respInfo);
            // dispatch(loggedIn(respInfo));
          } else {
            setNewTracks([]);
          };
        }).catch((error:any)=>{
          console.log(error.message);
        });
      }else{
        // setNewTracks(localHotAlbum);
      }
    })();   

  },[searchedAlbum, accessToken]);

  return (
    <IonPage>
      <IonHeader mode='ios'>
        <IonToolbar mode='ios'>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" text="Back" icon={chevronBack} />
          </IonButtons>
          <IonTitle>Albums</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" mode='ios'>
          <IonToolbar mode='ios'>
            <IonTitle size="large">Albums</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>
          <IonSearchbar mode='ios' className='mySearchBTN' placeholder='search albums' value={searchedAlbum} 
          onIonChange={e => setSearchedAlbum(e.detail.value!)}></IonSearchbar>
        </div>
        <div className="topHome">
          <IonList mode='ios'>
            {/* <IonListHeader>
              <IonLabel>All Albums</IonLabel>
            </IonListHeader> */}
            <IonGrid>
              <IonRow>
                {
                  (newAlbums.length>0) ? (
                    newAlbums.map((album: any) => {
                      if (album.type !== "loader") {
                        // var relevantIcon = play;
                        var playingClass:string = "";
                        if (currentAlbum) {
                          // relevantIcon = ((currentAlbum.id === album.id)&&(currentAlbum.playing))?(pauseCircle): (play)
                          // relevantIcon = ((currentTrack.id === track.id)&&(!songPlaying.paused))?(pauseCircle): (play);
                          playingClass = (currentAlbum.id === album.id)?("playAlbumBtn currentAlbumInList"): ("playAlbumBtn");
                        };
                        return <IonCol
                          size="6" size-md="3"
                          className="new-track"
                          key={album.id}
                          >
                          <img alt={album.title} style={{backgroundImage:"url("+img(album.img)+")" }}  className="albumCover albumContainer" src={img(album.img)} onClick={() => gotoAlbum(album, "no")} />
                          <IonItem lines="none" className='albumContainer theDesription'>
                            <IonLabel onClick={() => gotoAlbum(album, "no")}>
                              <h3>{album.title} ({album.noOfSongs} tracks)</h3>
                              <p>{album.artist}</p>
                            </IonLabel>
                            <IonIcon className={playingClass+ " albumIconPlay"} icon={((currentAlbum.id === album.id)&&(currentAlbum.playing))?(pauseCircle): (play)} onClick={() => gotoAlbum(album, "yes")} slot="start"/>
                          </IonItem>
                        </IonCol>
                      } else {
                        return <IonCol
                          size={"6"}
                          className="new-track"
                          key={album.id}
                          >
                          <img alt={album.title} src={img(album.img)} />
                          <IonItem lines="none">
                            <IonLabel>
                              <h2><IonSkeletonText animated style={{ width: '100%', float: 'left'  }} /></h2>
                              <p><IonSkeletonText animated style={{ width: '80%', float: 'left'  }} /></p>
                            </IonLabel>
                            <IonIcon className='playAlbumBtn' icon={play} slot="start"/>
                          </IonItem>
                        </IonCol>
                      }
                    })                    
                  ) : (<p>No albums available</p>)
                }
              </IonRow>
            </IonGrid>
          </IonList>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </IonContent>
    </IonPage>
  );
};

export default Albums;