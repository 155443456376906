import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonLoading, IonPage, IonSearchbar, IonSkeletonText, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router';
import { add, chevronBack, cloudDownload, cloudDownloadOutline, heartCircle, heartCircleOutline, play } from 'ionicons/icons';
import { useCallback, useContext, useEffect, useState } from 'react';
import { isOfflineTrack, AppContext, getCurrentTrack, openPlayer, playTrackList, togglePaymentPopup, makeRequests, setFavTrack, isFavTrack } from '../Services/State';
import { appAuthDomain, img } from '../Services/utils';
import './Tab1.css';
import './Tab2.css';

const Tab2: React.FC<any> = ({actionBtnsClick, addPlaylist, setShowAlert, selectPlaylist}) => {

  const { state, dispatch} = useContext(AppContext);
  const [searchedTracks, setSearchedTracks] = useState('');
  const [gotGenre, setGotGenre] = useState(false);
  const { search } = useLocation();
  const [genre, setGenre] = useState([]);
  const [mySearched, setMySearched] = useState([]);
  const [myShared, setMyShared] = useState([]);
  const [hadSongs, setHadSongs] = useState(false);
  const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});
  // const [searchedGenre, setSearchedGenre] = useState([]);

  const history = useHistory();

  const query = new URLSearchParams(search);
  var theGenre = query.get("genre");
  var genName = query.get("genName");
  var songID = query.get("songID");
  var searcFor = 'Search tracks';
  if(genName){searcFor = 'Search '+genName }
  var backRef = 'home';
  if(theGenre){
    backRef = 'search';
  }else{
    if(searchedTracks === "" && mySearched.length > 0){setMySearched([])}
  }

  var accessToken = "";
  if (state.auth.user) {
    if (state.auth.user.accessToken) {
      accessToken = state.auth.user.accessToken;
    }
  };

  // const getRandomColor = () => {
  //   const randomIndex = Math.floor(Math.random() * colors.length);
  //   return colors[randomIndex];
  // };

  // const getRandomPic = () => {
  //   const randomIndex = Math.floor(Math.random() * pics.length);
  //   return pics[randomIndex];
  // };

  const doPlay = useCallback((track:any, listTracks: any) => {
    // console.log(history);
    if (state.auth.user) { 
      if (state.auth.user.isSubscribed) {
        var myPlayList:any = [];
        var tempTracks:any = [];
        listTracks.forEach((song:any)=>{
          if(song.from === 'albums'){

          }else{
            myPlayList.push(song.id);
            tempTracks.push(song);
          }
          // getCurrentTracks.push(song);
        });

        if (getCurrentTrack(state)) {
          if (track.id === (getCurrentTrack(state)).id) {
            dispatch(openPlayer());
            // history.push("/album?track="+track.id); //Current Track route so that tracks have shareable links
          } else {
            // dispatch(playTrack(track));
            // dispatch(playTrackList(newMusicList));
            dispatch(playTrackList(tempTracks, myPlayList, track));
            // history.push("/album?track="+track.id); //Current Track route so that tracks have shareable links
          }
        } else {
          // dispatch(playTrack(track));
          // dispatch(playTrackList(newMusicList));
          dispatch(playTrackList(tempTracks, myPlayList, track));
          // history.push("/album?track="+track.id); //Current Track route so that tracks have shareable links
        }
      }else{
        dispatch(togglePaymentPopup(true));

      }
    } else {
      history.push("/login");
    }
  }, [state, dispatch, history]);

  useEffect(()=>{
    (function doSearch(){
      if(searchedTracks !== ""){
        var config = {
          headers: {
            Authorization: "Bearer "+accessToken
          }
        };
        var url = "api/music?page=user&action=searchTracks&keyWords="+searchedTracks;
        if(theGenre !== null){
          url = "api/music?action=getGenreMusic&keyWords="+searchedTracks+"&genreID="+theGenre;
        }
        return axios.get(appAuthDomain(url), config).then((result:any)=>{
          // console.log(result);
          return result.data;
        }).then ((response)=>{
          // console.log(response)§;
          if (response.success) {
            var respInfo = response.data;
            setMySearched(respInfo);
            // dispatch(loggedIn(respInfo));
          } else {
            setMySearched([]);
          };
        }).catch((error:any)=>{
          console.log(error.message);
        });
      }else if(searchedTracks === ''){
        setMySearched([]);
      }
    })();
  },[searchedTracks, theGenre, accessToken]);

  useEffect(()=>{
    var stateDerivative:any = {auth: {user: null}};
    if (accessToken) {
      stateDerivative.auth.user = {accessToken: accessToken};
    };
    if((!gotGenre) && (theGenre === null|| theGenre ===undefined)&& (songID === null|| songID ===undefined)){
      var requestObject = {method: "GET", url: appAuthDomain("api/music?action=getGenre")};
      setShowLoading({showLoading: true, showLoadingMessage: "Loading Genres...."});
      makeRequests(stateDerivative, requestObject).then(response=>{
        setShowLoading({showLoading: false, showLoadingMessage: "Loading Genres...."});
        if (response.success) {
          var respInfo = response.data;
          const colors = ['steelblue','aqua', 'chartreuse', 'aquamarine', 'violet', 'yellowgreen', 'thistle', 'skyblue', 'plum', 'orchid', 'mediumvioletred'];
          const pics = ['assets/icons/hi-hopi.jpeg', 'assets/icons/1.jpeg', 'assets/icons/2.jpeg', 'assets/icons/3.jpeg', 'assets/icons/4.webp', 'assets/icons/5.jpeg', 'assets/icons/6.avif'];        
          const updatedRespInfo = respInfo.map((item:any, index:number) => {
            item.color = colors[index % colors.length]; 
            item.image = pics[index % pics.length]; 
            return item;
          });
          setGenre(updatedRespInfo)
        }else{
          console.log(response.msg2)
        }
        setGotGenre(true);
      })
    }else if(theGenre ||songID){
      // if(mySearched.length<1){
        var url = "api/music?action=getGenreMusic&genreID="+theGenre;
        if(songID){
          url = "api/music?action=getThisMusic&songID="+parseInt(songID);
        }
        requestObject = {method: "GET", url: appAuthDomain(url)};
        setShowLoading({showLoading: true, showLoadingMessage: "Loading...."});
        makeRequests(stateDerivative, requestObject).then(response=>{
          setShowLoading({showLoading: false, showLoadingMessage: "Loading..."});
          if (response.success) {
            var respInfo = response.data;
            if(respInfo.length> 0){
              // console.log("here")
              setMySearched(respInfo);
              if((songID !== null|| songID !==undefined) && (response.data.length> 0)){
                localStorage.setItem('sharedSong', JSON.stringify(respInfo));
                setHadSongs(true);
              }
            }
            // setSearchedGenre(respInfo);
          }else{
            console.log(response)
          }
        })
      // }
      
    }
  },[gotGenre,theGenre, songID, genName, accessToken]);



  if(hadSongs && myShared.length< 1){
    var songs :any =[];
    songs = localStorage.getItem("sharedSong");
    setMyShared(JSON.parse(songs))
  }

  const addFav = (track: any) => {
    if (state.auth.user) {
      if (state.auth.user.isSubscribed) {
        dispatch(setFavTrack(track));
      }else {
        dispatch(togglePaymentPopup(true));
      }
      
    }else{
      history.push("/login");
    }
  };
// console.log(mySearched)

  return (
    <IonPage>
      <IonHeader mode='ios'> 
        <IonToolbar mode='ios'>
          <IonButtons slot="start">
            <IonBackButton defaultHref={backRef} text="Back" icon={chevronBack} />
          </IonButtons>
          <IonTitle>For You</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" mode='ios'>
          <IonToolbar mode='ios'>
            <IonTitle size="large">Discover</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div >
          <IonSearchbar mode='ios' className='mySearchBTN' placeholder={searcFor} value={searchedTracks} 
          onIonChange={e => setSearchedTracks(e.detail.value!)}></IonSearchbar>
        </div>
        <div className="topHome">
          <IonList mode='ios'>
            {/* <IonListHeader>
              <IonLabel>All Songs</IonLabel>
            </IonListHeader> */}
            {/* {
             
            } */}
            {
              (mySearched.length > 0) ? (
                mySearched.map((track: any, key:number ) => {
                  if (track.type !== "loader") {
                    if (track.title) {
                      if(track.from === 'albums'){
                        return <IonItem key={key} onClick={() => history.push("/album?id="+track.id+"&play=no")} mode='ios' className="trackListed">
                          <IonThumbnail slot="start" className='thumbnail'>
                            <img alt={track.title} src={img(track.img)}/>
                          </IonThumbnail>
                          <IonLabel>
                            <h2>{track.title}</h2>
                            <p>{track.artist} <span style={{color:'red'}}>(Album)</span></p>
                          </IonLabel>
                          {/* <IonIcon className="offline-indicator" icon={(isOfflineTrack(state, track))?(cloudDownload):(cloudDownloadOutline)} slot="end"/> */}
                        </IonItem>;
                      }if(track.from === 'songs'){
                        return <IonItemSliding key={key} className="trackListed">
                          <IonItem key={key} className="trackListed" mode='ios'>
                            <IonThumbnail slot="start" className='thumbnail' onClick={() => doPlay(track, mySearched)} >
                              <img alt={track.title} src={img(track.img)}/>
                            </IonThumbnail>
                            <IonIcon onClick={() => doPlay(track, mySearched)} icon={play} slot="start"/>
                            <IonLabel>
                              <h2>{track.title}</h2>
                              <p>{track.artist} <span style={{color:'red'}}>(SONG)</span></p>
                            </IonLabel>
                            <IonIcon icon={(isFavTrack(state, track))?(heartCircle):(heartCircleOutline)} onClick={()=> addFav(track)} slot="end"/>
                            <IonIcon className="offline-indicator" onClick={(e)=>{actionBtnsClick(e, {action: 'save', song: track})}} icon={(isOfflineTrack(state, track))?(cloudDownload):(cloudDownloadOutline)} slot="end"/>
                          </IonItem>
                          <IonItemOptions key={track.id}>
                            <IonItemOption color="success" onClick={(e)=> selectPlaylist(track)}><IonIcon slot="start" icon={add}/>Playlist</IonItemOption>
                          </IonItemOptions>
                          </IonItemSliding>;
                      }else{
                        return <IonItemSliding key={key} className="trackListed">
                          <IonItem key={key} className="trackListed" mode='ios'>
                            <IonThumbnail slot="start" className='thumbnail' onClick={() => doPlay(track, mySearched)}>
                              <img alt={track.title} src={img(track.img)}/>
                            </IonThumbnail>
                            <IonIcon onClick={() => doPlay(track, mySearched)} icon={play} slot="start"/>
                            <IonLabel>
                              <h2>{track.title}</h2>
                              <p>{track.artist}</p>
                            </IonLabel>
                            <IonIcon icon={(isFavTrack(state, track))?(heartCircle):(heartCircleOutline)} onClick={()=> addFav(track)} slot="end"/>
                            <IonIcon className="offline-indicator" onClick={(e)=>{actionBtnsClick(e, {action: 'save', song: track})}} icon={(isOfflineTrack(state, track))?(cloudDownload):(cloudDownloadOutline)} slot="end"/>
                          </IonItem>
                          <IonItemOptions key={track.id}>
                            <IonItemOption color="success" onClick={(e)=> selectPlaylist(track)}><IonIcon slot="start" icon={add}/>Playlist</IonItemOption>
                          </IonItemOptions>
                        </IonItemSliding>;
                      }
                    }else{return ""}
                  } else {
                    return <IonItem key={key} mode='ios' className="trackListed">
                      <IonThumbnail slot="start" className='thumbnail'>
                        {/* <h2><IonSkeletonText animated style={{ width: '100%', float: 'left'  }} /></h2> */}
                      </IonThumbnail>
                      <IonLabel>
                        <h2><IonSkeletonText animated style={{ width: '100%', float: 'left'  }} /></h2>
                        <p><IonSkeletonText animated style={{ width: '80%', float: 'left'  }} /></p>
                      </IonLabel>
                      <IonIcon className="offline-indicator" icon={cloudDownloadOutline} style={{color: "gray"}} slot="end"/>
                    </IonItem>;
                  }
                })
              ) : (
                (theGenre)?(
                    <p>No music available on {genName}</p>                  
                ):(
                  (genre.length > 0) ? (
                    genre.map((genre: any ) => {
                      return  <IonCard key={genre.id} onClick={()=>history.push("/search?genre="+genre.id+"&genName="+genre.title)} className="genre" style={{ backgroundColor: genre.color }} mode='ios'>
                        <div className='genreName'>
                          <IonLabel>{genre.title}</IonLabel>
                        </div>
                        <img className='genreIMG' alt='img' src={genre.image}/>
                      </IonCard>
                    })
                  ):(
                    (myShared.length > 0) ? (
                      myShared.map((track: any, key:number ) => {
                        return <IonItemSliding key={key} className="trackListed">
                          <IonItem key={key} className="trackListed" mode='ios'>
                            <IonThumbnail slot="start" className='thumbnail' onClick={() => doPlay(track, myShared)} >
                              <img alt={track.title} src={img(track.img)}/>
                            </IonThumbnail>
                            <IonIcon onClick={() => doPlay(track, myShared)} icon={play} slot="start"/>
                            <IonLabel>
                              <h2>{track.title}</h2>
                              <p>{track.artist}</p>
                            </IonLabel>
                            {/* <IonIcon icon={heartCircleOutline} onClick={()=> addFav(track)} slot="end"/> */}
                            <IonIcon className="offline-indicator" onClick={(e)=>{actionBtnsClick(e, {action: 'save', song: track})}} icon={(isOfflineTrack(state, track))?(cloudDownload):(cloudDownloadOutline)} slot="end"/>
                          </IonItem>
                          <IonItemOptions key={track.id}>
                            <IonItemOption color="success" onClick={(e)=> selectPlaylist(track)}><IonIcon slot="start" icon={add}/>Playlist</IonItemOption>
                          </IonItemOptions>
                        </IonItemSliding> ;
                     })):("")
                  )
                )
              )
            }
          </IonList>
        </div>
        {/* <ExploreContainer name="Tab 2 page" /> */}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </IonContent>
      <IonLoading
          mode='ios'
          isOpen={showLoadingState.showLoading}
          onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
          message={showLoadingState.showLoadingMessage}
      />
    </IonPage>
  );
};

export default Tab2;
