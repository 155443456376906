// import { useEffect } from 'react';
import { IonBackButton, IonButton, IonButtons, IonIcon, IonInput, IonItem, IonLabel} from '@ionic/react';
import { chevronBack, mail } from 'ionicons/icons';

// import { AppContext } from '../Services/State';


const recover: React.FC<any> = ({username, ranKey, onsubmit, formEntries}) => {
  // const { dispatch } = useContext(AppContext);

  if (username === "") {
    username = localStorage.getItem("username");
    ranKey = localStorage.getItem("ranKey");
  }
  
  return (
    <>
    <div className="topDiv">
      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>
      <IonButtons slot="start" className="myHead">
          <IonBackButton className="mtBackBtn" defaultHref="home" text="Back" icon={chevronBack} />
      </IonButtons>
      <div className='logContainer'>
        <div className='logo'><img alt='logo' src='assets/icon/logo.png'/></div>
        <h1>Reset password</h1>

        <form method="POST" onSubmit={onsubmit}>
          <input type="hidden" name="type" defaultValue="resetPassword" />
          <input type="hidden" name="username" defaultValue={username} />
          <input type="hidden" name="ranKey" defaultValue={ranKey} />
          <IonItem className="ionMyItem">
            <IonLabel position="floating" className="theLabel" >Enter password</IonLabel>
            <IonIcon className="iconPart" icon={mail} />
            <IonInput name="password" value={(formEntries&&formEntries['password'])?(formEntries['password']):("")} type="password" className="theInput" required />
          </IonItem>
          <IonItem className="ionMyItem">
            <IonLabel position="floating" className="theLabel" >Repeat password</IonLabel>
            <IonIcon className="iconPart" icon={mail} />
            <IonInput name="passwordRepeat" value={(formEntries&&formEntries['passwordRepeat'])?(formEntries['passwordRepeat']):("")}  type="password" className="theInput" required />
          </IonItem>
          <br/>
          <br/>
          <IonButton expand="block" type="submit" className="ion-activatable ripple-parent">Submit</IonButton>
        </form>
        <br/>
        <br/>
        <div className="myButtons">                                            
          {/* <p className="myForgot" onClick={()=>routerFunction('register')}>Remember your password?  <span>Sign in</span></p>           */}
          </div>
      </div>
    </div>
    </>
  );
};

export default recover;
