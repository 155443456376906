// import { useEffect } from 'react';
import { IonBackButton, IonButton, IonButtons, IonIcon, IonInput, IonItem, IonLabel} from '@ionic/react';
import { chevronBack, mail, lockClosed, person, call } from 'ionicons/icons';



const signup: React.FC<any> = ({routerFunction, onsubmit, fowardOnsubmit, formEntries}) => {
  
  return (
    <>
      <div className="topDiv">
        <div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>
        <IonButtons slot="start" className="myHead">
            <IonBackButton className="mtBackBtn" defaultHref="home" text="Back" icon={chevronBack} />
        </IonButtons>
        <div className='logContainer'>
          {/* <div className='logo'><img alt='logo' src='assets/icon/logo.png'/></div> */}
          <h1>Sign up</h1>

          <form method="POST" onSubmit={fowardOnsubmit}>
            <input type="hidden" name="type" defaultValue="register" />

            <IonItem className="ionMyItem">
              <IonLabel position="floating" className="theLabel" >First name</IonLabel>
              <IonIcon className="iconPart" icon={person} />
              <IonInput name="firstname" value={(formEntries&&formEntries['username'])?(formEntries['username']):("")} type="text" className="theInput" required />
            </IonItem>

            <IonItem className="ionMyItem">
              <IonLabel position="floating" className="theLabel" >Last name</IonLabel>
              <IonIcon className="iconPart" icon={person} />
              <IonInput name="lastname" value={(formEntries&&formEntries['lastname'])?(formEntries['lastname']):("")} type="text" className="theInput" required />
            </IonItem>

            <IonItem className="ionMyItem">
              <IonLabel position="floating" className="theLabel" >Email address</IonLabel>
              <IonIcon className="iconPart" icon={mail} />
              <IonInput name="email" value={(formEntries&&formEntries['email'])?(formEntries['email']):("")} type="text" className="theInput" required />
            </IonItem>

            <IonItem className="ionMyItem">
              <IonLabel position="floating" className="theLabel" >Phone number</IonLabel>
              <IonIcon className="iconPart" icon={call} />
              <IonInput name="phone" value={(formEntries&&formEntries['phone'])?(formEntries['phone']):("")} type="text" className="theInput" required />
            </IonItem>

            <IonItem className="ionMyItem">
              <IonLabel position="floating" className="theLabel" >Password</IonLabel>
              <IonIcon className="iconPart" icon={lockClosed} />
              <IonInput name="password" value={(formEntries&&formEntries['password'])?(formEntries['password']):("")} type="password" className="theInput" required />
            </IonItem>

            <IonItem className="ionMyItem">
              <IonLabel position="floating" className="theLabel" >Repeat password</IonLabel>
              <IonIcon className="iconPart" icon={lockClosed} />
              <IonInput name="password2" value={(formEntries&&formEntries['password2'])?(formEntries['password2']):("")} type="password" className="theInput" required />
            </IonItem>
            <br/>
            <br/>
            <IonButton expand="block" type="submit" className="ion-activatable ripple-parent">Sign in</IonButton>
          </form>
          <br/>
          <br/>
          <div className="myButtons">                                            
            <p className="myNew" onClick={()=>routerFunction('login')}>Have an account?  <span> Sign in.</span></p>           
          </div>
        </div>
      </div>
    </>
  );
};

export default signup;
