import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonAlert, IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, useIonToast } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Capacitor } from '@capacitor/core';

import axios from 'axios';

// import { home, library, menu, musicalNotes, search, settings } from 'ionicons/icons';
import { home, menu, musicalNotes, search} from 'ionicons/icons';

import LandingPage from './pages/Landing';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Library from './pages/Library';
import Settings from './pages/Settings';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/profile';
import Album from './pages/Album';
import Playlist from './pages/playlist';
import Albums from './pages/Albums';
// import AppUrlListener from './components/AppUrlListener'

import Songs from './pages/Songs';
import Audio from "./components/Audio";
import TrackPlayer from "./components/TrackPlayer";
import AuthRoute from './rerouting/AuthRoute';
import NetworkRoute from './rerouting/NetworkRoute';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/*  */
import TabBarSticky from './components/TabBarStick';
import TrackPreview from './components/TrackPreview';
import { AppContext, getPlaying, loggedIn, checkIsOnline, setTracks, setIsSubscribed, makeRequests, setOffline, getIsSubscribed, togglePaymentPopup, setToFavs } from './Services/State';
import PaymentPopup from './components/PaymentPopup';
import { appAuthDomain, appDatabase, getAllOnDB } from './Services/utils';

// import { appAuthDomain } from './Services/utils';


const App: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  interface AlertTypeInterface {
    header: string;
    subHeader: string;
    message: string;
    buttons: any;
    inputs: any;
    showAlert: boolean;
  }
  const [showAlertState, setShowAlert] = useState<AlertTypeInterface>({header: "", subHeader: "", message: "", buttons: [], inputs:[], showAlert: false});
  // const appRef = useRef<HTMLIonAppElement | null>(null);
  const routerRef = useRef<HTMLIonRouterOutletElement | null>(null);
  const initialPlaylists = {
    default: [], // A default playlist without a name
  };
  const [playlists, setPlaylists] = useState(() => {
    const storedPlaylists = localStorage.getItem('playlists');
    return storedPlaylists ? JSON.parse(storedPlaylists) : initialPlaylists;
  });
  var conditionalLandingPage = {value: true, page: "home"};
  let initialAppMode:any  = false;
  const [present, dismiss] = useIonToast();

  if (localStorage.appMode !==undefined) {
    initialAppMode = (localStorage.getItem("appMode")=== 'true')? true: false;
  }else{
    localStorage.setItem("appMode", 'false');
  }
  const [appMode, setAppMode] = useState(initialAppMode);
  const [fetched, setFetched] = useState(false);

  const playing = getPlaying(state);

  const isOnline = checkIsOnline(state); // Check online state upon AppStart first

  let initialAuthedCheck = false;
  let accessToken:any = null;
  
  window.document.body.classList.toggle('dark', appMode);

  
  const doAuth = useCallback(user => {
    dispatch(loggedIn(user));
  }, [dispatch]);
  if (localStorage.getItem("accessToken")) {
    accessToken = localStorage.getItem("accessToken");
    if (accessToken !== "") {
      initialAuthedCheck = true;
    }
  };
  var isAuthorized = initialAuthedCheck;
  
  useEffect(()=>{
    const checkSubscription = ()=>{
      var headers = {
          Authorization: "Bearer "+accessToken
      };
      return axios.get(appAuthDomain("backend/includes/subscription/subscription.php?action=checkSubscription"), {headers}).then(response=>{
          // console.log(response);
          if (response.data) {
            var respInfo = response.data;
            var subscriptionState:any = true;
            if (respInfo.success) {
                localStorage.setItem("isSubscribed", subscriptionState);
                dispatch(setIsSubscribed(true));
            } else {
                subscriptionState = false;
                localStorage.setItem("isSubscribed", subscriptionState);
                dispatch(setIsSubscribed(false));
            };
          }  
      }).catch(error=>{
          dispatch(setIsSubscribed(false));
          console.log(error.message);
      });
    }

    if (initialAuthedCheck) {
      checkSubscription();
    };

    
    

  }, [ accessToken, initialAuthedCheck, dispatch]);

  useEffect(() => {
    if(!fetched){
      getAllOnDB('songs', 'offlineSongs').then((res: any)=>{
        if (res.success) {
          const songs = res.data;
          for (let i = 0; i < songs.length; i++) {
            songs[i].imageBlob = URL.createObjectURL(songs[i].imageBlob);
            dispatch(setOffline(songs[i]));
          }
        }
      })
      setFetched(true)
    }
  },[dispatch, fetched])
  
  useEffect(() => {
    if (initialAuthedCheck) {
      var myRefreshToken = localStorage.getItem("refreshToken");
      var myFirstname = localStorage.getItem("firstname");
      // accessToken = localStorage.getItem("accessToken");
      var myLastname = localStorage.getItem("lastname");
      var myRole = localStorage.getItem("role");
      var myRoleName = localStorage.getItem("role");
      var isSubscribed = false;
      if (localStorage.getItem("isSubscribed")) {
        isSubscribed = (localStorage.getItem("isSubscribed")=== 'true')? true: false;
      };
      
      var userInfo = {accessToken: accessToken, refreshToken: myRefreshToken, 
        firstname: myFirstname, lastname: myLastname, role: myRole, roleName: myRoleName,
        isSubscribed: isSubscribed,
        
      };
      doAuth(userInfo);
    };

    if (isOnline) {// If online get 
      var stateDerivative:any = {auth: {user: null}};
      if (accessToken) {
        stateDerivative.auth.user = {accessToken: accessToken};
      };
      (function cloudUserState(){
        var requestObject = {method: "GET", url: appAuthDomain("api/user?action=getProfile")};
        return makeRequests(stateDerivative, requestObject).then(response=>{
          // console.log(response);
          if (response.success) {
            var respInfo = response.data;
            var isSubscribed = false;
            if (localStorage.getItem("isSubscribed")) {
              isSubscribed = (localStorage.getItem("isSubscribed")=== 'true')? true: false;
            };
            respInfo.isSubscribed = isSubscribed;
            respInfo.accessToken = accessToken;
            dispatch(loggedIn(respInfo));
          } else {
            if ((response.msg2).includes("status code 404")) {
            } else {
              dispatch(loggedIn(null));
              // dispatch({
              //   type: "LOGOUT",
              // //   payload: resJson
              // }); 
            }
          };
        })
      })();

      //Now fetch Favs
      (async function favMusic(){
        var config = {
          headers: {
            Authorization: "Bearer "+accessToken
          }
        };
        const result = await axios.get(appAuthDomain("api/music?action=getFavs"), config);
        const response = result.data;
        if (response.success) {
          var respInfo = response.data;
          dispatch(setToFavs(respInfo))
        }
      })();

      //Now fetch online music
      (function cloudUserMusic(){
        var loaderTracks = {
          hotAlbums: ["0", "0"],
          hotTracks: ['0', '1'],
          tracks: [
            {
              id: '0',
              title: 'Hey Jude',
              artist: 'The Beatles',
              img: 'music/hey-jude.jpg',
              src: 'assets/audio/Yini Sdakwa.mp3',
              time: 211000,
              type: "loader",
            },
            {
              id: '1',
              title: 'Bohemian Rhapsody',
              artist: 'Queen',
              img: 'music/bohemian-rhapsody.jpg',
              src: "assets/audio/Uded 'Usebenzile (Durban Finest).mp3",
              time: 259000,
              type: "loader"
            },
          ]
        };
        dispatch(setTracks(loaderTracks));

        var requestObject = {method: "GET", url: appAuthDomain("backend/includes/music/music.php")};
        return makeRequests(stateDerivative, requestObject).then(response=>{
          // console.log(response);
          if (response.success) {
            var respInfo = response.data;
            // dispatch(loggedIn(respInfo));
            dispatch(setTracks(respInfo));
          } else {
            var fetchedTracks:any = {
              hotTracks: [],
              hotAlbums: [],
              tracks: []
            };

            fetchedTracks = {
              hotTracks: ['5', '4'],
              hotAlbums: ["0", "6"],
              tracks: [
                {
                  id: '0',
                  title: 'Hey Jude',
                  artist: 'The Beatles',
                  img: 'music/hey-jude.jpg',
                  src: 'assets/audio/Yini Sdakwa.mp3',
                  time: 211000,
                  type: "loader",
                  noOfSongs: 1,
                  thisIsAlbum : true,
                  tracks: []
                },
                {
                  id: '4',
                  title: 'Bohemian Rhapsody',
                  artist: 'Queen',
                  img: 'music/bohemian-rhapsody.jpg',
                  src: "assets/audio/Uded 'Usebenzile (Durban Finest).mp3",
                  time: 259000,
                  type: "loader",
                  noOfSongs: 1,
                  thiIsAlbum : true,
                  tracks: []
                },
                {
                  id: '5',
                  title: 'Don\'t Stop Believin\'',
                  artist: 'Journey',
                  img: 'music/dont-stop-believin.jpg',
                  src: 'assets/audio/Yini Sdakwa.mp3',
                  time: 211000,
                  type: "loader",
                  noOfSongs: 1,
                  thisIsAlbum : false,
                  tracks: []
                },
                {
                  id: '6',
                  title: 'Hit Me with Your Best Shot',
                  artist: 'Pat Benetar',
                  img: 'music/hit-me-with-your-best-shot.jpg',
                  src: "assets/audio/Uded 'Usebenzile (Durban Finest).mp3",
                  time: 259000,
                  type: "loader",
                  thisIsAlbum : true,
                  noOfSongs: 1,
                  tracks: []
                },
              ]
            };
            dispatch(setTracks(fetchedTracks));
          };
        })
      })();
    } else {
      // will be handled by the rerouting cutom hook.
    }
    return () => {
      console.log("App.tsx file after loading.");
    }
  }, [isOnline, dispatch, accessToken, initialAuthedCheck, doAuth]);
  // console.log(state)

  const actionBtnsClick = useCallback((event, options) => {
    var song = options.song;
    var alertStateVars :any = [];
    // setShowLoading({showLoadingMessage: "Saving for offline, progress: "+progress*100+"%", showLoading: true});
    // const logProgress = (progressEvent: any)=>{
    //   var numberToShow:any = Math.floor((progressEvent.loaded/progressEvent.total)*100);
    //   console.log(numberToShow);
    //   setProgress(numberToShow/100);
    // }
    if(isAuthorized){
      const subscribed = getIsSubscribed(state);
      if(subscribed){
        present({
          position: "bottom",
          buttons: [{ text: 'hide', handler: () => dismiss() }],
          message: "Saving for offline: "+song.title,
          duration: 5000
        });
        Promise.all([
          
          axios.get(appAuthDomain(song.img), {responseType: "blob"}).then((response: any)=>{
            return response.data;
          }),
          // axios.get(appAuthDomain(song.src), {responseType: "blob", onDownloadProgress: logProgress}).then((response: any)=>{
          axios.get(appAuthDomain(song.src), {responseType: "blob"}).then((response: any)=>{
            return response.data;
          })
        ]).then(([imageBlob, songBlob])=>{
          var songStoreData:any = {
            album:song.album, artist:song.artist,genre: song.genre, song: song.id, img: song.img, src: song.src, imageBlob,
            thisIsAlbum: song.thisIsAlbum, time: song.time,title: song.title, type: song.type, year: song.year, songBlob
          }; 
          return appDatabase("songs", 'offlineSongs').then((databaseObject:any)=>{
            return databaseObject.appDbMethod('readwrite').then((vidStore: any)=>{
                return vidStore.put(songStoreData).then((mySong:any)=>{
                  songStoreData['id'] = mySong;
                  songStoreData['imageBlob'] = URL.createObjectURL(songStoreData.imageBlob);
                });
            }).then((res: any)=>{
                databaseObject.db.close();
                event.target.disabled = false;
                // hapticsImpactLight();
                // setShowLoading({showLoadingMessage: " ", showLoading: false});
                // setProgress(0);
                dispatch(setOffline(songStoreData));
                present({
                    position: "bottom",
                    buttons: [{ text: 'hide', handler: () => dismiss() }],
                    message: "Saved offline: "+song.title,
                    duration: 5000
                });
                // handleActionsClose();
                return res;
            });
          });
        })
      }else{
        alertStateVars = {
          header:"You need a subscription to download!",
          subHeader: "", message: "", inputs: [],
          buttons: [ {text: 'Back', handler: () => {}}
          ,{text: 'Subscribe', handler: () => {dispatch(togglePaymentPopup(true));}}]
        };
        setTimeout(() => {
          setShowAlert({...alertStateVars, showAlert: true});
        }, 500);
      }
    }else{
      alertStateVars = {
        header:"You are not logged in",
        subHeader: "", 
        message: "",
        inputs: [],
        buttons: [{text: 'Cancel', handler: () => {}},
         {text: 'Login', handler: () => {window.location.href = '/login';}}]
      };
      setTimeout(() => {
        setShowAlert({...alertStateVars, showAlert: true});
      }, 500);
      
    }
  },[dismiss, isAuthorized, state, dispatch, present])


  // ***********CODE FOR PLAYLISTS *************//

  const handleCreatePlaylist = (value:any) => {
    if(value ==="" || value === null || value === undefined){
      addPlaylist()
    }else{
      setShowAlert({...showAlertState, showAlert: false});
      setPlaylists((prevPlaylists: any) => ({
        ...prevPlaylists,
        [value]: [],
      }));
      var alertStateVars = {
        header: value+" has been created",
        subHeader: "", 
        message: "",
        inputs: [],
        buttons: [ {text: 'Okay', handler: () => {}}]
      };
      setTimeout(() => {
        setShowAlert({...alertStateVars, showAlert: true});
      }, 500);
    }
  };

  const addPlaylist = ()=>{
    var buttonActions:any = [];
    var alertStateVars:any = {};
    var inputs = [{
      placeholder: 'Name',
      name: 'name',
      type: 'text',
      id:"playlist",
    }]
    buttonActions = [
      {
          text: 'Cancel',
          handler: () => {
              // setShowModal2(false);
          }
      },
      {
        text: 'Create',
        handler: (alertData:any) => {
          handleCreatePlaylist(alertData.name);
            // setShowModal2(false);
        }
      }
    ];
    alertStateVars = {
        header: "Create playlist",
        subHeader: "Please write your playlist name", 
        message: "",
        inputs: inputs,
        buttons: buttonActions
    };
    setShowAlert({...alertStateVars, showAlert: true});
  };

  const addToPlaylist = (playlistName: string, track: any) => {
    if(playlistName === "" || playlistName === null || playlistName === undefined || playlistName === "undefined"){
      selectPlaylist(track)
    }else{
      // var theSongObject = {'id': parseInt(track.id), 'isOffline': 'No'};
      // if('songBlob' in track){
      //     theSongObject = {'id': parseInt(track.id), 'isOffline': 'Yes'}
      // }
      // console.log("theSongObject :", theSongObject)
      setPlaylists((prevPlaylists: any) => ({
        ...prevPlaylists,
        // [playlistName]: [...prevPlaylists[playlistName], parseInt(track.id)],
        [playlistName]: [...prevPlaylists[playlistName], track],
      }));
      setShowAlert({...showAlertState, showAlert: false});
      var alertStateVars = {
        header: track.title+" added to "+playlistName,
        subHeader: "", 
        message: "",
        inputs: [],
        buttons: [ {text: 'Okay', handler: () => {}}]
      };
      setTimeout(() => {
        setShowAlert({...alertStateVars, showAlert: true});
      }, 500);
    }
  };

  const selectPlaylist = (track:any)=>{
    const playlistNames = Object.keys(playlists);
    var inputs = playlistNames.map((theName: any) => (
        {
          label: theName,
          type: 'radio',
          value: theName,
          attributes: {
            required: true, // Add the required attribute to make the input required
          },
        }
    ))
    var buttonActions = [
      
      {
          text: 'Back',
          handler: () => {
              // setShowModal2(false);
          }
      }, 
      {
        text: 'Add',
        handler: (alertData:any) => {
          console.log(alertData)
          addToPlaylist(alertData, track)
        }
      }
    ];
    var alertStateVars = {
        header: "Select playlist",
        subHeader: "Please select a playlist", 
        message: "",
        inputs: inputs,
        buttons: buttonActions
    };
    setShowAlert({...alertStateVars, showAlert: true});
  } 
  const deletePlaylist =(playlistName:any)=>{
    setPlaylists((prevPlaylists: any) => {
      const updatedPlaylists = { ...prevPlaylists };
      delete updatedPlaylists[playlistName];
      return updatedPlaylists;
    });
    var alertStateVars = {
      header: playlistName+" removed",
      subHeader: "", 
      message: "",
      inputs: [],
      buttons: [{text: 'Okay',handler: () => {}}]
    };
    setShowAlert({...alertStateVars, showAlert: true});
  }

  const removeFromPlaylist = (playlistName:any, trackIndexToRemove:any) => {
    setPlaylists((prevPlaylists:any) => {
      const updatedPlaylist = [...prevPlaylists[playlistName]];
      const indexOfTrackToRemove = updatedPlaylist.indexOf(trackIndexToRemove);
      // const indexOfTrackToRemove = updatedPlaylist.indexOf(parseInt(trackIndexToRemove));
      if (indexOfTrackToRemove !== -1) {
        updatedPlaylist.splice(indexOfTrackToRemove, 1);
      }
      return {
        ...prevPlaylists,
        [playlistName]: updatedPlaylist,
      };
    });
    var alertStateVars = {
      header: trackIndexToRemove.title+" removed",
      subHeader: "", 
      message: "",
      inputs: [],
      buttons: [{text: 'Okay',handler: () => {}}]
    };
    setShowAlert({...alertStateVars, showAlert: true});
  };
  
  const removePlaylist = (playlistName:any, type:any, song:any) => {
    var playlistHolder = playlistName;
    if(type ==='song'){
      playlistHolder = song.title
    }
    var alertStateVars = {
      header: "You want to remove "+playlistHolder+" ?",
      subHeader: "", 
      message: "",
      inputs: [],
      buttons: [{text: 'Back',handler: () => {}},
      {text: 'Delete',handler: () => {
        if(type ==='song'){
          // removeFromPlaylist(playlistName, song.id);
          removeFromPlaylist(playlistName, song);
        }else{
          deletePlaylist(playlistName);
        }
      }}]
    };
    setShowAlert({...alertStateVars, showAlert: true});
  };

  const getPlaylistLength = (playlistName:any) => {
    return playlists[playlistName] ? playlists[playlistName].length : 0;
  };

  useEffect(() => {
    localStorage.setItem('playlists', JSON.stringify(playlists));
  }, [playlists]);

  //**************//

  if (((Capacitor.getPlatform() === 'ios')||(Capacitor.getPlatform() === 'android'))&&(isAuthorized)) {
    conditionalLandingPage = {value: false, page: "home"};
  };

  return (
    <IonApp >
      <IonReactRouter>
        {
          (playing) ? (
            <TabBarSticky>
              <TrackPreview />
            </TabBarSticky>
          ) : ""
        }
        <IonTabs>
          {/* <AppUrlListener></AppUrlListener> */}
          <IonRouterOutlet ref={routerRef} mode='ios'>
            <NetworkRoute isOnline={isOnline} exact path="/">
              <Tab1 actionBtnsClick={actionBtnsClick}/>
            </NetworkRoute>
            <NetworkRoute isOnline={isOnline} exact path="/home">
              <Tab1 actionBtnsClick={actionBtnsClick} addPlaylist={addPlaylist} setShowAlert={setShowAlert} selectPlaylist={selectPlaylist}/>
            </NetworkRoute>
            <NetworkRoute isOnline={isOnline} exact path="/search">
              <Tab2 actionBtnsClick={actionBtnsClick} addPlaylist={addPlaylist} setShowAlert={setShowAlert} selectPlaylist={selectPlaylist}/>
            </NetworkRoute>
            <AuthRoute isAuthorized={isAuthorized} path="/library">
              <Library getPlaylistLength={getPlaylistLength} addPlaylist={addPlaylist} setShowAlert={setShowAlert} selectPlaylist={selectPlaylist} playlists={playlists} removePlaylist={removePlaylist}/>
            </AuthRoute>
            <AuthRoute isAuthorized={isAuthorized} path="/settings">
              <Settings  appMode={appMode} setAppMode={setAppMode}/>
            </AuthRoute>
            <Route exact path="/login">
              <Login name="Sign in" />
              {/* <Login /> */}
            </Route>
            <Route exact path="/register">
              <Login name="Sign up" />
            </Route>
            <Route exact path="/forgotPassword">
              <Login name="Forgot Password" />
            </Route>
            <Route exact path="/resetPassword">
              <Login name="Reset Password" />
            </Route>
            <Route exact path="/otp">
              <Login name="OTP" />
            </Route>
            <NetworkRoute isOnline={isOnline} path="/album">
              <Album actionBtnsClick={actionBtnsClick} addPlaylist={addPlaylist} setShowAlert={setShowAlert} selectPlaylist={selectPlaylist}/>
            </NetworkRoute>
            {/* <NetworkRoute isOnline={isOnline} path="/profile"> */}
            <AuthRoute isAuthorized={isAuthorized} path="/profile">
                <Profile/>
              </AuthRoute>
            {/* </NetworkRoute> */}
            {/* <NetworkRoute isOnline={isOnline} path="/dashboard"> */}
              <AuthRoute isAuthorized={isAuthorized} path="/dashboard">
                <Dashboard />
              </AuthRoute>
            {/* </NetworkRoute> */}
            <Route path="/albums">
              <Albums />
            </Route>
            <Route path="/playlist">
              <Playlist playlists={playlists} actionBtnsClick={actionBtnsClick} removePlaylist={removePlaylist}/>
            </Route>
            <Route path="/songs">
              {/* <Songs actionBtnsClick={actionBtnsClick}/> */}
              <Songs actionBtnsClick={actionBtnsClick} addPlaylist={addPlaylist} setShowAlert={setShowAlert} selectPlaylist={selectPlaylist} />
            </Route>
            {
              (conditionalLandingPage.value)?(
                <Route exact path="/">
                  <LandingPage />
                </Route>
              ):(
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
              )
            }
            
          </IonRouterOutlet>
          <IonTabBar slot="bottom" id="bottomNav" className='bottomNav' mode='ios'>
            <IonTabButton tab="tab1" href="/home">
              <IonIcon icon={home} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab2" href="/search">
              <IonIcon icon={search} />
              <IonLabel>Search</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab3" href="/library">
              <IonIcon icon={musicalNotes} />
              <IonLabel>Library</IonLabel>
            </IonTabButton>
            <IonTabButton tab="settings" href="/settings">
              <IonIcon icon={menu} />
              <IonLabel>Menu</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
        <Audio />
        <PaymentPopup firstModalRef={routerRef.current}/>
        <TrackPlayer firstModalRef={routerRef.current}/>
      </IonReactRouter>
      <IonAlert
        mode='ios'
        isOpen={showAlertState.showAlert}
        onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
        header={showAlertState.header}
        subHeader={showAlertState.subHeader}
        message={showAlertState.message}
        inputs= {showAlertState.inputs}
        buttons={showAlertState.buttons}
      />
    </IonApp>
  );
}

export default App;
