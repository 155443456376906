import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonIcon, IonLabel, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonInput, IonBackButton, IonButtons, IonButton, IonLoading, IonAlert } from "@ionic/react";
import {person, lockClosed, chevronBack, mail, call, cloudOffline, trash } from "ionicons/icons";
import { useCallback, useContext, useEffect, useState } from "react";
import './profile.css';
import { AppContext} from '../Services/State.jsx';
import axios from "axios";
import { appAuthDomain } from "../Services/utils";

const Profile: React.FC<any> = ({history})=>{
    interface AlertTypeInterface {
        header: string;
        subHeader: string;
        message: string;
        buttons: any;
        showAlert: boolean;
    }
    const { state } = useContext(AppContext);
    const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});
    const [showAlertState, setShowAlert] = useState<AlertTypeInterface>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});
    const [personalProfile, setMyProfile] = useState<any>([]);

    // var firstname = "";
    // var lastname = "";
    // var email = "";
    // var phone = "";
    // var location = "";
    // if (state.auth.user) {
    //     if(state.auth.user.firstname){
    //         firstname = state.auth.user.firstname;
    //     }
    //     if(state.auth.user.lastname){
    //         lastname = state.auth.user.lastname;
    //     }
    // }
    // var personProfile = {firstname: firstname,  lastname:lastname, email:email, phone:phone, location:location};
    // setMyProfile(personProfile);

    var submitFunction = (e: any)=>{
        e.preventDefault();
        var url = e.target.action;
        const config = {
            headers: {
                Authorization: "Bearer "+state.auth.user.accessToken,
                "Content-Type": "multipart/form-data"
            }
        };
        var orderForm = new FormData(e.target);
        orderForm.append("appType", "music");
      
        setShowLoading({showLoadingMessage: "Submitting...", showLoading: true});
        axios.post(appAuthDomain(url), orderForm, config).then(res=>{
            return res.data;
        }).then(response=>{
            // console.log(response);
            setTimeout(() => {
                setShowLoading({...showLoadingState, showLoading: false});
            }, 900);
            var buttonActions:any = [];
            var alertStateVars:any = {};
            if (response.success) {
                // console.log(response.data)
                setMyProfile(response.data);
                buttonActions = [
                    {
                        text: 'Done',
                        handler: () => {
                            // setShowModal2(false);
                        }
                    }
                ];
                alertStateVars = {
                    header: response.msg,
                    subHeader: "", 
                    message: response.msg2,
                    buttons: buttonActions
                };
                setShowAlert({...alertStateVars, showAlert: true});
                // onDismiss('manager');
                // setShowModal2(false);
            } else {
                buttonActions = [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            // setShowModal2(false);
                        }
                    },
                    {
                        text: 'Retry',
                        handler: () => {
                            setTimeout(() => {
                                submitFunction(e);
                            }, 500);
                        }
                    }
                ];
                alertStateVars = {
                    header: response.msg,
                    subHeader: "", 
                    message: response.msg2,
                    buttons: buttonActions
                };
                setShowAlert({...alertStateVars, showAlert: true});
            };
        }).catch(err=>{
            console.log(err.message);
            setTimeout(() => {
                setShowLoading({...showLoadingState, showLoading: false});
            }, 900);
            var buttonActions = [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                },
                {
                    text: 'Retry',
                    handler: () => {
                        submitFunction(e);
                    }
                }
            ];
            var alertStateVars = {header: "Error encountered", subHeader: "", message: err.message, buttons: buttonActions};
            setTimeout(() => {
                setShowAlert({...alertStateVars, showAlert: true});
            }, 1001);
        })
    }

    var accessToken = "";
    if (state.auth.user) {
        if (state.auth.user.accessToken) {
            accessToken = state.auth.user.accessToken;
        }
    };

    const manageAccount = useCallback((whatToDo)=>{
        var buttonActions = [
            {
                text: whatToDo,
                cssClass: 'secondary',
                handler: () => {
                }
            },
            {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                    // doSearch();
                }
            }
        ];
        var alertStateVars = {header: whatToDo+" account", subHeader: "", message: "Are you sure you want to "+whatToDo+" you account?", buttons: buttonActions};
        // setTimeout(() => {
            setShowAlert({...alertStateVars, showAlert: true});
        // }, 1001);
    },[])
    
    useEffect(()=>{
        if(accessToken !== null || accessToken !== ""){
            (function doSearch(){
                var config = {
                headers: {
                    Authorization: "Bearer "+accessToken
                }
                };
                setShowLoading({showLoadingMessage: "Fetching profile...", showLoading: true});
                return axios.get(appAuthDomain("backend/includes/user/userProfile.php?action=getProfile"), config).then((result:any)=>{
                // console.log(result);
                return result.data;
                }).then ((response)=>{
                // console.log(response);
                setTimeout(() => {
                    setShowLoading({showLoadingMessage: "", showLoading: false});
                }, 900);
                if (response.success) {
                    var respInfo = response.data;
                    setMyProfile(respInfo);
                } else {
                    var buttonActions = [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                            }
                        },
                        {
                            text: 'Retry',
                            handler: () => {
                                doSearch();
                            }
                        }
                    ];
                    var alertStateVars = {header: response.msg2, subHeader: "", message: response.msg2, buttons: buttonActions};
                    setTimeout(() => {
                        setShowAlert({...alertStateVars, showAlert: true});
                    }, 1001);
                };
                }).catch((error:any)=>{
                    setTimeout(() => {
                        setShowLoading({showLoadingMessage:"", showLoading: false});
                    }, 900);
                    var buttonActions = [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                console.log('Confirm Cancel');
                            }
                        },
                        {
                            text: 'Retry',
                            handler: () => {
                                doSearch();
                            }
                        }
                    ];
                    var alertStateVars = {header: "Error encountered", subHeader: "", message: error.message, buttons: buttonActions};
                    setTimeout(() => {
                        setShowAlert({...alertStateVars, showAlert: true});
                    }, 1001);
                    var personProfile = {firstname: "Lethu",  lastname:"Ndabazandile", email:"", phone:"", location:""};
                    setMyProfile(personProfile);
                    
                    console.log(error.message);
                });
            
            })();  
        } 
    },[accessToken]);
    

    return (
        <IonPage>
            <IonHeader mode='ios'>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="home" text="Back" icon={chevronBack} />
                    </IonButtons>
                    <IonTitle>Profile</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen scrollEvents={true}>
                <IonHeader collapse="condense" mode='ios'>
                    <IonToolbar>
                        <IonTitle size="large">profile</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div>
                    <br/>
                    <br/>
            
                    <IonCard className="menuSections" mode='ios'>
                        <form action="backend/includes/user/userProfile.php?action=updateProfile" onSubmit={submitFunction}>
                        <input type="hidden" name="type" defaultValue="updateProfile"/>
                            <IonCardHeader>
                                <IonCardTitle>
                                    My Profile
                                </IonCardTitle>
                            </IonCardHeader>
                           
                            <IonCardContent className="cardContent">
                                <IonList className="cardContentList">
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >first name</IonLabel>
                                        <IonIcon className="iconPart" icon={person} />
                                        <IonInput style={{width: "87%"}} name="firstname" value={personalProfile.firstname} type="text" className="theInput" required />
                                    </IonItem>
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >Last name</IonLabel>
                                        <IonIcon className="iconPart" icon={person} />
                                        <IonInput style={{width: "87%"}} name="lastname" value={personalProfile.lastname} type="text" className="theInput" required />
                                    </IonItem>
                                    <IonItem className="profileItems"> 
                                        <IonLabel position="floating" className="theLabel" >Email address</IonLabel>
                                        <IonIcon className="iconPart" icon={mail} />
                                        <IonInput style={{width: "87%"}} name="email" value={personalProfile.email} type="email" className="theInput" required />
                                    </IonItem>
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >Cellphone number</IonLabel>
                                        <IonIcon className="iconPart" icon={call} />
                                        <IonInput style={{width: "87%"}} name="phone" value={personalProfile.phone} type="number" className="theInput" required />
                                    </IonItem>
                                    {/* <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >Your location</IonLabel>
                                        <IonIcon className="iconPart" icon={locate} />
                                        <IonInput style={{width: "87%"}} name="passwordChangeRepeat" value={personalProfile.location} type="password" className="theInput" required />
                                    </IonItem> */}
                                </IonList>
                            </IonCardContent>
                            <IonButton expand="block"  type='submit'>Save</IonButton>
                        </form>
                    </IonCard>
                    <IonCard className="menuSections" mode='ios'>
                        <IonCardHeader>
                            <IonCardTitle>
                                Reset password
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent className="cardContent">
                            <form action="backend/includes/user/userProfile.php?action=resetPassword" onSubmit={submitFunction}>
                                <input type="hidden" name="type" defaultValue="resetPassWord" />
                                <IonList className="cardContentList">
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >Old Password</IonLabel>
                                        <IonIcon className="iconPart" icon={lockClosed} />
                                        <IonInput style={{width: "87%"}} name="oldPassword" type="password" className="theInput" required />
                                    </IonItem>
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >New Password</IonLabel>
                                        <IonIcon className="iconPart" icon={lockClosed} />
                                        <IonInput style={{width: "87%"}} name="password" type="password" className="theInput" required />
                                    </IonItem>
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >Repeat new password</IonLabel>
                                        <IonIcon className="iconPart" icon={lockClosed} />
                                        <IonInput style={{width: "87%"}} name="passwordRepeat" type="password" className="theInput" required />
                                    </IonItem>
                                </IonList>
                                <IonButton expand="block" type='submit'>Reset</IonButton>
                            </form>
                            {/* <IonList className="cardContentList">
                                <IonItem>
                                    <IonIcon icon={chatbubbles} />
                                    <IonLabel> About us</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonIcon icon={helpCircle} />
                                    <IonLabel> Help</IonLabel>
                                </IonItem>
                            </IonList> */}
                        </IonCardContent>
                    </IonCard>
                    <IonCard 
                    className="menuSections" mode='ios'
                    >
                        <IonCardHeader>
                            <IonCardTitle>
                                Account State
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent className="cardContent">
                            <IonItem onClick={()=>manageAccount('Deactivate')} button>
                                <IonIcon slot="start" icon={cloudOffline} className="component-icon component-icon-dark" />
                                <IonLabel>De-activate account</IonLabel>
                            </IonItem>
                            <IonItem onClick={()=>manageAccount('Delete')} button>
                                <IonIcon slot="start" icon={trash} className="component-icon component-icon-dark" />
                                <IonLabel>Delete account</IonLabel>
                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </IonContent>
            <IonLoading
                mode='ios'
                isOpen={showLoadingState.showLoading}
                onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
                message={showLoadingState.showLoadingMessage}
            />
            <IonAlert
                mode='ios'
                isOpen={showAlertState.showAlert}
                onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
                header={showAlertState.header}
                subHeader={showAlertState.subHeader}
                message={showAlertState.message}
                buttons={showAlertState.buttons}
            />
        </IonPage>
      );

}
export default Profile;
