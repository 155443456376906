import { IonPage, useIonViewWillEnter } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import './Landing.css';
const LandingPage: React.FC = ()=>{
    const history = useHistory();
    useIonViewWillEnter(() => {
        const bottomNav = document.getElementById("bottomNav");
        if (bottomNav) {
            bottomNav.style.display = "none";
        };
    });

    return (
        <IonPage>
            <div className="landingMainHolder">
                <div className='roundDIv'>
                    <div className='imageDiv'><img alt='logo' src='assets/icon/logo.png'/></div>
                </div>
                <button className='tuneBtn' onClick={() =>history.push("/home")}>Listen now</button>
                <p className='logs'><b onClick={() =>history.push("/login")}>Login</b> or <b onClick={() =>history.push("/register")}>Register</b> a new account</p>
            </div>
        </IonPage>
    )

}
export default LandingPage;