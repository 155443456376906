// import { useEffect } from 'react';
import { IonBackButton, IonButton, IonButtons,IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import { chevronBack, lockClosed, mail } from 'ionicons/icons';


const signin: React.FC<any> = ({routerFunction, onsubmit, fowardOnsubmit, formEntries}) => {



  
  return (
    <>
      <div className="topDiv">
        <div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>
        <IonButtons slot="start" className="myHead">
            <IonBackButton className="mtBackBtn" defaultHref="home" text="Back" icon={chevronBack} />
        </IonButtons>
        <div className='logContainer'>
          <div className='logo'><img alt='logo' src='assets/icon/logo.png'/></div>
          <h1>Sign in</h1>

          <form method="POST" onSubmit={fowardOnsubmit}>
            <input type="hidden" name="type" defaultValue="login" />
            <IonItem className="ionMyItem">
              <IonLabel position="floating" className="theLabel" >Email / Phone</IonLabel>
              <IonIcon className="iconPart" icon={mail} />
              <IonInput name="username" value={(formEntries&&formEntries['username'])?(formEntries['username']):("")} type="text" className="theInput" required />
            </IonItem>

            <IonItem className="ionMyItem">
              <IonLabel position="floating" className="theLabel" >Password</IonLabel>
              <IonIcon className="iconPart" icon={lockClosed} />
              <IonInput name="password" value={(formEntries&&formEntries['password'])?(formEntries['password']):("")} type="password" className="theInput" required />
            </IonItem>
            <br/>
            <br/>
            <IonButton expand="block" type="submit" className="ion-activatable ripple-parent">Sign in</IonButton>
          </form>
          <br/>
          <br/>
          <div className="myButtons">                                            
            <p className="myForgot" onClick={()=>routerFunction('forgotPassword')}>Forgot password?  </p> 

            <p className="myNew" onClick={()=>routerFunction('register')}>Don't have an account?  <span> Sign up now.</span></p>           
          </div>
        </div>
      </div>
    </>
      
  );
};

export default signin;
