import { useCallback, useContext, useEffect, useState } from 'react';
// import { useCallback, useContext} from 'react';
import {IonContent, IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonPage, IonSearchbar, IonSegment, IonSegmentButton, IonThumbnail, IonTitle, IonToolbar, useIonToast, useIonViewWillEnter } from '@ionic/react';
import { AppContext, getRecentTracks, playTrack, isOfflineTrack, playTrackList, getOfflineTracks, setOffline} from '../Services/State.jsx';

import './Library.css';
import { add, addCircle, cloudDownload, removeCircleOutline, trash } from 'ionicons/icons';
import { appAuthDomain, appDatabase, getAllOnDB, getItemOnDB, img } from '../Services/utils';
import axios from 'axios';
import { useHistory } from 'react-router';

const Library: React.FC<any> = ({getPlaylistLength,addPlaylist, setShowAlert, selectPlaylist, playlists, removePlaylist}) => {
 
  const { state, dispatch } = useContext(AppContext);
  const [fetched, setFetched] = useState(false);
  const [segment, setSegment] = useState('recents');
  const [present, dismiss] = useIonToast();
  const [firstLoad, setFirstLoad] = useState(true);
  const [favTracks, getFavTracks] = useState([]);
  const [offlineSongs, setOfflineSongs] = useState<any>([]);
  const recentTracks = getRecentTracks(state);
  const history = useHistory();

  // const favTracks = getFavTracks(state);

  var accessToken = "";
  if (state.auth.user) {
    if (state.auth.user.accessToken) {
      accessToken = state.auth.user.accessToken;
    }
  };

  const fetchSaved = useCallback(() => {
    getAllOnDB('songs', 'offlineSongs').then((res: any)=>{
      if (res.success) {
        const songs = res.data;
        for (let i = 0; i < songs.length; i++) {
          songs[i].imageBlob = URL.createObjectURL(songs[i].imageBlob);
          dispatch(setOffline(songs[i]));
        }
        setOfflineSongs(songs)
      }
    })
    setFetched(true)
  },[dispatch])

  const fetchFavs = useCallback(async () => {
    var config = {
      headers: {
        Authorization: "Bearer "+accessToken
      }
    };
    try {
      const result = await axios.get(appAuthDomain("api/music?action=getFavs"), config);
      const response = result.data;
      if (response.success) {
        var respInfo = response.data;
        // console.log("favs", respInfo)
        getFavTracks(respInfo);
      } else {
        getFavTracks([]);
      };
    } catch (error:any) {
      console.log(error.message);
    }
  },[accessToken])
  
  useEffect(()=>{
    if(!fetched){
      const offlineTracks = getOfflineTracks(state);
      if(offlineTracks.length === 0){
        fetchSaved();
      }else{
        setOfflineSongs(offlineTracks);
      }
    }
    if(firstLoad){
      if((favTracks.length=== 0) && (recentTracks.length=== 0)){
        setSegment('offline');
      }else if((favTracks.length> 0) && (recentTracks.length=== 0)){
        setSegment('favourites');
      }
      setFirstLoad(false);
    }
    
  },[ fetchSaved, state, favTracks, recentTracks, firstLoad, fetched]);

  useIonViewWillEnter(() => {
    setFetched(false)
    if(accessToken !== ""){
      fetchFavs();
    }
  });

  const onSegment = (seg: any)=>{
      setSegment(seg);
  };

  const playSaved = useCallback((track, songsList) =>{
    var myPlayList:any = [];
    var tempTracks:any = [];
    songsList.forEach((song:any)=>{
      myPlayList.push(song.id);
      tempTracks.push(song);
      // getCurrentTracks.push(song);
    });
    // console.log(tempTracks, myPlayList, track)
    dispatch(playTrackList(tempTracks, myPlayList, track));
  },[dispatch])
  
  const doPlay = useCallback(track => {
    dispatch(playTrack(track));
  }, [dispatch]);

  const actionBtnsClick = useCallback((event, options) => {
    var song = options.song;
    console.log(event)
    if(options.action ==='deleteFav'){
      var config = {
        headers: {
          Authorization: "Bearer "+accessToken
        }
      };
      return axios.get(appAuthDomain("api/music?action=deleteFavs&id="+song.favId), config).then((result:any)=>{
        return result.data;
      }).then ((response)=>{
        present({
          position: "bottom",
          buttons: [{ text: 'hide', handler: () => dismiss() }],
          message: response.msg2,
          duration: 4000
        });
        if (response.success) {
          fetchFavs();
        }
      }).catch((error:any)=>{
        console.log(error.message);
      });
    }else{
      present({
        position: "bottom",
        buttons: [{ text: 'hide', handler: () => dismiss() }],
        message: 'Deleting: '+song.title,
        duration: 3000
      });
      var itemBy = {database: "songs", table: "offlineSongs", byCol: 'id', byColValue: song.id};
      getItemOnDB(itemBy).then((res: any)=>{
        if (res.success) {
          let savedKey = res.data.id;
          appDatabase("songs", 'offlineSongs').then((databaseObject:any)=>{
            return databaseObject.appDbMethod('readwrite').then((vidStore: any)=>{
              return vidStore.delete(savedKey);
            }).then((res:any)=>{
              databaseObject.db.close();
              event.target.disabled = false;
              present({
                  position: "bottom",
                  buttons: [{ text: 'hide', handler: () => dismiss() }],
                  message: "Removed offline: "+song.title,
                  duration: 3000
              });
              fetchSaved()
              return res;
            });
          })
        }
      })
    }
  },[dismiss, fetchSaved, accessToken, fetchFavs, present])

  return (
    <IonPage>
      <IonHeader mode='ios'>
        <IonToolbar mode='ios'>
          <IonTitle>Your Library</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" mode='ios'>
          <IonToolbar mode='ios'>
            <IonTitle size="large">Your Library</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonItem button className='mySearchBTN' onClick={()=> addPlaylist()} mode='ios'>
          <IonIcon icon={addCircle}/>
          <IonLabel>
            Add playlist
          </IonLabel>
        </IonItem>
        <br/>
        <IonSegment mode='ios' value={segment} className='mySearchBTN' onIonChange={e => onSegment(e.detail.value!)}>
            <IonSegmentButton value="recents">
                <IonLabel>Recent</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="favourites">
                <IonLabel>Favorites</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="offline">
                <IonLabel>Saved</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="playlists">
                <IonLabel>playlists</IonLabel>
            </IonSegmentButton>
        </IonSegment>
        <div>
          <IonSearchbar mode='ios' className='mySearchBTN'></IonSearchbar>
        </div>
        {
          (segment === "recents")?(
          <>
          <IonList mode='ios'>
            <IonListHeader mode='ios'>
              <IonLabel>Recent Tracks</IonLabel>
            </IonListHeader>
            {(recentTracks.length> 0)?(
              recentTracks.map((track: any, key:number) => (
                <IonItemSliding key={key} className="trackListed">
                  <IonItem key={track.title} onClick={() => doPlay(track)} mode='ios' button>
                    <IonThumbnail slot="start" className='thumbnail'>
                      <img alt={track.title} src={img(track.img)}/>
                    </IonThumbnail>
                    <IonLabel>
                      <h2>{track.title}</h2>
                      <p>{track.artist}</p>
                    </IonLabel>
                    {(isOfflineTrack(state, track))?(<IonIcon className="offline-indicator" icon={cloudDownload} slot="end"/>):""}
                  </IonItem>
                  <IonItemOptions key={track.id}>
                    <IonItemOption color="success" onClick={(e)=> selectPlaylist(track)}><IonIcon slot="start" icon={add}/>Playlist</IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              ))
              ):(<p style={{textAlign: "center"}}>No Tracks</p>)
            }
          </IonList>
          </>
          ):(
            (segment === "favourites")?(
              <>
              <IonList mode='ios'>
                <IonListHeader mode='ios'>
                  <IonLabel>Favorite Tracks</IonLabel>
                </IonListHeader>
                {(favTracks.length> 0)?(
                favTracks.map((track: any, key:number) => (
                  <IonItemSliding key={key} className="trackListed">
                    <IonItem key={track.title} mode='ios'>
                    {/* <IonItem key={track.title} mode='ios' button> */}
                      <IonThumbnail slot="start" className='thumbnail' onClick={() => playSaved(track, favTracks)} >
                        <img alt={track.title} src={img(track.img)}/>
                      </IonThumbnail>
                      <IonLabel onClick={() => playSaved(track, favTracks)} >
                        <h2>{track.title}</h2>
                        <p>{track.artist}</p>
                      </IonLabel>
                      {(isOfflineTrack(state, track))?(<IonIcon className="offline-indicator" icon={cloudDownload} slot="end"/>):""}
                      <IonIcon
                        onClick={(e)=>{actionBtnsClick(e, {action: 'deleteFav', song: track})}}
                        // onClick={e => { e.stopPropagation(); dispatch(setFavTrack(track))}}
                        icon={removeCircleOutline} slot="end" />
                    </IonItem>
                    <IonItemOptions key={track.id}>
                      <IonItemOption color="success" onClick={(e)=> selectPlaylist(track)}><IonIcon slot="start" icon={add}/>Playlist</IonItemOption>
                    </IonItemOptions>
                  </IonItemSliding>
                ))
                ):(<p style={{textAlign: "center"}}>No Tracks</p>)
              }
              </IonList>
              </>):(
                (segment === 'offline')?(
                <>  
                <IonList mode='ios'>
                  <IonListHeader mode='ios'>
                    <IonLabel>Saved Tracks</IonLabel>
                  </IonListHeader>
                  {(offlineSongs.length> 0)?(
                    offlineSongs.map((track: any, key: number) => (
                      <IonItemSliding key={key} className="trackListed">
                        <IonItem key={key} mode='ios'>
                        {/* <IonItem key={key} mode='ios' button> */}
                          <IonThumbnail slot="start" className='thumbnail' onClick={() => playSaved(track, offlineSongs)}>
                            <img alt={track.title} src={track.imageBlob}/>
                          </IonThumbnail>
                          <IonLabel onClick={() => playSaved(track, offlineSongs)}>
                            <h2>{track.title}</h2>
                            <p>{track.artist}</p>
                          </IonLabel>
                          {/* <IonIcon className="offline-indicator" icon={(isOfflineTrack(state, track))?(cloudDownload):(cloudDownloadOutline)} slot="end"/> */}
                          {(isOfflineTrack(state, track))?(<IonIcon className="offline-indicator" icon={cloudDownload} slot="end"/>):""}
                          <IonIcon
                            onClick={(e)=>{actionBtnsClick(e, {action: 'delete', song: track})}}
                            icon={removeCircleOutline} slot="end" />
                        </IonItem>
                        <IonItemOptions key={track.id}>
                          <IonItemOption color="success" onClick={(e)=> selectPlaylist(track)}><IonIcon slot="start" icon={add}/>Playlist</IonItemOption>
                        </IonItemOptions>
                      </IonItemSliding>
                  ))
                  ):(<p style={{textAlign: "center"}}>No Tracks</p>)
                }
                </IonList>  
              </>):(<>
                <IonList mode='ios'>
                  <IonListHeader mode='ios'>
                    <IonLabel>Playlists</IonLabel>
                  </IonListHeader>
                </IonList>
                {(Object.keys(playlists).length > 0)?(
                  Object.keys(playlists).map((playlistName: any, key: number) => (
                    <IonItem key={key} className="trackListed" mode='ios'>
                    {/* <IonItem key={key} className="trackListed" mode='ios' button> */}
                      <IonLabel onClick={()=>history.push("/playlist?name="+playlistName)}>
                        
                          <h2><b>{playlistName}</b></h2>
                          <p>{getPlaylistLength(playlistName)} Songs</p>
                        </IonLabel>
                      <IonIcon slot='end' icon={trash} onClick={()=> removePlaylist(playlistName, 'playlist')}/>
                    </IonItem>
                  ))
                ):(<p style={{textAlign: "center"}}>No Playlists</p>)}
              </>))
          )
        }    
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>   
      </IonContent>
    </IonPage>
  );
};
export default Library;


