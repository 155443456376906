import { useCallback, useContext, useEffect, useState } from 'react';
import { IonAlert, IonContent, IonLoading, IonPage} from '@ionic/react';

import { AppContext, loggedIn } from '../Services/State';

import Forgot from './loging/forgotpassword';
import axios from 'axios';

import OTP from './loging/otp';
import ResetPassword from './loging/recover';
import SignIn from './loging/signin';
import SignUp from './loging/signup';

import './Login.css';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { appAuthDomain } from '../Services/utils';

interface LoginInterfaceProps{
  name?: any,
  history?: any,
}

interface ShowAlertStateInterface {
  header: string;
  subHeader: string;
  message: string;
  buttons: any;
  showAlert: boolean
}

const Login: React.FC<LoginInterfaceProps> = ({name }) => {
  const { dispatch } = useContext(AppContext);

  const [defaultAction, setDefaultAction] = useState(false); 
  const [ username, setUsername ] = useState("");
  const [ranKey, setRanKey] = useState("");
  const [ formEntries, setFormEntries ] = useState({});
  const [stillLoading, setStillLoading] = useState(false); 
  const [showLoadingState, setShowLoading] = useState({showLoadingMessage: 'c...', showLoading: false});
  const [showAlertState, setShowAlert] = useState<ShowAlertStateInterface>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});


  let history = useHistory();
  let location = useLocation();

  var pageIn = location.pathname;
  pageIn = pageIn.substring(1, pageIn.length);

  const routerFunction = (e: string)=>{
    history.push("/"+e);
  };
  const otpResend = ()=>{
    authFunction({username: username}, false);
  }
  const onSignIn = useCallback(user => {
    var link:any = "/home";
    console.log(location);
    console.log(location.state, "location.state");
    if (location.state) {
      var theState:any = location.state;
      // console.log(typeof location);
      if (typeof theState === 'object') {
        // link = (location && location.state.from) || "/";
        link = (location && theState.from) || "/";

        if (link.state) { 
          if (link.state.from) {               
            link = link.state.from;
          };
        };
      }
    };

    // dispatch(setIsAuthorized(true));
    console.log(link);
    history.replace(link);
  
    localStorage.removeItem("username");
    localStorage.removeItem("ranKey");
    localStorage.removeItem("mod");
    dispatch(loggedIn(user));
    console.log(link);
    history.replace(link);
  }, [dispatch, history, location]);

  const fowardOnsubmit = (e: any)=>{
    e.preventDefault();
    e.stopPropagation();
    authFunction(e, null);
  }

  var authFunction = (e: any, thirParty: any)=>{
    var url = appAuthDomain("api/authentication");
    if (localStorage.getItem("mod")) {
      // if (localStorage.getItem("mod")) {
        url = appAuthDomain("api/authentication?mod="+localStorage.getItem("mod"));
      // }
    };
    var formData:any;
    var requestObject:any = {};
    var showLoadingMessage = 'Submitting...';
    if (thirParty) {
      url = appAuthDomain("api/authentication?thirdPart=true");
      formData = new FormData();
      for ( var key in e ) {
        formData.append(key, e[key]);
      };
      showLoadingMessage = thirParty+" authentication...";
    } else if (e.username) {
      formData = new FormData();
      for ( var theKey in e ) {
        formData.append(theKey, e[theKey]);
      };
      showLoadingMessage = "Re-sending OTP ...";
    } else {
      formData = new FormData(e.target);
    };
    setShowLoading({showLoadingMessage: showLoadingMessage, showLoading: true});

    requestObject = Object.fromEntries(formData.entries());
    const config = {
      headers: { "Content-Type": "multipart/form-data" }
    };
    setFormEntries((formValues:any)=>({...formValues, ...requestObject}));
    // console.log(url, requestObject);
    axios.post(url, formData, config).then(response=>{
      console.log(response);
      return response.data;
    }).then(result=>{
      setTimeout(() => {
        setShowLoading({...showLoadingState, showLoading: false});
        setShowLoading({showLoadingMessage: showLoadingMessage, showLoading: true});
      }, 900);
      console.log(result);
      if (result.success) {
        var fullData = result.data;
        if ((requestObject.type === "login")||(requestObject.type === "register")||(requestObject.type === "resetPassword")) {
          // if (requestObject.type === "register") {
          //   history.push("/otp");
          //   localStorage.setItem("username", requestObject.phone);
          //   localStorage.setItem("mod", "0");
          //   setUsername(requestObject.phone);
          // } else {
            localStorage.setItem("firstname", fullData.firstname);
            localStorage.setItem("lastname", fullData.lastname);
            localStorage.setItem("roleName", fullData.roleName);
            localStorage.setItem("accessToken", fullData.userToken);
            localStorage.setItem("refreshToken", fullData.refreshToken);

            if ((requestObject.type === "login")||(requestObject.type === "register")||(requestObject.type === "resetPassword")) {
              setTimeout(()=>{
                onSignIn(fullData);
              }, 1000);
            };
          // };
        } else if (requestObject.type === "forgotPassword") {
          history.push("/otp");
          localStorage.setItem("username", requestObject.username);
          setUsername(requestObject.username);
        } else if (requestObject.type === "otp") {
          if (fullData.continue || (fullData.nextAction === 'resetPassword')) {
            history.push("/resetPassword");
            localStorage.setItem("username", requestObject.username);
            localStorage.setItem("ranKey", requestObject.ranKey);
            setUsername(requestObject.username);
            setRanKey(requestObject.ranKey); 
          } else {
            localStorage.setItem("firstname", fullData.firstname);
            localStorage.setItem("lastname", fullData.lastname);
            localStorage.setItem("roleName", fullData.roleName);
            localStorage.setItem("accessToken", fullData.userToken);
            localStorage.setItem("refreshToken", fullData.refreshToken);

            setTimeout(()=>{
              onSignIn(fullData);
            }, 1000);
          }
        };
        var alertStateVarsSuccess = {header: result.msg, subHeader: "", message: result.msg2, buttons: [{ text: 'Continue', handler: () => { console.log('Confirm Ok'); } } ]};
        setTimeout(() => {
          setShowAlert({...alertStateVarsSuccess, showAlert: true});
        }, 1001);
      } else {
        var buttonActions = [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          },
          {
            text: 'Ok',
            handler: () => {
              console.log('Confirm Ok');
            }
          }
        ];
        var alertStateVars = {header: result.msg, subHeader: "", message: result.msg2, buttons: buttonActions};
        setTimeout(() => {
          setShowAlert({...alertStateVars, showAlert: true});
        }, 1001);
      }
    }).catch(err=>{
      console.log("At login.jsx; Request Error Catch (by Lethu): ", err.message);

      setTimeout(() => {
        setShowLoading({...showLoadingState, showLoading: false});
      }, 1000);
      var buttonActions = [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            // console.log('Confimmmrm Cancel', requestObject);

            // localStorage.setItem("username", requestObject.username);
            // setUsername(requestObject.username);
            // history.push("/otp");

          // var demoToken = "qqqqqqqqqqqqqqqqq.eeeeeeeeeeeeeeee.rrrrrrrrrrrrrrr";
          // // localStorage.setItem("accessToken", demoToken);
          // onSignIn(demoToken);
          }
        },
        {
          text: 'Retry',
          handler: () => {
            authFunction(e, thirParty);
          }
        }
      ];
      
      var alertStateVars = {header: "Error encountered", subHeader: "", message: err.message, buttons: buttonActions};
      setTimeout(() => {
        setShowAlert({...alertStateVars, showAlert: true});
      }, 900);
    })
  };

  const veryifyFunc = ()=>{
    if (!defaultAction) {
      if ((location.pathname ).substring(1, location.pathname.length) === "otp") {
        var paramsString = location.search;
        if (paramsString !== "") { 
          const queryParams = new URLSearchParams(paramsString);
          if ( queryParams.has("username") && queryParams.has("ranKey") ) {
            if(!stillLoading){
              setStillLoading(true);
              var sendObj:any = {
                type: "verifyLink",
                ranKey: queryParams.get("ranKey"),
                username: queryParams.get("username"),
              };
              setShowLoading({showLoadingMessage: 'Verifying Link...', showLoading: true});
              console.log("in")
              var ranKey:any =  queryParams.get("ranKey");
              var username:any = queryParams.get("username");
              axios.post(appAuthDomain("api/authentication?type=verifyLink&ranKey="+ranKey+"&username="+username), sendObj).then(response=>{
                return response.data;
              }).then (result=>{
                console.log(result)
                setShowLoading({...showLoadingState, showLoading: false});
                if (result.success) {
                  history.push("/resetPassword");
                  setDefaultAction(true);
                  setRanKey(ranKey);
                  setUsername(username);
                  // setUsername(sendObj.username);
                  // setRanKey(sendObj.ranKey);
                } else {
                  var buttonActions = [
                    {
                      text: 'Cancel',
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: () => {
                        window.history.back();
                      }
                    },
                    {
                      text: 'Retry',
                      handler: () => {
                        setStillLoading(false);
                        veryifyFunc();
                      }
                    }
                  ];

                  var alertStateVars = {header: result.msg, subHeader: "", message: result.msg2, buttons: buttonActions};
                  setTimeout(() => {
                    setShowAlert({...alertStateVars, showAlert: true});
                  }, 1001);
                }
              }).catch(err=>{
                setTimeout(() => {
                  setShowLoading({...showLoadingState, showLoading: false});
                }, 1000);
                
                var buttonActions = [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                      window.history.back();
                    }
                  },
                  {
                    text: 'Retry',
                    handler: () => {
                      veryifyFunc();
                    }
                  }
                ];
                var alertStateVars = {header: "Error encountered.", subHeader: "", message: err.message, buttons: buttonActions};
                setTimeout(() => {
                  setShowAlert({...alertStateVars, showAlert: true});
                }, 1001);
              });
            }
            
          } else {
            setDefaultAction(true);
          }
        } else {
          setDefaultAction(true);
        };
      } else {
        setDefaultAction(true);
      }
    } else {
      setDefaultAction(true);
    }
  }

  useEffect(() => {
    const loginPages = ['login', 'register', 'otp', 'forgotPassword', 'resetPassword'];

    var showBottomNav = false;
    const bottomNav = document.getElementById("bottomNav");
    if (!loginPages.includes(pageIn)) {showBottomNav = true; };
    if (bottomNav) {
      bottomNav.style.display = "none";
    };
    return ()=> {
      if (bottomNav) {
        if (showBottomNav) {
          bottomNav.style.display = "flex";
        }
      }
    }
  },[pageIn]);
  
  return (
    <IonPage>
      <IonContent>
        <>
      { (defaultAction) ? (
      <Switch>
        <Route path="/login" component={() => <SignIn routerFunction={routerFunction} onsubmit={authFunction} fowardOnsubmit={fowardOnsubmit} formEntries={formEntries}/>} exact /> 
        <Route path="/register" component={() => <SignUp routerFunction={routerFunction} onsubmit={authFunction} fowardOnsubmit={fowardOnsubmit} formEntries={formEntries}/>} exact />
        <Route path="/forgotPassword" component={() => <Forgot routerFunction={routerFunction} onsubmit={authFunction} formEntries={formEntries}/>} exact />
        <Route path="/otp" component={() => <OTP username={username} onsubmit={authFunction} routerFunction={otpResend} formEntries={formEntries}/>} exact /> 
        <Route path="/resetPassword" component={() => <ResetPassword username={username} onsubmit={authFunction} ranKey={ranKey}  formEntries={formEntries}/>} exact /> 
      </Switch>
      ) : veryifyFunc() }
      </>
      </IonContent>
      <IonLoading
          isOpen={showLoadingState.showLoading}
          onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
          message={showLoadingState.showLoadingMessage}
        />
      <IonAlert
        isOpen={showAlertState.showAlert}
        onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
        header={showAlertState.header}
        subHeader={showAlertState.subHeader}
        message={showAlertState.message}
        buttons={showAlertState.buttons}
      />
    </IonPage>
  );
};

export default Login;
