import React, { useCallback, useContext, useRef, useState } from 'react'
import { IonButton, IonButtons, IonCard, IonCardContent, IonHeader, IonIcon, IonLabel, IonModal, IonSearchbar, IonSegment, IonSegmentButton, IonTitle, IonToolbar, IonLoading, IonAlert, IonInput, IonItem, IonList, IonSelect, IonSelectOption, IonContent } from '@ionic/react';
import { chevronForwardCircle, closeCircle, move } from 'ionicons/icons';

import { AppContext, makeRequests, setIsSubscribed, togglePaymentPopup, openPlayer, getCurrentTrack, playTrackList } from '../../Services/State';

import './index.css';
import { appAuthDomain, sleep } from '../../Services/utils';
// import axios from 'axios';

interface ContainerProps {
    firstModalRef?: any;
}
interface Provider {
    header: string;
    subHeader: string;
    message: string;
    buttons: any;
    showAlert: boolean;
}

const PaymentPopup:React.FC<ContainerProps> = ({firstModalRef})=>{
    const {state, dispatch} = useContext(AppContext);
    const ifraneRef = useRef(null);
    const [segment, setSegment] = useState("5");
    const [voucherNumber, setVoucherNumber] = useState("");
    const [bankResponse, setBankResponse] = useState({url:"", MD:"", TermUrl:"", PaReq:"", payID: ""});
    const [cardPayModal,setCardPayModal] = useState(false);
    const [card3DPayModal,setCard3DPayModal] = useState<any>({show: false, contents: ''});
    const [voucherVerifyBtn, setVoucherVerifyBtn] = useState({color: "primary", text: "Verify"});
    const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});
    const [showAlertState, setShowAlert] = useState<Provider>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});


    const handleClose = useCallback(() => {
        dispatch(togglePaymentPopup(false));
    }, [dispatch]);
    // console.log("hgjh");
    // console.log(state.trackToPlay.whatType);
    // console.log("hh");
    
    const verifyVOucher = ()=>{
        var requestObject = {
            method: "POST", 
            url: appAuthDomain("backend/includes/subscription/subscription.php?action=subscribe&paymentMethod=voucher"),
            data: {
                voucher: voucherNumber,
                value: segment,
            },
            headers:{
                "Content-Type": "multipart/form-data"

            }
        };
        console.log(requestObject);
        setVoucherVerifyBtn({color: "secondary", text: "Wait.."});
        setShowLoading({showLoading: true, showLoadingMessage: "Please wait... "});
        makeRequests(state, requestObject).then(response=>{
            setVoucherVerifyBtn({color: "primary", text: "Verify"});
            setTimeout(() => {
                if (response.success) {
                    setVoucherVerifyBtn({color: "success", text: "Success"})
                    localStorage.setItem("isSubscribed", 'true');
                    dispatch(togglePaymentPopup(false));
                    dispatch(setIsSubscribed(true));
                    handleClose();
                    doThePlay();
                } else {
                    setShowLoading({...showLoadingState, showLoading: false});
                    var buttonActions = [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                
                            }
                        },
                        {
                            text: 'Retry',
                            handler: () => {
                                verifyVOucher();
                            }
                        }
                    ];
                    var alertStateVars = {header: response.msg, subHeader: "", message: response.msg2, buttons: buttonActions};
                    setShowAlert({...alertStateVars, showAlert: true});
                }
            }, 1000);
        })
    }
    var interval :any ;
    const peach3DPayment = async ()=>{
        var requestObject = {
            method: "POST", 
            url: appAuthDomain("backend/includes/subscription/subscription.php?action=subscribe&paymentMethod=getStatus"),
            data: {
                payID: bankResponse.payID,
                value: segment,
            },
            headers:{
                "Content-Type": "multipart/form-data"
            }
        };
        // console.log(requestObject);
        setVoucherVerifyBtn({color: "secondary", text: "Wait.."});
        // setShowLoading({showLoading: true, showLoadingMessage: "Please wait... "});
        await sleep(100);
        setShowLoading({...showLoadingState, showLoading: false});
        makeRequests(state, requestObject).then(response=>{
            // console.log(response);
            if (response.success) {
                clearInterval(interval);
                setCard3DPayModal({show: false, contents: ''})
                setVoucherVerifyBtn({color: "success", text: "Success"})
                localStorage.setItem("isSubscribed", 'true');
                dispatch(togglePaymentPopup(false));
                dispatch(setIsSubscribed(true));
                handleClose();
                doThePlay();
            }else{
                // var buttonActions :any;
                // var alertStateVars :any;
                // if(response.data.result){
                //     if(response.data.result.code ==="000.200.000"){
                //         clearInterval(interval);
                //         interval = setInterval(() => {
                //             peach3DPayment()
                //         }, 10000);
                //         // peach3DPayment()
                //     }else{
                //         clearInterval(interval);
                //         setShowLoading({...showLoadingState, showLoading: false});
                //         buttonActions = [
                //             {
                //                 text: 'Cancel',
                //                 role: 'cancel',
                //                 cssClass: 'secondary',
                //                 handler: () => {
                //                 }
                //             },
                //             {
                //                 text: 'Retry',
                //                 handler: () => {
                //                     peach3DPayment();
                //                 }
                //             }
                //         ];
                //         alertStateVars = {header: response.msg, subHeader: "", message: response.data.result.description, buttons: buttonActions};
                //         setShowAlert({...alertStateVars, showAlert: true}); 
                //     }
                // }else{
                //     clearInterval(interval);
                //     setShowLoading({...showLoadingState, showLoading: false});
                //     buttonActions = [
                //         {
                //             text: 'Cancel',
                //             role: 'cancel',
                //             cssClass: 'secondary',
                //             handler: () => {
                //             }
                //         },
                //         {
                //             text: 'Retry',
                //             handler: () => {
                //                 peach3DPayment();
                //             }
                //         }
                //     ];
                //     alertStateVars = {header: response.msg, subHeader: "", message: response.msg2, buttons: buttonActions};
                //     setShowAlert({...alertStateVars, showAlert: true});
                // }
            }
        })
        
    }
    const paywithBank = (e:any)=>{
        e.preventDefault();
        var formData = new FormData(e.target);
        formData.append("value", segment); 
        var formDataJSON = Object.fromEntries(formData);

        var requestObject = {
            method: "POST", 
            url: appAuthDomain("backend/includes/subscription/subscription.php?action=subscribe&paymentMethod=card"),
            data: {
                formDataJSON,
            },
            headers:{
                "Content-Type": "multipart/form-data"

            }
        };
        setShowLoading({showLoading: true, showLoadingMessage: "Please wait... "});
        makeRequests(state, requestObject).then(response=>{
            setTimeout(() => {
                if (response.success) {
                    var data = response.data;
                    console.log(response);
                    console.log(data);
                    setBankResponse({url: data.url, MD: data.MD, TermUrl:data.TermUrl, PaReq:data.PaReq, payID:data.payID});
                    setShowLoading({...showLoadingState, showLoading: false});
                    if (data.url) {
                        var iframe = document.createElement('iframe');
                        iframe.setAttribute("style","height:100%;width:100%;");
                        // var inputFields = '';
                        // var urlParamsString = '';
                        var html:any = `
                        <style >
                        #iframeSubmit{min-width: 200px; height: 40px; background-color: #4c8dff; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); border-radius: 25px; border: none;}
                        </style>
                        <body>
                            <div title={"Next payment stage."} >
                                <p>Processing payment. Do not reload page, Press continue if it takes longer.</p>
                                <form method='POST' action="${data.url}" id="iframeForm">
                                    <input type="hidden" name='TermUrl' value=${data.TermUrl}/>
                                    <input type="hidden" name='MD' value=${data.MD}/>
                                    <input type="hidden" name='PaReq' value=${data.PaReq}/>
                                    <input type="hidden" name='connector' value="THREEDSECURE"/>
                                    <button type='submit' id="iframeSubmit" >Continue</button>
                                </form>
                            </div>
                        </body>
                        <script type="text/javascript" defer>
                        window.onload = () => {
                            let iframeForm = document.getElementById("iframeForm"); iframeForm.submit();
                            setTimeout(function(){  document.getElementById("iframeSubmit").click(); }, 100);
                        }
                        </script>`;
                    }
                    var iFrameSrc:any = null; 
                    setCard3DPayModal((card3DPayModal:any)=>({...card3DPayModal, show: true, contents: {iframe, title: "Payments", srcDoc: html, src: iFrameSrc}}));
                    // setTimeout(() => {
                    //     var buttonActions = [
                            
                    //         {
                    //             text: 'Subscribed',
                    //             cssClass: 'primary',
                    //             handler: () => {
                    //                 localStorage.setItem("isSubscribed", 'true');
                    //                 dispatch(togglePaymentPopup(false));
                    //                 dispatch(setIsSubscribed(true));
                    //                 setCardPayModal(false);
                    //                 handleClose();
                    //                 doThePlay();
                    //             }
                    //         }
                    //     ];
                    //     var alertStateVars = {header: response.msg, subHeader: "", message: response.msg2, buttons: buttonActions};
                    //     setShowAlert({...alertStateVars, showAlert: true});
                    // }, 1000);
                    
                } else {
                    setShowLoading({...showLoadingState, showLoading: false});
                    var buttonActions = [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                            }
                        },
                        {
                            text: 'Retry',
                            handler: () => {
                                paywithBank(formDataJSON);
                            }
                        }
                    ];
                    var alertStateVars = {header: response.msg, subHeader: "", message: response.msg2, buttons: buttonActions};
                    setShowAlert({...alertStateVars, showAlert: true});
                }
            }, 1000);

        })
        
    }

    function doThePlay (){
        if(state.trackToPlay.whatType !== ""|| state.trackToPlay.whatType!== null){
            var myPlayList:any = [];
            var tempTracks:any = [];
            state.trackToPlay.listTracks.forEach((song:any)=>{
                myPlayList.push(song.id);
                tempTracks.push(song);
            });
            if(state.trackToPlay.whatType === "track"){
                if (getCurrentTrack(state)) {
                    if (state.trackToPlay.track.id === (getCurrentTrack(state)).id) {
                        dispatch(openPlayer());
                    } else {
                        dispatch(playTrackList(tempTracks, myPlayList, state.trackToPlay.track));
                    }
                } else {
                dispatch(playTrackList(tempTracks, myPlayList, state.trackToPlay.track));
                }
            }else{
                if (state.trackToPlay.listTracks.length > 0) {
                    dispatch(playTrackList(tempTracks, myPlayList, state.trackToPlay.track));
                };
            }
        }
    }
    

    if (!state.user.tryPay) {
        return null;
    };

    // console.log(card3DPayModal)

    return (
        <>
             <IonModal
                mode='ios'
                isOpen={state.user.tryPay}
                onDidDismiss={handleClose}
                swipeToClose={true}
                presentingElement={firstModalRef}
                showBackdrop={true}
                // trigger="open-payment-modal"
                // breakpoints={[ 0.5, 1]}
                // initialBreakpoint={0.5}
            >
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton onClick={handleClose}>Close</IonButton>
                        </IonButtons>
                        <IonTitle>Add a Subscription</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonCard>
                    <IonSegment mode='ios' value={segment} onIonChange={e => setSegment(e.detail.value!)} >
                        <IonSegmentButton value="5">
                            <IonLabel>R5 </IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="50">
                            <IonLabel>R50</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                    <IonCardContent>
                        <IonCard>
                            <div className='voucherForm'>
                                <IonSearchbar className='voucherFormField' onIonChange={(e)=>setVoucherNumber(e.detail.value!)} placeholder='Enter Voucher' 
                                searchIcon={move}
                                />
                                <IonButton className='voucherFormBtn' color={voucherVerifyBtn.color} onClick={verifyVOucher}>{voucherVerifyBtn.text}</IonButton>
                            </div>
                            <br/>
                            <br/>
                            <div className='paymentMethodSeparator'>
                                <hr/>
                                <p>OR</p>
                            </div>
                            <br/>
                            <br/>
                            <IonButton expand='full' style={{margin: "auto"}} onClick={()=>setCardPayModal(true)}>Use Bank</IonButton>
                        </IonCard>
                    </IonCardContent>
                </IonCard>
                <IonButton onClick={handleClose}><IonIcon icon={closeCircle}/>Back</IonButton>
            </IonModal>
            <IonModal
                mode='ios'
                isOpen={cardPayModal}
                cssClass='my-custom-class'
                presentingElement={firstModalRef.current}
                swipeToClose={true}
                onDidDismiss={() => setCardPayModal(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setCardPayModal(false)}>Close</IonButton>
                        </IonButtons>
                        <IonTitle>Add a Card</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {/* <IonContent> */}
                    <IonCard className="checkoutCard">
                        <form onSubmit={paywithBank}>
                            <IonList>
                                <IonItem>
                                    <IonLabel position="floating">Card Holder Names</IonLabel>
                                    <IonInput name="cardHolder" placeholder="Firstname Lastname" required/>
                                </IonItem>
                                <IonItem>
                                    <IonLabel position="floating">Card Brand</IonLabel>
                                    <IonSelect 
                                    // ref={deliveryOptsRef}
                                    id="cardBrand"
                                    // interface="popover"
                                    placeholder="Select one" 
                                    name='cardBrand'
                                    aria-required
                                    >
                                        <IonSelectOption value="VISA">VISA</IonSelectOption>
                                        <IonSelectOption value="MASTER">MASTER CARD</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonItem>
                                    <IonLabel position="floating">Card Number</IonLabel>
                                    <IonInput name="cardNumber" placeholder="0000 0000 0000 0000" required/>
                                </IonItem>

                    
                                <div className='monDate'>
                                    <IonItem class="mon">
                                        <IonLabel >
                                            <IonLabel position="floating">MM </IonLabel>
                                            <IonInput name="month" maxlength={2} type='tel' placeholder="MM" required/>
                                        </IonLabel>
                                    </IonItem>
                                    <IonItem class="mon">
                                        <IonLabel slot="end" >
                                            <IonLabel position="floating">YY </IonLabel>
                                            <IonInput name="year" maxlength={2} type='tel' placeholder="YY" required/>
                                        </IonLabel>
                                    </IonItem>
                                </div>
                                
                                <IonItem>
                                    <IonLabel position="floating">CVV</IonLabel>
                                    <IonInput name="cvv" type='number' placeholder="CVV" required/>
                                </IonItem>
                                
                            </IonList>
                            <div className='payBtnHolder'>
                                <IonButton className="payBtn" expand='full' slot='end' type='submit'>Pay<IonIcon icon={chevronForwardCircle}/></IonButton>
                            </div>
                        </form>
                    </IonCard>
                
                <IonButton style={{visibility: "hidden"}} onClick={() => setCardPayModal(false)}>Checkout<IonIcon icon={chevronForwardCircle}/></IonButton>
            </IonModal>

            <IonModal
                mode='ios'
                isOpen={card3DPayModal.show}
                cssClass='my-custom-class'
                presentingElement={firstModalRef.current}
                swipeToClose={true}
                onDidDismiss={() => setCard3DPayModal({show: false, contents: ''})}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setCard3DPayModal({show: false, contents: ''})}>Close</IonButton>
                        </IonButtons>
                        <IonTitle>3D payment</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    {
                        (card3DPayModal.contents.iframe)?(
                            <>
                            {
                                (card3DPayModal.contents.src)?(
                                    <iframe id='myFrame' 
                                        ref={ifraneRef}
                                        title={card3DPayModal.contents.title} 
                                        src={card3DPayModal.contents.src}
                                        style={{width: "100%", height: "100%"}}
                                        onLoad={peach3DPayment}
                                    />
                                ):(
                                    <iframe id='myFrame' 
                                        ref={ifraneRef}
                                        title={card3DPayModal.contents.title} 
                                        srcDoc={card3DPayModal.contents.srcDoc}
                                        src=""
                                        style={{width: "100%", height: "100%"}}
                                        onLoad={peach3DPayment}
                                    />
                                )
                            }
                            </> 
                        ):(
                            <>
                            {card3DPayModal.contents.srcDoc}
                            </>
                        )
                    }
                    {/* <IonCard className="checkoutCard">
                        <form action={bankResponse.url} method="POST">
                            <input type="hidden" name='TermUrl' value={bankResponse.TermUrl}/>
                            <input type="hidden" name='MD' value={bankResponse.MD}/>
                            <input type="hidden" name='PaReq' value={bankResponse.PaReq}/>
                            <input type="hidden" name='connector' value="THREEDSECURE"/>
                            <div className='payBtnHolder'>
                                <IonButton className="payBtn" expand='full' slot='end' type='submit'>Click here to continue<IonIcon icon={chevronForwardCircle}/></IonButton>
                            </div>
                        </form>
                    </IonCard> */}
                </IonContent>
                {/* <IonButton style={{visibility: "hidden"}} onClick={() => peach3DPayment()}>Checkout<IonIcon icon={chevronForwardCircle}/></IonButton> */}
            </IonModal>
            
            <IonLoading
                mode='ios'
                isOpen={showLoadingState.showLoading}
                onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
                message={showLoadingState.showLoadingMessage}
            />
            
            <IonAlert
                mode='ios'
                isOpen={showAlertState.showAlert}
                onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
                header={showAlertState.header}
                subHeader={showAlertState.subHeader}
                message={showAlertState.message}
                buttons={showAlertState.buttons}
            />
        </>
    )
}

export default PaymentPopup;