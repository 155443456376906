import { useContext, useEffect } from "react";

import { AppContext, getPlaying, progressTrack, syncMyActions } from "../Services/State";

// A really naive fake play routine
const Audio = () => {
    const { state, dispatch } = useContext(AppContext);

    // let [handle, setHandle] = useState(null);

    useEffect(() => {
        // console.log(state.playing);
        const playing = getPlaying(state);
        // let h;
        if (playing && !playing.paused) {
            // clearTimeout(h);
            // h = setTimeout(() => {
            //     // dispatch(seekTrack(Math.floor(playing.progress + 1000)));
            // }, 1000);
            // setHandle(h);

            var myAudioObj = playing.playerRef.ref;
            var lastTime = 0;
            var playCounterTimer = state.playCounterTimer*myAudioObj.duration;
            var playCounterTimerCounted = false;
            myAudioObj.ontimeupdate = ()=>{
                var currentTime = myAudioObj.currentTime;
                var currentTimeRound = Math.round(currentTime)*1000;
                if ((currentTimeRound >= playCounterTimer) && (!playing.counted)) {
                    playCounterTimerCounted = true;
                    syncMyActions(state, 'playCount', playing.id, playCounterTimer);
                } else {
                    if (playing.counted) {
                        playCounterTimerCounted = true;
                    }
                };
                if (lastTime !== currentTimeRound) {
                    lastTime = currentTimeRound;
                    dispatch(progressTrack({time: currentTimeRound, counted: playCounterTimerCounted}));
                };
            };
        } else {
            if (state.playerRef) {
                state.playerRef.ref.pause();
            }
        }

        return () => {
            // console.log(h);
            // clearTimeout(h);
        };
    }, [state, dispatch]);

    return null;
};

export default Audio;
