import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { IonBackButton, IonButtons, IonCard, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonPage, IonSkeletonText, IonTitle, IonToolbar } from '@ionic/react';
import { add, chevronBack, cloudDownload, cloudDownloadOutline, heartCircle, heartCircleOutline, pauseCircle, play, playBack } from 'ionicons/icons';

import { AppContext, getCurrentAlbum, getCurrentTrack, getPlaying, isFavTrack, isOfflineTrack, playTrackList, setCurrentAlbum, setFavTrack, setTrackToPlay, togglePaymentPopup } from '../Services/State.jsx';
import { appAuthDomain, img} from '../Services/utils';
import axios from 'axios';

import './Album.css';

const Album: React.FC<any> = ({actionBtnsClick, addPlaylist, setShowAlert, selectPlaylist}) => {
  const { state, dispatch } = useContext(AppContext);
  const { search } = useLocation();
  const history = useHistory();

  const currentAlbum = getCurrentAlbum(state);
  const songPlaying = getPlaying(state);
  const currentTrack = getCurrentTrack(state);
  const [albumTracksFetched, setAlbumTracksFetched] = useState(false);
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);
  const [albumTracks, setAlbumTracks] = useState<any>((currentAlbum.tracks)?(currentAlbum.tracks):[]);
  
  // console.log(currentTrack);

  
  const query = new URLSearchParams(search);
  var albumId = query.get("id");
  var shouldPlay = (query.get("play")==="yes")?true:false;
  
  const doPlayAlbum = useCallback((albumTracks:any, firstTrack: any) => {
    if (state.auth.user) {
      if (state.auth.user.isSubscribed) {
        // var getCurrentTracks = getTracks(state);
        var myPlayList:any = [];
        var tempTracks:any = [];
        albumTracks.forEach((song:any)=>{
          myPlayList.push(song.id);
          tempTracks.push(song);
          // getCurrentTracks.push(song);
        });
        // var newMusicList = {...state.music, tracks: getCurrentTracks, tempTracks: tempTracks, playList: myPlayList};
        // var newMusicList = {...state.music, tracks: getCurrentTracks, tempTracks: tempTracks, playList: myPlayList};
        // dispatch(setTracks(newMusicList));
        if (albumTracks.length > 0) {
          // dispatch(playTrack(albumTracks[0]));
          // dispatch(playTrackList(newMusicList));
          setIsAutoPlaying(true);
          dispatch(playTrackList(tempTracks, myPlayList, firstTrack));
        };
      } else {
        // history.push("/subscribe");
        dispatch(setTrackToPlay(firstTrack, albumTracks, "album"));
        dispatch(togglePaymentPopup(true));
      }
    } else {
      history.push("/login");
    }
  }, [ state.auth.user, dispatch, history ]);
  // const doPlay = useCallback(track => {
  //   dispatch(playTrack(track));
  // }, [dispatch]);
  
  // const keepOffline = useCallback(track => {
  //   dispatch(setOffline(track));
  // }, [dispatch]);
  

  // const addFav = useCallback(track => {
  //   dispatch(setFavTrack(track));
  // }, [dispatch]);

  const addFav = (track: any) => {
    if (state.auth.user) {
      if (state.auth.user.isSubscribed) {
        dispatch(setFavTrack(track));
      }else {
        dispatch(togglePaymentPopup(true));
      }
      
    }else{
      history.push("/login");
    }
  };

  var album = getCurrentAlbum(state);

  useEffect(()=>{
    (function cloudUserState(){
      if (!albumTracksFetched) {
        var accessToken = "";
        if (state.auth.user) {
          if (state.auth.user.accessToken) {
            accessToken = state.auth.user.accessToken;
          }
        };
        var headers = {
          Authorization: "Bearer "+accessToken
        }
        // return axios.get(appAuthDomain("api/music?content=album&id="+albumId), {headers}).then((result:any)=>{
        // return axios.get("api/music?content=album&id="+albumId, {headers}).then((result:any)=>{
        axios.get(appAuthDomain("backend/includes/music/music.php?content=album&id="+albumId), {headers}).then((result:any)=>{
          // console.log(result);
          return result.data;
        }).then ((response)=>{
          setAlbumTracksFetched(true);
          // console.log(response);
          var newAlbumTracks:any = [];
          if (response.success) {
            var respInfo = response.data;
            newAlbumTracks.id = respInfo.id;
            newAlbumTracks.title = respInfo.title;
            newAlbumTracks.img = respInfo.img;
            newAlbumTracks.artist = respInfo.artist;
            newAlbumTracks.type = respInfo.type;
            newAlbumTracks.noOfSongs = respInfo.noOfSongs;
            newAlbumTracks.tracks = respInfo.tracks;
            newAlbumTracks.src = respInfo.src;
            dispatch(setCurrentAlbum(newAlbumTracks));
          } else {
            newAlbumTracks.tracks = [];
          };
          // dispatch(setCurrentAlbum(newAlbumTracks));
          setAlbumTracks(newAlbumTracks.tracks);
        }).catch((error:any)=>{
          // console.log(error.message);
          setAlbumTracksFetched(true);
          // var fetchedAlbum = {img: "Can't find cover", title: "Can't find title", artist: "Can't find artist", type: "", tracks: []};
          var tr1 = {...album.tracks[0], type: ""};
          var tr2 = {...album.tracks[1], type: ""};
          var tr3 = {...album.tracks[2], type: ""};
          var fetchedAlbum = {
            img: "Can't find cover", title: "Can't find title", artist: "Can't find artist", type: "",
            // tracks: [
            //   // {
            //   //   ...album
            //   // }
            // ]
            tracks: [
              tr1,
              tr2,
              tr3
            ],
          };
          setTimeout(() => {
            // dispatch(setCurrentAlbum(fetchedAlbum));
            setAlbumTracks(fetchedAlbum.tracks);
          }, 1000);
        });
      }
    })();
  }, [state.auth.user, albumId, album, albumTracksFetched, dispatch]);

  useEffect(()=>{
    // console.log(1, albumTracks);
    if (shouldPlay) {
      // console.log(2, albumTracks);
      if (!isAutoPlaying) {
        // console.log(3, albumTracks);
        if (albumTracks.length > 0) {
          // console.log(4, albumTracks);
          // console.log(albumTracks.some((e:any) => e.type === 'loader'));
          // console.log(albumTracks.filter(function(e:any) { return e.type === 'loader'; }).length > 0);
          // const foundLoader = albumTracks.findIndex((t:any) => t.type === "loader");
          const foundLoader = albumTracks.some((e:any) => e.type === 'loader');
          // console.log(foundLoader);
          if ( !foundLoader ) {
            // doPlayAlbum(albumTracks, albumTracks[0]);

            var myPlayList:any = [];
            var tempTracks:any = [];
            albumTracks.forEach((song:any)=>{
              myPlayList.push(song.id);
              tempTracks.push(song);
            });
            // console.log(albumTracks);
            setIsAutoPlaying(true);
            dispatch(playTrackList(tempTracks, myPlayList, albumTracks[0]));
          }
        }
      }
    }

    return ()=>{
      // dispatch(setCurrentAlbum({}));
    };
  }, [shouldPlay, isAutoPlaying, albumTracks, dispatch]);

  return (
    <IonPage>
      <IonHeader mode='ios'>
        <IonToolbar mode='ios'>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" text="Back" icon={chevronBack} />
          </IonButtons>
          <IonTitle>{(album)?(album.type): ""}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <IonHeader collapse="condense" mode='ios'>
          <IonToolbar mode='ios'>
            <IonTitle size="large">{(album)?(album.type): ""}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid className="album-info">
          <IonItem lines="none" className="album-cover" mode='ios'>
            <IonCard className="album-cover-card" mode='ios'>
              <img alt={(album)?(album.title):""} src={ (album)?(img(album.img)):""} />
            </IonCard>
            <IonLabel>
              <h2>{(album)?(album.title):"Loading title..."}</h2>
              <p>{(album)?(album.artist):"loading Artist..."}</p>
            </IonLabel>
            <IonIcon name={(currentAlbum.id === album.id)?(pauseCircle): (playBack)} onClick={() => doPlayAlbum(albumTracks, albumTracks[0])} slot="start"/>
          </IonItem>
        </IonGrid>
        <IonList mode='ios'>
          <IonListHeader mode='ios'>
            <IonLabel>Songs</IonLabel>
          </IonListHeader>
          {
            (albumTracks.length > 0) ? (
              albumTracks.map((track: any, key:number) => {
                if (track.type !== "loader") {
                  var relevantIcon = play;
                  var playingClass = "";
                  if (currentTrack) {
                    relevantIcon = ((currentTrack.id === track.id)&&(!songPlaying.paused))?(pauseCircle): (play);
                    playingClass = (currentTrack.id === track.id)?("currentSongInList"): ("");
                  };
                  return <IonItemSliding key={key} className="trackListed">
                    <IonItem key={track.title} mode='ios'>
                    {/* <IonItem key={track.title} mode='ios' button> */}
                      <IonIcon onClick={() => doPlayAlbum(albumTracks, track)} icon={relevantIcon} className={playingClass} slot="start" />
                      <IonLabel onClick={() => doPlayAlbum(albumTracks, track)} >
                        <h2>{track.title}</h2>
                        <p>{track.artist}</p>
                      </IonLabel>
                      <IonIcon icon={(isFavTrack(state, track))?(heartCircle):(heartCircleOutline)} onClick={()=> addFav(track)} slot="end"/>
                      {/* <IonIcon icon={heartCircleOutline} onClick={()=> addFav(track)} slot="end"/> */}
                      <IonIcon className="offline-indicator" onClick={(e)=>{actionBtnsClick(e, {action: 'save', song: track})}} icon={(isOfflineTrack(state, track))?(cloudDownload):(cloudDownloadOutline)} slot="end"/>
                    {/* <IonIcon name={(isOfflineTrack(state, track))?(cloudDownload):(cloudDownloadOutline)} onClick={()=> keepOffline(track)} slot="end"/> */}
                    </IonItem>
                    <IonItemOptions key={track.id}>
                        <IonItemOption color="success" onClick={(e)=> selectPlaylist(track)}><IonIcon slot="start" icon={add}/>Playlist</IonItemOption>
                    </IonItemOptions>
                  </IonItemSliding>
                } else {
                  return <IonItem key={track.id} className="trackListed" mode='ios'  button>
                    <IonIcon style={{color: "silver"}} name={playBack}  slot="start"/>
                    <IonLabel>
                      <h2><IonSkeletonText animated style={{ width: '100%', float: 'left'  }} /></h2>
                      <p><IonSkeletonText animated style={{ width: '80%', float: 'left'  }} /></p>
                    </IonLabel>
                    <IonIcon slot="end"/>
                  </IonItem>;
                }
              })
            ) : (<IonItem>No tracks in this album.</IonItem>)
          }
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Album;
